import {calculateFramePerSecond, extractAudioFromVideo, getVideoMetaData, promisedToLoadFfmpeg} from "tpz-lib";
import {api, useFileUpload} from "front-lib";

export const useClipUploader = () => {

    const {upload} = useFileUpload();
    const createClipEntity = async (blobUrl: string) => {
        console.log("createClipEntity", blobUrl);
        // const blobUrl = URL.createObjectURL(blob);
        const videoMetaData = await getVideoMetaData(blobUrl);
        await promisedToLoadFfmpeg;
        const fps = await calculateFramePerSecond(blobUrl)
        const audioBlob = await extractAudioFromVideo(blobUrl)
        const blob = await fetch(blobUrl).then(r => r.blob());

        const orgVideoUrl = await upload("/upload/video", blob, {});
        const orgAudioUrl = await upload("/upload/audio", audioBlob, {});

        const response = await api.post(`/workspace/clips/create`, {
            ...videoMetaData,
            fps,
            orgAudioUrl,
            orgVideoUrl,
        })
        // console.log("response", response.data)
        return response.data;
    }


    const createFileEntity = async (clipId: string, blob: Blob, duration: number) => {
        const url = await upload("/upload/video", blob, {});
        const response = await api.post(`/workspace/clips/${clipId}/files`, {
            url,
            duration
        });
        return response.data;
    }
    return {
        createClipEntity,
        createFileEntity
    }
}