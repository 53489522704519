import React from "react";
import {DialogConfirmation} from "front-lib";
import {useNavigate} from "react-router-dom";
import {useWorkspace} from "../../workspace/context/useWorkspace";

const ButtonDeleteClip = ({clip}: any) => {
    const navigate = useNavigate();
    const {deleteClipAndSaveState} = useWorkspace();
    return (
        <>
            <DialogConfirmation
                title={"Confirm Deletion"}
                onApprove={async () => {
                    await deleteClipAndSaveState(clip.id);
                    navigate("/app");

                }}
                renderTrigger={<button type="button"
                                       className={`px-3 py-2 text-xs font-medium text-center text-white bg-primary-200 rounded-lg hover:bg-primary-300  dark:bg-primary-600 dark:hover:bg-primary-700 `}>
                    Delete Clip
                </button>}
            />

        </>
    )
}

export {ButtonDeleteClip}