import {LoadingStripes, api} from "front-lib";
import React, {useEffect, useState} from "react";
import {WorkspaceContext} from "./context";
import {useWorkspaceApi} from "./useWorkspaceApi";


const WorkspaceContextProvider = ({children, username}: any) => {

    const {
        loadMyClips,
        loadStats,
        editClip,
        deleteClip
    } = useWorkspaceApi();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [stats, setStats] = useState<any>(null);
    const [clips, setClips] = useState<any[]>([]);


    const deleteClipAndSaveState = async (clipId: string) => {
        await deleteClip(clipId)

        setClips(clips => clips.filter(c => c.id !== clipId))

    }

    async function loadWorkspaceData() {
        console.log("loadWorkspaceData")
        const clips = await loadMyClips();
        setClips(clips);

        const stats = await loadStats();
        setStats(stats);
    }

    const initWorkSpace = async (key?: string) => {
        try {
            // const workspaceUsers = await loadWorkspaceUsers();
            // setWorkspaceUsers(workspaceUsers);
            await loadWorkspaceData();

        } catch (err) {

        } finally {
            setIsLoading(false)
        }

    }


    useEffect(() => {
        setIsLoading(true);
        initWorkSpace();
    }, [])

    const context = {
        clips,
        editClip,
        initWorkSpace,
        deleteClipAndSaveState,
        stats,
        isLoading,
    }
    return (
        <WorkspaceContext.Provider value={context}>
            {!isLoading && children}
            {isLoading && <div className={'flex justify-center items-center  mx-auto pt-10'}>
                <LoadingStripes/>
            </div>}
        </WorkspaceContext.Provider>
    )
}

export default WorkspaceContextProvider
