import React from "react"
import {TiCloudStorage} from "react-icons/ti";
import {useWorkspace} from "../workspace/context/useWorkspace";
import {FaRegClock} from "react-icons/fa";
import {BiMovie} from "react-icons/bi";
import {formatTime} from "../engine/fabric/useFabric";

const DashboardStats = () => {

    const {stats} = useWorkspace();
    // const stats = {totalDuration: 180, clipsCount: 2, credits: 5}
    return (
        <>
            <div>
                <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-12 sm:col-span-4">
                        <div
                            className="w-full bg-white dark:bg-gray-950 dark:text-gray-300 border dark:border-gray-950 text-primary-200 rounded-lg flex items-center p-6  xl:mb-0">
                            <BiMovie  className="w-11 h-11 fill-current mr-4 hidden lg:block"/>
                            <div className="text-gray-700 dark:text-gray-300">
                                <p className="font-semibold text-3xl">{stats.clipsCount}</p>
                                <p>Clips</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 sm:col-span-4">
                        <div
                            className="w-full bg-white dark:bg-gray-950 dark:text-gray-300 border dark:border-gray-950 text-primary-200 rounded-lg flex items-center p-6">
                            <FaRegClock  className="w-10 h-10 fill-current mr-4 hidden lg:block"/>
                            <div className="text-gray-700 dark:text-gray-300">
                                <p className="font-semibold text-3xl">{formatTime(stats.totalDuration)}</p>
                                <p>Total Clips duration</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 sm:col-span-4">
                        <div
                            className="w-full bg-white dark:bg-gray-950 dark:text-gray-300 border dark:border-gray-950 text-primary-200 rounded-lg flex items-center p-6">

                            <TiCloudStorage className="w-14 h-14 fill-current mr-4 hidden lg:block"/>
                            <div className="text-gray-700 dark:text-gray-300">
                                <p className="font-semibold text-3xl">{stats.credits}</p>
                                <p>Credits</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export {DashboardStats}
