import React from "react";
import {Helmet} from "react-helmet-async";
import {useLocation} from "react-router-dom";
import introImage from "../../../assets/intro.png";

const VITE_APP_UI_URL = import.meta.env.VITE_APP_UI_URL;

const HelmetSiteRouteSeoMetaTags = () => {
    const {pathname} = useLocation();
    const imgUrl = `${VITE_APP_UI_URL}${introImage}`

    // console.log("imgUrl", imgUrl)
    // const imgUrl = `${VITE_APP_UI_URL}${blogArticle.image}`
    const canonicalUrl = pathname === "/" ? VITE_APP_UI_URL : `${VITE_APP_UI_URL}${pathname}`
    const title = "Manage your AI models with contextual precision";
    const description = "Revolutionizing AI interactions with a user-friendly interface, contextual conversations, and team collaboration features."
    return (
        <>
            <Helmet>
                <meta property="title" content={title}/>
                <meta name="description" content={description}/>
                {/*    OG  */}
                <meta property="og:type" content="website"/>
                {/*    The title of your content. You should not include your brand name or the domain.    */}
                <meta property="og:title" content={title}/>
                {/*    A one to two sentence description of your content.  */}
                <meta property="og:description" content={description}/>
                {/*    An image URL which should represent your content.   */}
                <meta property="og:image" content={imgUrl}/>
                {/*    The canonical URL of your content.  */}
                <meta property="”og:url”" content={canonicalUrl}/>
                {/*    The locales this content is available in.   */}
                <meta property="”og:locale”" content="en"/>
                {/*    The website name or brand name.    */}
                <meta property="”og:site_name”" content="Clipz"/>
                {/*    A Facebook App ID that identifies your website to Facebook. You can analyze the traffic in Facebook. */}
                <meta property="”fb:app_id”" content="100089867559801"/>
                {/*--------------------------------*/}
                {/*    TWITTER     */}
                <meta name="twitter:card" content={imgUrl}/>
                <meta name="twitter:site" content="@clipz"/>
                <meta name="twitter:title" content={title}/>
                <meta name="twitter:description" content={description}/>
                <meta name="twitter:image:src" content={imgUrl}/>
                <meta name="twitter:creator" content="@clipz"/>
                {/*-----------------------------*/}
                <link rel="canonical" href={canonicalUrl}/>
            </Helmet>


        </>
    )
}

export default HelmetSiteRouteSeoMetaTags