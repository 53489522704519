import React from 'react';
import {IColumn} from "../../table/context/GenericTableContext";
import LabelRole from "./labels/LabelRole";
import {Avatar, LazyLabelDateTime , AvatarList} from "front-lib";
import DialogEditUser from "./dialogs/DialogEditUser";

export const userColumns: IColumn[] = [
    {
        property: "actions",
        label: "Actions",
        disableSorting: true,
        isVisible: true,
        component: ({row, column}) => <div className={'flex items-center'} onClick={(event) => event.stopPropagation()}>
            <DialogEditUser user={row}/>
            {/*<TriggerDeleteOrRestoreBaseModel model={row}/>*/}
        </div>
    },
    {
        property: "id",
        label: "ID",
        isVisible: true,
        component: ({row, column}) => <>
            {row.id.split("-")[0]}
        </>
    },
    {
        property: "name",
        label: "Name",
        isVisible: true,
        component: ({row, column}) => <div className={'flex items-center'}>
            <div className={'h-11 w-11'}>
                <Avatar url={row.profile.avatar}/>
            </div>
            <div className={'ml-2 '}>
                {/*{row.profile.firstname} {row.profile.lastname}*/}
                <div> {row.profile.firstname} {row.profile.lastname}</div>
                {row.isActive && <div className={'text-xs text-gray-500'}>
                    active
                </div>}
                {!row.isActive && <div className={'text-xs text-error-700'}>
                    disabled
                </div>}
            </div>

        </div>
    },
    {
        property: "workspace",
        label: "Workspace",
        isVisible: true,
        component: ({row, column}) => <>
            {row.profile.workspace && row.profile.workspace.name}
        </>
    },
    {
        property: "email",
        label: "Email",
        isVisible: true,
        component: ({row, column}) => <>
            <div style={{maxWidth: 200, textOverflow: "ellipsis", overflow: "hidden", height: "19px"}}>
                {/*<Tooltip title={row.email} placement={"top"} arrow>*/}
                {/*   <span>*/}
                {row.email}

                {/*</span>*/}
                {/*</Tooltip>*/}
            </div>
        </>
    },
    {
        property: "role",
        label: "Role",
        isVisible: true,
        component: ({row, column}) => <>
            <LabelRole role={row.role}/>
        </>
    },

    // {
    //     property: "puppets",
    //     label: "Puppets",
    //     isVisible: true,
    //     component: ({row, column}) => <>
    //         {row.profile.puppets && <AvatarList profiles={row.profile.puppets}/>}
    //     </>
    // },
    {
        property: "referrer",
        label: "Referrer",
        isVisible: true,
        component: ({row, column}) => <>
            {/*{row.referrals && <AvatarList profiles={row.referrals.map((u: any) => u.profile)}/>}*/}
            {row.referrer && row.referrer.profile && <AvatarList profiles={[row.referrer.profile]}/>}
        </>
    },
    {
        property: "createdAt",
        label: "Created",
        isVisible: true,
        component: ({row, column}) => <>
            <LazyLabelDateTime timestamp={row.createdAt}/>
        </>
    },

];
