import React from "react"
import {Accordion, Tabs} from "front-lib";


const TabsSocials = ({}: any) => {

    return (
        <>
            <Tabs tabs={[
                {
                    label: <span>YouTube</span>,
                    content: <div
                        className="grid grid-cols-6 gap-6  space-y-6 bg-white dark:bg-gray-950 px-4 py-0 sm:p-6">
                        <div className="col-span-6">
                            <Accordion defaultItems={[
                                {
                                    label: <>
                                        <span>Watermark</span>

                                    </>,
                                    content: <>

                                        <p className="mb-2 text-gray-500 dark:text-gray-400">Flowbite is an
                                            open-source library
                                            of
                                            interactive components built on top of Tailwind CSS including buttons,
                                            dropdowns,
                                            modals,
                                            navbars, and more.</p>
                                        <p className="text-gray-500 dark:text-gray-400">Check out this guide to
                                            learn how to <a
                                                href="/docs/getting-started/introduction/"
                                                className="text-blue-600 dark:text-blue-500 hover:underline">get
                                                started</a> and
                                            start
                                            developing websites even faster with components on top of Tailwind CSS.
                                        </p>

                                    </>
                                },
                                {
                                    label: <>
                                        <span>Audio Visual</span>

                                    </>,
                                    content: <>

                                        <p className="mb-2 text-gray-500 dark:text-gray-400">Flowbite is an
                                            open-source library
                                            of
                                            interactive components built on top of Tailwind CSS including buttons,
                                            dropdowns,
                                            modals,
                                            navbars, and more.</p>
                                        <p className="text-gray-500 dark:text-gray-400">Check out this guide to
                                            learn how to <a
                                                href="/docs/getting-started/introduction/"
                                                className="text-blue-600 dark:text-blue-500 hover:underline">get
                                                started</a> and
                                            start
                                            developing websites even faster with components on top of Tailwind CSS.
                                        </p>

                                    </>
                                }
                            ]}/>
                            {/*<EffectsForm/>*/}
                        </div>
                    </div>
                },
                {
                    label: <span>Facebook</span>,
                    content: <div
                        className="grid grid-cols-6 gap-6  space-y-6 bg-white dark:bg-gray-950 px-4 py-0 sm:p-6">
                        <div className="col-span-6">
                            <ComingSoonView/>
                            {/*<EffectsForm/>*/}
                        </div>
                    </div>
                },
                {
                    label: <span>LinkedIn</span>,
                    content: <div
                        className="grid grid-cols-6 gap-6  space-y-6 bg-white dark:bg-gray-950 px-4 py-0 sm:p-6">
                        <div className="col-span-6">
                            <ComingSoonView/>
                            {/*<EffectsForm/>*/}
                        </div>
                    </div>
                },
            ]}/>
        </>
    )
}

export const ComingSoonView = () => {
    return <div className={'noData'}>
        <div>
            <div
                className="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12 flex justify-center items-center flex-col">

                <svg id="fi_5229336" className={'w-[250px] h-[250px] opacity-50'} enableBackground="new 0 0 512 512"
                     height="512" viewBox="0 0 512 512" width="512"
                     xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <ellipse cx="336.262" cy="128.389" rx="9.997" ry="9.997"
                                 transform="matrix(.478 -.878 .878 .478 62.671 362.278)"></ellipse>
                        <path
                            d="m47.32 242.877c1.252 4.409 5.267 7.286 9.634 7.285.906 0 1.827-.124 2.742-.384l38.891-11.043 2.791 9.83c1.252 4.409 5.267 7.285 9.634 7.285.906 0 1.827-.124 2.742-.384l9.456-2.685 19.558 33.133c5.868 9.941 16.448 15.742 27.553 15.742 2.965 0 5.97-.414 8.93-1.273 9.567-2.776 17.159-9.68 20.832-18.939 3.672-9.259 2.874-19.49-2.189-28.068l-11.021-18.671 34.135-9.693 144.788 21.579c7.073 1.058 13.068-5.769 11.115-12.647l-18.494-65.13c-1.512-5.323-7.055-8.413-12.375-6.902-5.323 1.512-8.413 7.052-6.902 12.375l14.253 50.195-125.173-18.655-21.304-75.026 96.688-81.654 11.076 39.006c1.512 5.323 7.052 8.413 12.375 6.902s8.413-7.052 6.902-12.375l-15.317-53.943c-.93-3.273-3.457-5.852-6.712-6.846-3.255-.995-6.791-.268-9.391 1.928l-111.841 94.451-107.254 30.455c-2.556.726-4.72 2.437-6.014 4.758s-1.614 5.061-.887 7.617l2.791 9.83-38.89 11.043c-2.556.726-4.72 2.437-6.014 4.758s-1.614 5.061-.887 7.617zm133.315 20.685c1.893 3.207 2.192 7.032.819 10.495-1.373 3.462-4.212 6.044-7.79 7.082-5.251 1.522-10.86-.701-13.64-5.41l-16.886-28.606 23.803-6.759zm7.975-123.869 19.363 68.189-90.055 25.571-19.363-68.188zm-103.804 50.514 8.306 29.252-29.252 8.306-8.306-29.252z"></path>
                        <path
                            d="m431.729 117.532c.901 0 1.818-.122 2.727-.379l23.762-6.707c5.326-1.503 8.424-7.039 6.921-12.364-1.503-5.326-7.044-8.425-12.364-6.921l-23.762 6.707c-5.326 1.503-8.424 7.039-6.921 12.364 1.246 4.416 5.265 7.3 9.637 7.3z"></path>
                        <path
                            d="m380.745 37.729c2.609 0 5.216-1.013 7.18-3.03l17.225-17.689c3.861-3.964 3.776-10.308-.189-14.168-3.964-3.861-10.308-3.776-14.168.188l-17.225 17.689c-3.861 3.965-3.776 10.308.188 14.169 1.948 1.897 4.47 2.841 6.989 2.841z"></path>
                        <path
                            d="m427.532 211.905 23.931 6.072c5.377 1.365 10.961-2.054 12.222-7.444 1.226-5.241-2.079-10.657-7.293-11.98l-23.931-6.072c-5.36-1.361-10.815 1.884-12.176 7.248s1.884 10.815 7.247 12.176z"></path>
                        <path
                            d="m359.195 370.093 24.818 35.939c2.864 4.135 7.671 5.884 12.25 4.454 4.654-1.454 7.66-5.761 7.66-11.07l-.596-61.681c-.053-5.533-4.558-10-10.116-9.922-5.533.053-9.976 4.583-9.922 10.116l.312 32.241-26.181-37.912c-2.492-3.608-7.041-5.176-11.227-3.872-4.186 1.305-7.038 5.181-7.038 9.566v63.712c0 5.534 4.486 10.02 10.02 10.02s10.02-4.486 10.02-10.02z"></path>
                        <path
                            d="m272.377 411.007c5.435-1.039 8.999-6.287 7.961-11.722l-11.883-62.179c-.008-.04-.016-.08-.023-.12-1.039-5.097-5.391-8.862-10.585-9.155-5.203-.295-9.942 2.957-11.548 7.905-.037.115-.072.23-.105.346l-9.505 33.344-9.867-33.443c-.035-.119-.073-.238-.113-.356-1.657-4.932-6.443-8.142-11.631-7.785-5.191.348-9.504 4.159-10.488 9.268-.008.038-.015.077-.022.115l-11.219 62.16c-.983 5.446 2.635 10.657 8.08 11.64 5.45.983 10.657-2.635 11.64-8.08l4.788-26.528 7.995 27.099c.035.118.072.236.112.353 1.571 4.68 5.932 7.814 10.864 7.814h.059c4.956-.024 9.314-3.209 10.844-7.923.037-.115.072-.23.105-.346l7.716-27.067 5.102 26.699c1.039 5.436 6.29 9 11.723 7.961z"></path>
                        <path
                            d="m300.97 337.832v63.832c0 5.534 4.486 10.02 10.02 10.02s10.02-4.486 10.02-10.02v-63.832c0-5.534-4.486-10.02-10.02-10.02s-10.02 4.487-10.02 10.02z"></path>
                        <path
                            d="m482.211 359.729h-19.095c-5.534 0-10.02 4.486-10.02 10.02s4.486 10.02 10.02 10.02h7.529c-2.268 6.662-7.208 11.877-15.985 11.877-12.074 0-21.896-9.822-21.896-21.896s9.822-21.896 21.896-21.896c4.389 0 8.62 1.291 12.235 3.734 4.586 3.098 10.814 1.892 13.911-2.693 3.098-4.585 1.892-10.814-2.693-13.911-6.941-4.689-15.051-7.168-23.454-7.168-23.123 0-41.935 18.812-41.935 41.935s18.812 41.935 41.935 41.935c22.121 0 37.571-17.245 37.571-41.935.001-5.537-4.485-10.022-10.019-10.022z"></path>
                        <path
                            d="m88.248 384.976c-4.241-3.555-10.561-2.999-14.115 1.241-.492.587-1.022 1.134-1.576 1.628-2.831 2.522-6.482 3.8-10.852 3.8-12.074 0-21.896-9.822-21.896-21.896s9.822-21.896 21.896-21.896c4.388 0 8.619 1.291 12.235 3.734 4.587 3.099 10.814 1.891 13.911-2.693 3.098-4.586 1.892-10.814-2.693-13.911-6.942-4.69-15.052-7.168-23.454-7.168-23.123 0-41.935 18.812-41.935 41.935s18.812 41.935 41.935 41.935c9.3 0 17.661-3.069 24.18-8.874 1.278-1.138 2.491-2.389 3.605-3.718 3.556-4.242 3-10.562-1.241-14.117z"></path>
                        <path
                            d="m142.51 327.813c-23.123 0-41.935 18.812-41.935 41.935s18.812 41.935 41.935 41.935 41.935-18.812 41.935-41.935c.001-23.123-18.811-41.935-41.935-41.935zm0 63.831c-12.074 0-21.896-9.822-21.896-21.896s9.822-21.896 21.896-21.896 21.896 9.822 21.896 21.896c.001 12.074-9.822 21.896-21.896 21.896z"></path>
                        <path
                            d="m410.381 428.13c-5.533.053-9.976 4.583-9.922 10.116l.312 32.241-26.181-37.912c-2.492-3.608-7.04-5.175-11.227-3.872-4.186 1.305-7.038 5.181-7.038 9.566v63.712c0 5.534 4.486 10.02 10.02 10.02s10.02-4.486 10.02-10.02v-31.57l24.818 35.939c2.864 4.136 7.67 5.884 12.25 4.454 4.654-1.454 7.661-5.761 7.66-11.07l-.596-61.68c-.053-5.535-4.586-10.024-10.116-9.924z"></path>
                        <path
                            d="m132.696 461.168c-7.48-2.759-14.468-5.756-16.977-6.85-.682-.664-.741-1.497-.652-2.121.224-1.563 1.553-2.82 3.646-3.451 8.042-2.427 17.19 4.241 17.429 4.42 4.352 3.371 10.615 2.602 14.019-1.735 3.416-4.353 2.657-10.652-1.697-14.068-1.745-1.369-17.563-13.219-35.531-7.804-9.581 2.886-16.363 10.47-17.702 19.791-1.262 8.795 2.552 17.256 9.955 22.083.443.289.908.542 1.39.757.372.166 9.222 4.105 19.184 7.78 3.725 1.374 7.537 3.781 7.067 6.416-.402 2.249-3.585 5.576-9.142 5.576-5.777 0-11.318-2.307-14.819-6.171-3.716-4.101-10.053-4.411-14.153-.696-4.1 3.716-4.412 10.053-.696 14.153 7.345 8.105 18.159 12.753 29.668 12.753 14.442 0 26.583-9.291 28.869-22.092 1.735-9.715-2.572-22.365-19.858-28.741z"></path>
                        <path
                            d="m208.489 428.13c-23.123 0-41.935 18.812-41.935 41.935s18.812 41.935 41.935 41.935 41.935-18.812 41.935-41.935c.001-23.123-18.811-41.935-41.935-41.935zm0 63.831c-12.073 0-21.896-9.822-21.896-21.896 0-12.073 9.822-21.896 21.896-21.896s21.896 9.822 21.896 21.896c.001 12.073-9.822 21.896-21.896 21.896z"></path>
                        <path
                            d="m301.896 428.13c-23.123 0-41.935 18.812-41.935 41.935s18.812 41.935 41.935 41.935 41.935-18.812 41.935-41.935-18.812-41.935-41.935-41.935zm0 63.831c-12.074 0-21.896-9.822-21.896-21.896 0-12.073 9.822-21.896 21.896-21.896s21.896 9.822 21.896 21.896c0 12.073-9.822 21.896-21.896 21.896z"></path>
                    </g>
                </svg>

            </div>
        </div>
    </div>;

};

export {TabsSocials}