import React, {useEffect, useState} from "react";
import {api, FormSelect, sleep} from "front-lib";
import axios from "axios";
import {ButtonPlayAudio} from "../buttons/ButtonPlayAudio";
import {createGlobalState} from 'react-hooks-global-state';


interface IProps {
    // name?: string;
    defaultValue?: string;
    readOnly?: boolean
    rules?: any
}

const FormElevenVoices = ({defaultValue, readOnly, ...props}: IProps) => {

    const {elevenVoices} = useElevenVoices();
    return (
        <>
            <FormSelect label={"Voice"} name={"voiceId"}
                        className={'max-h-[400px] min-w-[460px] overflow-y-scroll z-30'}
                        renderOption={({option}: any) => {

                            const accent = option.labels.accent;
                            const description = option.labels.description;
                            const useCase = option.labels["use case"];
                            return <div className={'flex items-center'}>
                                <div onClick={(e) => e.stopPropagation()}>
                                    <ButtonPlayAudio url={option.previewUrl}/>
                                </div>
                                <span className={'mx-2'}> {option.name}</span>
                                <div className={'space-x-2'}>
                                    {accent && <span
                                        className="bg-error-400 text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-primary-700 dark:text-white">
                                        {accent}
                                    </span>}
                                    {description && <span
                                        className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                                          {description}
                                    </span>}
                                    {useCase && <span
                                        className="bg-warning-100 text-warning-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-warning-900 dark:text-warning-300">
                                        {useCase}
                                    </span>}
                                </div>
                            </div>

                        }}
                        options={elevenVoices} defaultValue={defaultValue}
                        readOnly={readOnly} {...props} />
        </>
    );
};

const initialState = {elevenVoices: []};
const {useGlobalState} = createGlobalState(initialState);
let initVoices = false;
export const useElevenVoices = () => {

    const [elevenVoices, setElevenVoices] = useGlobalState('elevenVoices');

    const getVoiceById = (voiceId: string) => {
        return elevenVoices.find((v: any) => v.value === voiceId)
    }
    useEffect(() => {
        const fetchOptions = async () => {
            try {
                console.log("LOADING 11 VOICES")
                const response = await axios.get("https://api.elevenlabs.io/v1/voices");
                const options = response.data.voices.map((v: any) => {
                    return {
                        value: v.voice_id,
                        name: v.name,
                        labels: v.labels || [],
                        previewUrl: v.preview_url
                    }
                })
                // await sleep(2000)
                // const options: any = [{
                //     "value": "21m00Tcm4TlvDq8ikWAM",
                //     "name": "Rachel",
                //     "labels": {
                //         "accent": "american",
                //         "description": "calm",
                //         "age": "young",
                //         "gender": "female",
                //         "use case": "narration"
                //     },
                //     "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/21m00Tcm4TlvDq8ikWAM/df6788f9-5c96-470d-8312-aab3b3d8f50a.mp3",
                //
                // }]
                setElevenVoices(options);
            } catch (error) {
                console.error("Error fetching options:", error);
            }
        };

        if (!initVoices) {
            initVoices = true
            fetchOptions();
        }
    }, []);

    return {
        elevenVoices,
        getVoiceById
    }
}

//
// const options = (() => {
//     const items = [
//         {
//             value: "2EiwWnXFnvU5JabPnv8n",
//             name: "Clyde"
//         },
//         {
//             value: "AZnzlk1XvdvUeBnXmlld",
//             name: "Domi"
//         },
//         {
//             value: "CYw3kZ02Hs0563khs1Fj",
//             name: "Dave"
//         }
//     ];
//
//     return items;
// })();
export default FormElevenVoices;
