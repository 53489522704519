import React from "react";
import {Controller} from "react-hook-form";
import {RiArrowDropDownLine, RiArrowDropUpLine} from "react-icons/ri";
import {Popover} from "front-lib";
import {useForm} from "../context/useForm";

const FormDropdown = ({
                          name,
                          defaultValue,
                          rules,
                          label,
                          placeholder,
                          className,
                          options,
                          renderOption
                      }: any) => {
    const {control, watch} = useForm();
    const value = watch(name, defaultValue)
    const selectedOption = options.find((o: any) => o.value === value);
    // console.log("defaultValue", defaultValue)
    return <>
        <Controller
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            name={name}
            render={({field}: any) => (
                <div className={'relative'}>
                    {label && <label htmlFor={name}
                                     className="block mb-2 text-sm font-medium text-gray-950 dark:text-white">
                        {label}
                    </label>}
                    <Popover
                        containerClassName={"relative"}
                        className={`z-10 bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-800 absolute w-full ${className}`}
                        renderTrigger={({isOpen}: any) => {
                            return <button
                                className="flex items-center justify-between  bg-gray-50 border border-gray-300 text-gray-950 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-gray-300 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                type="button">
                                {selectedOption && <>{selectedOption.name}</>}
                                {!isOpen && <RiArrowDropDownLine className="w-6 h-6 ml-1"/>}
                                {isOpen && <RiArrowDropUpLine className="w-6 h-6 ml-1"/>}
                            </button>
                        }}
                        renderContent={({toggleOpen}: any) => {
                            return <ul className={`py-1 text-sm text-gray-700 dark:text-gray-200 w-full`}
                                       onClick={toggleOpen}
                                       aria-labelledby="states-button">
                                {options.map((option: any) => {
                                    const selected = selectedOption && selectedOption.value === option.value;
                                    return <li key={`dd-${option.value}`} onClick={() => {
                                        console.log("set option", option)
                                        field.onChange(option.value)
                                    }}>
                                        {renderOption({option, selected})}
                                    </li>
                                })}

                            </ul>
                        }}
                    />
                </div>
            )}/>
    </>
}

export {FormDropdown}