import React from 'react';
import {Helmet} from 'react-helmet-async';
import {QuickGettingStartedWizard} from "../../../modules/quicks/getting-started/QuickGettingStartedWizard";

function QuickGettingStartedRoute() {
    return (
        <>
            <Helmet>
                <title>Clipz - AI-Driven Video Editing</title>
            </Helmet>
            {/*<HeroSection/>*/}
            <QuickGettingStartedWizard/>
            {/*<ContactUsSection/>*/}
        </>
    );
}

export default QuickGettingStartedRoute;
