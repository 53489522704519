import React, {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {WhiteLabelThemeProvider} from "../../theme";
const DialogAnimated = ({title, isOpen, onClose, children, size = "2xl" , className}: any) => {

    const sizes: any = {
        "2xl": "max-w-2xl",
        "xl": "max-w-xl",
        "lg": "max-w-lg",
        "md": "max-w-md",
        "sm": "max-w-sm",
    }
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className={`relative z-50 ${className}`} onClose={onClose}>
                    <WhiteLabelThemeProvider>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25 dark:bg-opacity-75"/>
                        </Transition.Child>
                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel
                                        className={`w-full ${sizes[size]} transform overflow-hidden rounded-2xl bg-white dark:bg-gray-900 text-left align-middle shadow-xl transition-all`}>
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-950 dark:text-white flex justify-between items-center p-4 sm:p-6 !pb-4">
                                            {title}
                                            <button type="button"
                                                    onClick={onClose}
                                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-950 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                                    data-modal-toggle="defaultModal">
                                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor"
                                                     viewBox="0 0 20 20"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                          clipRule="evenodd"/>
                                                </svg>
                                                <span className="sr-only">Close modal</span>
                                            </button>
                                        </Dialog.Title>
                                        {children}
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </WhiteLabelThemeProvider>
                </Dialog>
            </Transition>
        </>
    )
}

export {DialogAnimated}