import React, {useState} from "react"
import {WizardContextProvider} from "../wizard/WizardContext";
import {LoadingCover, withForm} from "front-lib";
import {StepUploadOrRecord} from "./steps/StepUploadOrRecord";
import {useNavigate} from "react-router-dom";

const QuickGettingStartedWizard = withForm(() => {

    return (
        <WizardContextProvider>
            <QuickAutoCaptionsWizardContent/>
        </WizardContextProvider>
    )
})
const QuickAutoCaptionsWizardContent = () => {
    const step = 1;
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    return <>
        <LoadingCover isLoading={isLoading} text={"Processing Video..."}
                      className={'absolute flex z-50 items-center w-full top-[70px] left-0 bg-white dark:bg-gray-950 min-h-[calc(100%-70px)]'}/>
        <div className={'px-6 py-2.5 max-w-screen-xl mx-auto'}>
            {step == 1 && <StepUploadOrRecord onSubmit={async (blob: Blob) => {
                const blobUrl = URL.createObjectURL(blob);
                navigate(`/register?url=${encodeURIComponent(blobUrl)}`)
            }}/>}
        </div>
    </>
}
export {QuickGettingStartedWizard}