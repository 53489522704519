import React, {useState} from "react"
import {ButtonFormSubmit, DropzoneVideoUploader, LoadingCover, sleep, withForm} from "front-lib";
import {useWizard} from "../wizard/useWizard";
import {WizardContextProvider} from "../wizard/WizardContext";
import {SpotlightView} from "../../app/spotlight/SpotlightView";
import {useNavigate} from "react-router-dom";

const QuickSpotlightWizard = withForm(() => {

    return (
        <WizardContextProvider>
            <QuickSpotlightWizardContent/>
        </WizardContextProvider>
    )
})


const QuickSpotlightWizardContent = () => {

    const navigate = useNavigate();

    const onCompleted = (blob: Blob) => {
        console.log("onCompleted", blob);
        const blobUrl = URL.createObjectURL(blob);
        navigate(`/register?url=${encodeURIComponent(blobUrl)}`)
    }
    return <>

        <div className={'px-6 py-2.5 max-w-screen-xl mx-auto'}>
            <SpotlightView onCompleted={onCompleted}/>
        </div>
    </>
}

export {QuickSpotlightWizard}
