import {api} from "front-lib";

export const useWorkspaceApi = () => {

    const loadClipById = async (clipId: string) => {
        const response = await api.get(`/workspace/clips/${clipId}`)
        const clipDb = response.data;
        // return clipDb;
        return appendSourceFilesToClip(clipDb);
    }

    const loadMyClips = async () => {
        const response = await api.get('/workspace/clips');
        return response.data;
    }


    const editClip = async (clipId: string, propsToUpdate: any) => {
        const response = await api.put(`/workspace/clips/${clipId}`, propsToUpdate)
        // return response.data.clip
        return appendSourceFilesToClip(response.data.clip);
    }

    const deleteClip = async (clipId: string) => {
        console.log("deleteClip", clipId)
        const response = await api.delete(`/workspace/clips/${clipId}`)
    }

    const loadStats = async () => {
        const response = await api.get('/workspace/stats');
        return response.data.stats;
    }


    const appendSourceFilesToClip = async (clip: any) => {
        clip.files = [
            ...clip.files,
            {
                id: "file-1",
                url: clip.orgVideoUrl,
                description: "Original Video",
                duration: clip.duration,
                createdAt: clip.createdAt
            },
            {
                id: "file-2",
                url: clip.orgAudioUrl,
                description: "Original Audio",
                duration: clip.duration,
                createdAt: clip.createdAt
            },
        ];
        return clip;
    }

    return {
        loadClipById,
        loadMyClips,
        loadStats,
        editClip,
        deleteClip
    }
}

