import React from "react";
import {clipCrudFilters} from "./ClipCrudFilters";
import {clipColumns} from "./ClipColumns";
import GenericTable from "../../table/GenericTable";
import {useClipCrudApi} from "./api/useClipCrudApi";

const ClipCrudTable = ({withHeader = true}: any) => {
    const crudApi = useClipCrudApi();

    return (
        <>
            <GenericTable renderHeader={withHeader && <>
                <div className={'flex items-center'}>
                    <h3 className="font-semibold text-lg dark:text-white">Clips</h3>

                </div>
            </>}
                          crudApi={crudApi}
                          filters={clipCrudFilters}
                          columns={clipColumns}
            />
        </>
    );
};

export default ClipCrudTable;
