import React from "react";
import {FaDiscord, FaLinkedin,} from "react-icons/all";
import {FaYoutube} from "react-icons/fa";

const SocialIcons = ({className}: any) => {
    return (
        <>
            <ul className={`flex justify-center space-x-5 ${className}`}>

                <li className={'mb-0'}>
                    <a href="https://www.youtube.com/@clipz.studio.channel"
                       target={"_blank"}
                       className="hover:text-gray-300 dark:hover:text-primary-300 dark:text-gray-400">
                        <FaYoutube className="w-5 h-5"/>
                    </a>
                </li>
                <li className={'mb-0'}>
                    <a href="https://discord.gg/NpjJYUFS6d"
                       target={"_blank"}
                       className="hover:text-gray-300 dark:hover:text-primary-300 dark:text-gray-400">
                        <FaDiscord className="w-5 h-5"/>
                    </a>
                </li>

                {/*<li className={'mb-0'}>*/}
                {/*    <a href="https://twitter.com/clipz"*/}
                {/*       target={"_blank"}*/}
                {/*       className="hover:text-gray-950 dark:hover:text-primary-300 dark:text-gray-400">*/}
                {/*        <FaTwitter className="w-5 h-5"/>*/}
                {/*    </a>*/}
                {/*</li>*/}
                <li className={'mb-0'}>
                    <a href="https://www.linkedin.com/company/clipz-studio"
                       target={"_blank"}
                       className="hover:text-gray-300 dark:hover:text-primary-300 dark:text-gray-400">
                        <FaLinkedin className="w-5 h-5"/>
                    </a>
                </li>

            </ul>


        </>
    )
}

export default SocialIcons