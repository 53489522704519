import React, {useEffect, useRef, useState} from "react";
import {BiPause, BiPlay, BiVolume, BiVolumeFull, BiVolumeMute, BsPlay} from "react-icons/all";
import {MdFullscreen, MdFullscreenExit} from "react-icons/md";
import {formatTime} from "../../../engine/fabric/useFabric";

const ClipPlayerControls = ({clip, videoRef}: any) => {
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
    const [isMuted, setIsMuted] = useState<boolean>(false);
    const requestIdRef = useRef<any>(null);
    useEffect(() => {
        const onFullScreenChanged = () => {
            if (document.fullscreenElement) {
                setIsFullScreen(true)
            } else {
                setIsFullScreen(false)
            }
        }
        document.addEventListener("fullscreenchange", onFullScreenChanged, false);

        const onVideoEnded = () => {
            console.log("onVideoEnded")
            setIsPlaying(false)
        }
        videoRef.current.addEventListener('ended', onVideoEnded, false);

        return () => {
            document.removeEventListener("fullscreenchange", onFullScreenChanged);
            document.removeEventListener("ended", onVideoEnded);
        }
    }, []);

    //fix tracker location
    // useEffect(() => {
    //     console.log("fixTrackerLocation - clip changed")
    //     const fixTrackerLocation = () => {
    //         if (clip.skipCuts && !videoRef.current.paused) {
    //             console.log("fixTrackerLocation - check", videoRef.current.currentTime);
    //         }
    //         requestIdRef.current = requestAnimationFrame(fixTrackerLocation);
    //     };
    //
    //     if (isPlaying) {
    //         requestIdRef.current = requestAnimationFrame(fixTrackerLocation);
    //     }
    //
    //     return () => {
    //         // Clear animation frame when component unmounts or clip changes
    //         if (requestIdRef.current) {
    //             cancelAnimationFrame(requestIdRef.current);
    //         }
    //     };
    // }, [clip, isPlaying]);

    return (
        <>
            <div className={'relative z-40 w-full p-3.5 bg-gray-800 text-white'}>

                {/*0:0{parseInt(videoRef.current.currentTime)} /*/}
                {/*0:0{parseInt(videoRef.current.duration)}*/}
                <div className={'mb-2 flex items-center justify-between'}>
                    <div className={'flex items-center'}>
                        <button type="button"
                                onClick={() => {
                                    setIsPlaying(isPlaying => !isPlaying)
                                    if (!isPlaying) {
                                        videoRef.current.play();
                                    } else {
                                        videoRef.current.pause();
                                    }
                                }}
                                className="-ml-1 mr-1 p-0.5 text-white rounded-full hover:text-gray-950 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700">
                            {!isPlaying && <BiPlay className="w-6 h-6 relative left-[1px] "/>}
                            {isPlaying && <BiPause className="w-6 h-6"/>}
                        </button>
                        <ClipPlayerClock videoRef={videoRef} clip={clip}/>
                    </div>
                    <div className={'flex items-center'}>
                        <button type="button"
                                onClick={() => {
                                    setIsMuted(isMuted => !isMuted)
                                    videoRef.current.muted = !isMuted;

                                }}
                                className="-ml-1 mr-1 p-0.5 text-white rounded-full hover:text-gray-950 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700">
                            {isMuted && <BiVolumeMute className="w-6 h-6 relative"/>}
                            {!isMuted && <BiVolumeFull className="w-6 h-6"/>}
                        </button>
                        <button type="button"
                                onClick={async () => {
                                    const element = document.getElementById("clip-player-video");
                                    if (!isFullScreen) {
                                        await element?.requestFullscreen()
                                    } else {
                                        await document.exitFullscreen()
                                    }

                                }}
                                className="-ml-1 mr-1 p-0.5 text-white rounded-full hover:text-gray-950 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700">
                            {!isFullScreen && <MdFullscreen className="w-7 h-7 relative left-[1px] "/>}
                            {isFullScreen && <MdFullscreenExit className="w-7 h-7"/>}
                        </button>
                    </div>
                </div>
                <div className={'relative w-full h-3'}>
                    <ClipPlayerProgressTracker videoRef={videoRef} clip={clip}/>
                    <MarkingsForTracker videoRef={videoRef} clip={clip}/>
                </div>
            </div>
        </>
    )
}


const ClipPlayerProgressTracker = ({clip, videoRef}: any) => {
    const requestRef = React.useRef<any>();
    const isPlayingRef = React.useRef<any>();
    const [progress, setProgress] = React.useState(videoRef.current.currentTime)
    const outerDivRef = React.useRef<any>(null);
    const [isMouseDown, setIsMouseDown] = React.useState(false);
    const handleMouseDown = (e: any) => {
        console.log("handleMouseDown")
        setIsMouseDown(true);
        isPlayingRef.current = !videoRef.current.paused
        videoRef.current.pause();
        changeVideoCurrentTime(e.clientX);
    };

    const handleMouseUp = () => {
        // console.log("handleMouseUp")
        setIsMouseDown(false);
        if (isPlayingRef.current) {
            videoRef.current.play();
            isPlayingRef.current = false;
        }
    };
    const calcProgress = () => {
        return (videoRef.current?.currentTime / clip.duration) * 100;
    }

    function changeVideoCurrentTime(clientX: any) {
        const rect = outerDivRef.current.getBoundingClientRect();
        const mouseX = clientX - rect.left;
        const percentage = (mouseX / rect.width) * 100;
        setProgress(Math.min(percentage, 100));
        const duration = videoRef.current.duration;
        const newTime = Math.max(Math.min((duration / 100) * percentage, duration), 0);
        // console.log("newTime", newTime)
        videoRef.current.currentTime = newTime;
    }

    const handleMouseMove = (e: any) => {
        // console.log("handleMouseMove")
        if (isMouseDown && outerDivRef.current) {
            changeVideoCurrentTime(e.clientX);
        }
    };
    const animate = () => {
        const isPlaying = !videoRef.current?.paused;
        // console.log("isPlaying", isPlaying)
        if (isPlaying) {
            setProgress(calcProgress())
        }
        requestRef.current = requestAnimationFrame(animate);
    }
    useEffect(() => {
        animate()
    }, []);

    useEffect(() => {

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isMouseDown]);

    return <div className={'bg-gray-200 w-full h-full select-none cursor-pointer'} ref={outerDivRef}
                onMouseDown={handleMouseDown}
        // onMouseUp={handleMouseUp}
        // onMouseMove={handleMouseMove}
    >
        <div className={'bg-primary-200 h-full'} style={{width: `${progress}%`}}>
        </div>
    </div>
}

const MarkingsForTracker = ({clip, videoRef}: any) => {

    const {duration, captions} = clip;

    const captionsToMark = captions ? captions.filter((c: any) => c.shouldTake) : []
    return <>
        {/*<div className={'w-[15%] bg-secondary-200 h-1/2 absolute top-1/2 -translate-y-1/2 select-none pointer-events-none'} />*/}
        {captionsToMark.map((c: any) => {

            const left = ((c.startTime / duration) * 100) + '%';
            const width = ((c.endTime - c.startTime) / duration) * 100 + '%';
            return <div key={`cap-mark-${c.id}`} style={{left, width}}
                        className={'bg-secondary-200 h-full absolute top-full'}>

            </div>
        })}

        {/*<div className={'w-[15%] bg-secondary-200 h-full absolute top-full'}>*/}

        {/*</div>*/}
        {/*<div className={'w-[15%] left-[20%] bg-secondary-200 h-full absolute top-full'}>*/}

        {/*</div>*/}

    </>
}
export const ClipPlayerClock = ({clip, videoRef}: any) => {
    const requestRef = React.useRef<any>();
    const [currentTime, setCurrentTime] = React.useState(videoRef.current.currentTime)

    const animate = () => {
        setCurrentTime(videoRef?.current?.currentTime)
        requestRef.current = requestAnimationFrame(animate);
    }
    useEffect(() => {
        animate()
    }, []);
    return <>
        <span className={'absolute -top-[30px] left-[150px] z-30'}>{currentTime}</span>
        <span className={'select-none font-mono'}>
        {formatTime(currentTime)} / {formatTime(clip.duration)}

            {/*({currentTime.toFixed(2)})*/}
    </span>

    </>
}


export default ClipPlayerControls