import React from "react";
import {useForm} from "../context/useForm";
import {Controller} from "react-hook-form";
import {FormDropdown} from "./FormDropdown";

interface IProps {
    name: string,
    defaultValue?: string,
    rules?: any
    label?: string | JSX.Element
    placeholder?: string,
    options: any[]
}

interface IOption {
    name: string,
    value: string
}

const FormSelect = ({
                        name,
                        defaultValue = "",
                        rules,
                        label,
                        placeholder,
                        options,
                        renderOption,
                        onChange,
                        className

                    }: any) => {
    const {control} = useForm();

    // const nameOfValue = 'banana'
    // const nameOfName ='description'
    // console.log("defaultValue", defaultValue)
    return (

        <FormDropdown label={label} placeholder={placeholder} name={name} defaultValue={defaultValue} options={options}
                      rules={rules}
                      className={className}
                      onChange={onChange}
                      renderOption={({option, selected}: any) => <div
                          className={`p-2.5 cursor-pointer hover:bg-gray-50 hover:dark:bg-gray-700 w-full ${selected ? 'bg-gray-50 dark:bg-gray-700' : ''}`}>
                          {renderOption ? renderOption({option, selected}) : option.name}
                      </div>}/>

    );
};

export {FormSelect};


