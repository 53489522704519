import React from "react";
import {
    ButtonFormSubmit,
    DialogBasic,
    FormInputNumber,
    FormInputText,
    FormTextarea,
    Tabs,
    withNestedForm
} from "front-lib";
import ClipPartialPlayer from "../player/clip-player/ClipPartialPlayer";
import FormElevenVoices from "../forms/FormElevenVoices";

const DialogEditCaption = ({renderTrigger, ...rest}: any) => {

    return (
        <>
            <DialogBasic
                title={"Edit Caption"}
                renderTrigger={renderTrigger}
                renderContent={({toggleOpen}: any) => <ContentEditCaption toggleOpen={toggleOpen} {...rest}/>}
            />
        </>
    )
}


const ContentEditCaption = withNestedForm(({clip, caption, toggleOpen, onSubmit}: any) => {
    return <div>
        <div className={'px-6 pb-6'}>
            <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6">
                    <FormElevenVoices defaultValue={caption.voiceId || clip.voiceId}/>
                </div>
                <div className="col-span-6">
                    <FormTextarea name={`transcript`} rows={2}
                                  defaultValue={caption.transcript}/>
                </div>
                <div className="col-span-6 sm:col-span-3">
                    <FormInputNumber name={`startTime`}
                                     min={0}
                                     step={0.1}
                                     defaultValue={caption.startTime}/>
                </div>
                <div className="col-span-6 sm:col-span-3">
                    <FormInputNumber name={`endTime`}
                                     step={0.1}
                                     min={0}
                                     defaultValue={caption.endTime}/>
                </div>
                <div className="col-span-6">
                    <ClipPartialPlayer clip={clip} caption={caption}/>
                </div>
            </div>
        </div>
        <div className="bg-gray-50 dark:bg-gray-900 px-4 py-3   sm:px-6">
            <div className={'flex space-x-2'}>
                <ButtonFormSubmit onSubmit={(formState: any) => {
                    toggleOpen();
                    onSubmit(formState)
                }}/>
                <button type="button"
                        onClick={toggleOpen}
                        className="text-gray-500 bg-white hover:bg-gray-100  rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-950 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                    Cancel
                </button>
            </div>
        </div>
    </div>
})
export default DialogEditCaption
