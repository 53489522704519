import React, {useRef, useState} from "react";

export const useVideoFramesParser = () => {
    const videoRef = React.useRef<any>(null);
    const canvasRef = React.useRef<any>(null);

    const initVideoFramesParser = async (url: string, startTime = 0) => {
        console.log("initVideoFramesParser start")
        return new Promise((resolve, reject) => {
            videoRef.current = document.createElement('video');
            videoRef.current.crossOrigin = 'anonymous';
            videoRef.current.src = url;
            videoRef.current.currentTime = startTime;

            videoRef.current.onloadeddata = () => {
                console.log("initVideoFramesParser onloadeddata", videoRef.current.currentTime)
                canvasRef.current = document.createElement('canvas');
                canvasRef.current.width = videoRef.current.videoWidth;
                canvasRef.current.height = videoRef.current.videoHeight;
                const context = canvasRef.current.getContext('2d');

                context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
                const url = canvasRef.current.toDataURL();
                resolve(url);

            };

            videoRef.current.onerror = (error: any) => {
                reject(error);
            };

            videoRef.current.onabort = () => {
                reject(new Error('Video loading aborted'));
            };

            videoRef.current.load();
        });
    }
    const parseFrameFromVideo = async (seconds: number): Promise<string> => {
        console.log("parseFrameFromVideo", seconds)
        // return frames.current[frameNumber];
        return new Promise((resolve, reject) => {
            const seekHandler = () => {
                // console.log("seekHandler", seconds)
                videoRef.current.removeEventListener('seeked', seekHandler);
                const context = canvasRef.current.getContext('2d');
                context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
                const url = canvasRef.current.toDataURL();
                resolve(url);
            };

            videoRef.current.addEventListener('seeked', seekHandler);
            videoRef.current.currentTime = seconds
        });
    }

    return {
        initVideoFramesParser,
        parseFrameFromVideo
    }
}


