import React from "react";
import {IoMdArrowBack} from "react-icons/all";

const WizardBackButton = ({onClick , text = "Back"}: any) => {
    return (
        <>
            <button
                onClick={onClick}
                className="flex items-center   text-primary-500 hover:text-primary-700 font-medium rounded-lg text-md mr-2 mb-2 ">
                <IoMdArrowBack size={22}/>
                <span className={'ml-2'}>{text}</span>
            </button>
        </>
    )
}

export default WizardBackButton
