import React from 'react';
import {Helmet} from 'react-helmet-async';
import {QuickSpotlightWizard} from "../../../modules/quicks/spotlight/QuickSpotlightWizard";

function QuickSpotlightRoute() {
    return (
        <>
            <Helmet>
                <title>Clipz - AI-Driven Video Editing</title>
            </Helmet>
            {/*<HeroSection/>*/}
            <QuickSpotlightWizard/>
        </>
    );
}

export default QuickSpotlightRoute;
