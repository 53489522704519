import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Dashboard} from "../../../modules/app/dashboard/Dashboard";
import {api} from "front-lib";


function AppHomeRoute() {

    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <section className="">
                <Dashboard/>
            </section>
        </>
    );
}

export default AppHomeRoute;
