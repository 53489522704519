import React from 'react';

const IconLogo = ({className, size = 35}: any) => {
    return (
        <>
            <svg id="Layer_1" className={className} height="512" viewBox="0 0 48 48" width="512" xmlns="http://www.w3.org/2000/svg"
                 data-name="Layer 1">
                <path
                    d="m40 18h-33a1 1 0 0 0 -1 1v23a2.006 2.006 0 0 0 2 2h32a2.006 2.006 0 0 0 2-2v-22a2.006 2.006 0 0 0 -2-2zm0 24h-32v-22h32z"/>
                <path d="m8 28h32v14h-32z"/>
                <path d="m42 27a1 1 0 0 1 -1 1h-34a1 1 0 0 1 0-2h34a1 1 0 0 1 1 1z"/>
                <path
                    d="m33 28h-18a1 1 0 0 1 -.97-1.242l2-8a1 1 0 0 1 .97-.758h18a1 1 0 0 1 .97 1.242l-2 8a1 1 0 0 1 -.97.758zm-16.719-2h15.938l1.5-6h-15.938z"/>
                <path
                    d="m24 28a1.025 1.025 0 0 1 -.244-.03 1 1 0 0 1 -.727-1.212l2-8a1 1 0 1 1 1.94.484l-2 8a1 1 0 0 1 -.969.758z"/>
                <path
                    d="m14.832 17.5a1 1 0 0 1 -1-1.07l.58-8.226a1 1 0 0 1 .824-.914l17.728-3.124a.992.992 0 0 1 .844.241 1 1 0 0 1 .328.814l-.58 8.225a1 1 0 0 1 -.824.914l-17.727 3.126a.928.928 0 0 1 -.173.014zm1.522-8.376-.435 6.176 15.7-2.769.435-6.169z"/>
                <path
                    d="m23.7 15.939h-.071a1 1 0 0 1 -.928-1.068l.58-8.225a1 1 0 0 1 1.995.14l-.58 8.226a1 1 0 0 1 -.996.927z"/>
                <path
                    d="m6.953 18.892a1 1 0 0 1 -.985-.827l-1.216-6.893a2 2 0 0 1 1.623-2.318l31.514-5.554a1.97 1.97 0 0 1 1.494.331 1.991 1.991 0 0 1 .823 1.292l1.042 5.907a2 2 0 0 1 -1.623 2.318l-32.5 5.73a.928.928 0 0 1 -.172.014zm31.283-13.624-31.514 5.555 1.042 5.91 31.513-5.556zm1.042 5.909.174.984-.173-.985z"/>
            </svg>
            {/*<svg id="Layer_1" enableBackground="new 0 0 24 24" className={className} height="512" viewBox="0 0 24 24" width="512"*/}
            {/*     xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">*/}
            {/*    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="1" x2="23" y1="12" y2="12">*/}
            {/*        <stop offset="0" stopColor="#d63b5c"/>*/}
            {/*        <stop offset="1" stopColor="#a72e47"/>*/}
            {/*    </linearGradient>*/}
            {/*    <path*/}
            {/*        d="m12 18c-1.6 0-3.1-.6-4.2-1.8-1.2-1.1-1.8-2.6-1.8-4.2 0-.6-.4-1-1-1s-1 .4-1 1c0 2.1.8 4.1 2.3 5.7 1.6 1.5 3.6 2.3 5.7 2.3.6 0 1-.4 1-1s-.4-1-1-1zm7.8-13.8c-2.1-2.1-4.9-3.2-7.8-3.2s-5.7 1.1-7.8 3.2-3.2 4.9-3.2 7.8 1.1 5.7 3.2 7.8 4.9 3.2 7.8 3.2 5.7-1.1 7.8-3.2 3.2-4.9 3.2-7.8-1.1-5.7-3.2-7.8zm-1.4 14.2c-1.7 1.7-4 2.6-6.4 2.6s-4.7-.9-6.4-2.6c-1.7-1.7-2.6-4-2.6-6.4s.9-4.7 2.6-6.4 4-2.6 6.4-2.6 4.7.9 6.4 2.6 2.6 4 2.6 6.4-.9 4.7-2.6 6.4z"*/}
            {/*        fill="url(#SVGID_1_)"/>*/}
            {/*</svg>*/}
        </>
    )
}

export {IconLogo}