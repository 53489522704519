import React from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Link} from "react-router-dom";

const LinkPreviewBox = ({url, showNewTab = true}: any) => {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };
    return (
        <div className={'flex justify-between items-center bg-primary-300 dark:bg-gray-900 p-5 relative '}>
            <code
                className={'text-white dark:text-primary-400 pr-3  max-w-[calc(100%_-_100px] break-all'}>{url}</code>
            <CopyToClipboard text={url}>
                <button type="button"
                        onClick={handleTooltipOpen}
                        className="text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-2 py-1 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none ">
                    Copy
                </button>
            </CopyToClipboard>
            {showNewTab && <Link to={url} target={"_blank"}
                                 className={'absolute -bottom-[16px] left-0 text-xs text-gray-400 hover:underline'}>Open
                in new
                tab</Link>}
        </div>
    )
}

export {LinkPreviewBox}