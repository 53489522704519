import React, {createContext, useEffect, useRef, useState} from "react";
import {WizardContext} from "./context";


const WizardContextProvider = ({children}: any) => {

    const [step, setStep] = useState(1)
    const goNext = () => {
        // await createEnvironment(fieldValues);
        setStep(s => s + 1);
    }
    const goBack = () => {
        console.log("onBack");
        setStep(s => s - 1);
    }

    const context = {step, goNext, goBack}

    return <WizardContext.Provider value={context}>
        {children}
    </WizardContext.Provider>;
};


export {WizardContextProvider};


