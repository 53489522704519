import React from "react"
import {GuardCannotBeAuthenticated, GuardPrivateRoute, Role, withLazyComponent} from "front-lib";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import SharedLayout from "../modules/layout/SharedLayout";
import AppLayout from "../modules/layout/AppLayout";
import SiteLayout from "../modules/layout/SiteLayout";
import AuthLayout from "../modules/layout/AuthLayout";

import HomeRoute from "./routes/site/HomeRoute";

const PlaygroundRoute = withLazyComponent(React.lazy(() => import( "./routes/app/dev/PlaygroundRoute")));
import LoginRoute from "./routes/auth/LoginRoute";
import {DevToolsRoute} from "./routes/app/dev/DevToolsRoute";

const SystemDashboardRoute = withLazyComponent(React.lazy(() => import( "./routes/app/admin/SystemDashboardRoute")));
import UsersRoute from "./routes/app/admin/UsersRoute";
import NotFoundRoute from "./routes/errors/NotFoundRoute";
import RegisterRoute from "./routes/auth/RegisterRoute";
import SystemConfigsRoute from "./routes/app/admin/SystemConfigsRoute";
import MyProfileRoute from "./routes/app/MyProfileRoute";
import TicketsRoute from "./routes/app/admin/TicketsRoute";
import TranslationsRoute from "./routes/app/admin/TranslationsRoute";
import FeatureFlagsRoute from "./routes/app/admin/FeatureFlagsRoute";
import ForgotPasswordRoute from "./routes/auth/ForgotPasswordRoute";
import CacheRoute from "./routes/app/dev/CacheRoute";
import NotificationsRoute from "./routes/app/admin/NotificationsRoute";
import SocketsRoute from "./routes/app/dev/SocketsRoute";
import AuditLogRoute from "./routes/app/admin/AuditLogRoute";
import TermsAndConditionsRoute from "./routes/site/TermsAndConditionsRoute";
import PrivacyPolicyRoute from "./routes/site/PrivacyPolicyRoute";

const ColorPaletteRoute = withLazyComponent(React.lazy(() => import("./routes/app/dev/ColorPaletteRoute")));
import ReferralsRoute from "./routes/app/ReferralsRoute";
import SocialAuthCallbackRoute from "./routes/auth/SocialAuthCallbackRoute";
import CareersRoute from "./routes/site/CareersRoute";
import WorkspacesRoute from "./routes/app/admin/ai/WorkspacesRoute";
import ConnectRoute from "./routes/auth/ConnectRoute";
import ClipEditorRoute from "./routes/app/ai/ClipEditorRoute";
import ClipCreationRoute from "./routes/app/ClipCreationRoute";
import PublicClipPlayerRoute from "./routes/site/PublicClipPlayerRoute";
import QuickGettingStartedRoute from "./routes/quicks/QuickGettingStartedRoute";
import AddWatermarkRoute from "./routes/quicks/AddWatermarkRoute";
import AppHomeRoute from "./routes/app/AppHomeRoute";
import QuickSpotlightRoute from "./routes/quicks/QuickSpotlightRoute";
import ClipsRoute from "./routes/app/admin/ClipsRoute";
import SpotlightRoute from "./routes/app/ai/SpotlightRoute";
import MyWorkspaceRoute from "./routes/app/ai/MyWorkspaceRoute";
import SocialsRoute from "./routes/app/ai/SocialsRoute";

const HugsRoute = withLazyComponent(React.lazy(() => import( "./routes/app/dev/HugsRoute")));

function AppRouter() {
    // console.log("AppRouter";

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<SharedLayout/>}>

                    {/*WebSite Routes*/}
                    <Route path="/" element={<SiteLayout/>}>
                        <Route index element={<HomeRoute/>}/>
                        <Route path="terms" element={<TermsAndConditionsRoute/>}/>
                        <Route path="privacy" element={<PrivacyPolicyRoute/>}/>
                        <Route path="careers" element={<CareersRoute/>}/>
                        <Route path="clip/:clipId" element={<PublicClipPlayerRoute/>}/>
                    </Route>
                    {/*Quicks Routes*/}
                    <Route path="/" element={<GuardCannotBeAuthenticated element={<SiteLayout/>}/>}>
                        <Route path="getting-started" element={<QuickGettingStartedRoute/>}/>
                        {/*<Route path="watermark" element={<AddWatermarkRoute/>}/>*/}
                        <Route path="spotlight" element={<QuickSpotlightRoute/>}/>
                    </Route>
                    {/*Blog Routes*/}
                    {/*<Route path="/blog" element={<SiteLayout/>}>*/}
                    {/*    /!*<Route path="article-1" element={<BlogRoute/>}/>*!/*/}
                    {/*    <Route index element={<BlogHomeRoute/>}/>*/}
                    {/*    <Route path=":articleSlug" element={<BlogPostRoute/>}/>*/}
                    {/*</Route>*/}

                    {/*Auth Routes*/}
                    <Route path="/" element={<GuardCannotBeAuthenticated element={<AuthLayout/>}/>}>
                        <Route path="/connect" element={<ConnectRoute/>}/>
                        {/*<Route path="/connect/:referralId" element={<ConnectRoute/>}/>*/}
                        <Route path="/login" element={<LoginRoute/>}/>
                        <Route path="/register" element={<RegisterRoute/>}/>
                        <Route path="/register/:referralId" element={<RegisterRoute/>}/>
                        <Route path="/admin/login" element={<LoginRoute/>}/>
                    </Route>

                    <Route path="/" element={<AuthLayout/>}>
                        <Route path="auth/social/cb" element={<SocialAuthCallbackRoute/>}/>
                        {/*<Route path="register" element={<RegisterRoute/>}/>*/}
                        <Route path="forgot-password" element={<ForgotPasswordRoute/>}/>
                        {/*<Route path="set-password/:token" element={<SetPasswordRoute/>}/>*/}
                        {/*<Route path="email-confirmation/:token" element={<EmailConfirmationRoute/>}/>*/}
                    </Route>

                    {/*App Routes*/}
                    <Route path="/app" element={<GuardPrivateRoute element={<AppLayout/>}/>}>
                        <Route index element={<AppHomeRoute/>}/>
                        <Route path={"clips/create"} element={<ClipCreationRoute/>}/>
                        <Route path={"clips/:clipId"} element={<ClipEditorRoute/>}/>
                        <Route path={"workspace"} element={<MyWorkspaceRoute/>}/>
                        <Route path={"spotlight"} element={<SpotlightRoute/>}/>
                        <Route path={"socials"} element={<SocialsRoute/>}/>
                        <Route path={"profile"} element={<MyProfileRoute/>}/>
                        {/*<Route path={"packages/mine"} element={<MyPackagesRoute/>}/>*/}

                        <Route path={"referrals"} element={<ReferralsRoute/>}/>
                        {/*<Route path="moments" element={<MyMomentsRoute/>}/>*/}
                        <Route path="*" element={<NotFoundRoute/>}/>
                    </Route>


                    {/*Dev Routes*/}
                    <Route path="/app/dev"
                           element={<GuardPrivateRoute role={Role.Developer} element={<AppLayout/>}/>}>
                        <Route index element={<DevToolsRoute/>}/>
                        <Route path="cache" element={<CacheRoute/>}/>
                        <Route path="playground" element={<PlaygroundRoute/>}/>
                        <Route path="hugs" element={<HugsRoute/>}/>
                        <Route path="sockets" element={<SocketsRoute/>}/>
                        <Route path="colors" element={<ColorPaletteRoute/>}/>
                        <Route path="*" element={<NotFoundRoute/>}/>
                    </Route>


                    {/*Admin Routes*/}
                    <Route path="/app/admin"
                           element={<GuardPrivateRoute role={Role.Admin} element={<AppLayout/>}/>}>
                        <Route path="system" element={<SystemConfigsRoute/>}/>
                        <Route path="dashboard" element={<SystemDashboardRoute/>}/>
                        <Route path="users" element={<UsersRoute/>}/>
                        <Route path="tickets" element={<TicketsRoute/>}/>
                        <Route path="translations" element={<TranslationsRoute/>}/>
                        <Route path="notifications" element={<NotificationsRoute/>}/>
                        <Route path="flags" element={<FeatureFlagsRoute/>}/>
                        <Route path="audit" element={<AuditLogRoute/>}/>
                        <Route path="workspaces" element={<WorkspacesRoute/>}/>
                        <Route path="clips" element={<ClipsRoute/>}/>

                        {/*<Route path="test" element={<TestRoute/>}/>*/}
                        <Route path="*" element={<NotFoundRoute/>}/>
                    </Route>

                    <Route path="/" element={<SiteLayout/>}>
                        <Route path="/*" element={<NotFoundRoute/>}/>
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}


export default AppRouter;
