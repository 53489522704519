import React from 'react';
import {FormInputHidden, FormSwitch, FormTextarea, useForm} from "front-lib";
import {formatTime} from "../../engine/fabric/useFabric";
import {FaPlay} from "react-icons/fa";
import {ButtonTranscribeClip} from "../buttons/ButtonTranscribeClip";
import DialogEditCaption from "../dialogs/DialogEditCaption";
import {BiEdit} from "react-icons/bi";
import {ButtonPlayAudio} from "../buttons/ButtonPlayAudio";
import {useElevenVoices} from "./FormElevenVoices";

const CaptionsTimeline = ({clip}: any) => {
    const {captions} = clip;
    const {watch} = useForm();
    const skipCuts = watch("skipCuts", clip.skipCuts);
    console.log("captions", captions)
    return (
        <>
            {(!captions || !captions.length) && <NoCaptionsMessage clip={clip}/>}
            {(captions && !!captions.length) &&
                <ol className="relative border-l ml-1 border-gray-200 dark:border-gray-700">
                    {
                        captions && captions.map((c: any, index: number) => {
                            return <TimelineItem key={`cap-${c.id}`}
                                                 clip={clip} caption={c}
                                                 index={index}
                                                 showSwitch={skipCuts}
                                                 isLast={index == captions.length - 1}/>
                        })
                    }
                </ol>}
        </>
    )
}

export const calcActiveCaptionsDuration = (clip: any) => {
    const {captions, duration, skipCuts} = clip
    if (!captions || !skipCuts) return duration;
    return parseFloat((captions.reduce(function (a: any, obj: any) {
        const duration = obj.shouldTake ? obj.endTime - obj.startTime : 0;
        return a + duration;
    }, 0)).toFixed(2))
}
const TimelineItem = ({clip, caption, index, showSwitch, isLast}: any) => {
    const {setValue} = useForm();
    const {getVoiceById} = useElevenVoices()

    const voice: any = getVoiceById(caption.voiceId || clip.voiceId)
    const deleteCaptionItem = () => {
        const captions = clip.captions.filter((c: any, i: number) => i != index);
        console.log("deleteCaptionItem", captions)
        clip.captions = [...captions];
        setValue("captions", captions, {shouldDirty: true})
    }
    const dubCaptionItem = () => {
        console.log("dubCaptionItem")
    }
    const saveChangesFromNestedForm = ({dirtyValues}: any) => {
        console.log("saveChangesFromNestedForm", dirtyValues)
        const captions = [...clip.captions];
        captions[index] = {...caption, ...dirtyValues};
        clip.captions = captions;
        setValue("captions", captions, {shouldDirty: true})
    }
    const onMouseEnter = () => {
        console.log("onMouseEnter", caption)
    }
    return <li className={`${!isLast ? 'mb-5' : ''} ml-4`} onMouseEnter={onMouseEnter}>
        <span
            className="absolute flex items-center justify-center w-6 h-6 bg-white rounded-full -left-3 -mt-1 ">
          <div className={'rounded-full shadow-lg bg-gray-400 w-2 h-2'}/>
        </span>
        <div
            className="p-4 bg-gray-100 border border-gray-200 rounded-lg shadow-sm   dark:bg-gray-700 dark:border-gray-600">
            <div className={'flex items-center justify-between  '}>
                <time
                    className="mb-1 text-xs font-normal text-gray-400  sm:mb-0">
                    {formatTime(caption.startTime)} - {formatTime(caption.endTime)}
                </time>

                {showSwitch && <div className={'flex'}>
                    <FormSwitch name={`captions[${index}].shouldTake`} defaultValue={caption.shouldTake}/>


                    {/*<CaptionButton text={"Cut"} icon={<FiScissors className="w-5 h-5  relative"/>}/>*/}

                </div>}
            </div>
            {/*<div className="text-sm font-normal text-gray-500 dark:text-gray-300">*/}
            {/*    /!*{caption.transcript}*!/*/}

            {/*</div>*/}
            <FormInputHidden name={`captions[${index}].id`}
                             defaultValue={caption.id}/>
            <FormInputHidden name={`captions[${index}].voiceUrl`}
                             defaultValue={caption.voiceUrl}/>

            <FormInputHidden name={`captions[${index}].startTime`}
                             defaultValue={caption.startTime}/>
            <FormInputHidden name={`captions[${index}].endTime`}
                             defaultValue={caption.endTime}/>
            <FormInputHidden name={`captions[${index}].transcript`}
                             defaultValue={caption.transcript}/>
            <div className={'mt-3 relative'}>
                <FormTextarea name={`captions[${index}].transcript`} rows={2}
                              defaultValue={caption.transcript}/>

                <div className={'flex space-x-1  mt-1'}>
                    {caption.voiceUrl && voice && <div>
                        <ButtonPlayAudio url={caption.voiceUrl}/>
                        <span className={'ml-1 mr-2'}> {voice.name}</span>
                    </div>}
                    <DialogEditCaption
                        clip={clip}
                        caption={caption}
                        onSubmit={saveChangesFromNestedForm}
                        renderTrigger={<button type="button"
                                               className="text-primary-200 hover:text-white border border-primary-200 hover:bg-primary-300 rounded-lg px-2 py-1 text-xs font-medium text-center dark:border-primary-300 dark:text-primary-300 dark:hover:text-white dark:hover:bg-primary-400">
                            Edit
                        </button>}/>
                    <button type="button"
                            onClick={dubCaptionItem}
                            className="text-primary-200 hover:text-white border border-primary-200 hover:bg-primary-300 rounded-lg px-2 py-1 text-xs font-medium text-center dark:border-primary-300 dark:text-primary-300 dark:hover:text-white dark:hover:bg-primary-400">
                        Dub
                    </button>
                    <button type="button"
                            onClick={deleteCaptionItem}
                            className="text-primary-200 hover:text-white border border-primary-200 hover:bg-primary-300 rounded-lg px-2 py-1 text-xs font-medium text-center dark:border-primary-300 dark:text-primary-300 dark:hover:text-white dark:hover:bg-primary-400">
                        Delete
                    </button>
                </div>
            </div>

        </div>
    </li>
}


const NoCaptionsMessage = ({clip}: any) => {
    return <div className={'noData'}>
        <div>
            <div
                className="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12 flex justify-center items-center flex-col">

                <svg id="fi_2658259" className={'text-gray-500 w-[100px] h-[100px] mb-5 opacity-75'}
                     enableBackground="new 0 0 512 512"
                     height="512" viewBox="0 0 512 512" width="512"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m310 512h-190c-66.168 0-120-53.832-120-120v-272c0-66.168 53.832-120 120-120h272c66.168 0 120 53.832 120 120v191.5c0 11.046-8.954 20-20 20s-20-8.954-20-20v-191.5c0-44.112-35.888-80-80-80h-272c-44.112 0-80 35.888-80 80v272c0 44.112 35.888 80 80 80h190c11.046 0 20 8.954 20 20s-8.954 20-20 20zm102-386c0-11.046-8.954-20-20-20h-183c-11.046 0-20 8.954-20 20s8.954 20 20 20h183c11.046 0 20-8.954 20-20zm0 80c0-11.046-8.954-20-20-20h-272c-11.046 0-20 8.954-20 20s8.954 20 20 20h272c11.046 0 20-8.954 20-20zm-200 80c0-11.046-8.954-20-20-20h-72c-11.046 0-20 8.954-20 20s8.954 20 20 20h72c11.046 0 20-8.954 20-20zm-112-162c0 13.807 11.193 25 25 25s25-11.193 25-25-11.193-25-25-25-25 11.193-25 25zm390.619 366.618c28.462-28.462 28.462-74.774 0-103.237l-70.109-70.108c-20.263-20.264-45.827-33.942-73.926-39.559l-56.664-11.326c-6.554-1.313-13.334.742-18.062 5.47-4.728 4.729-6.78 11.506-5.47 18.063l11.326 56.664c5.617 28.1 19.296 53.662 39.558 73.926l70.109 70.108c13.788 13.787 32.12 21.381 51.619 21.381s37.831-7.594 51.619-21.382zm-151.875-173.68c20.329 4.063 38.822 13.958 53.481 28.618l70.109 70.108c12.867 12.867 12.867 33.803 0 46.67-6.232 6.233-14.52 9.666-23.334 9.666s-17.102-3.433-23.334-9.666l-70.109-70.108c-14.659-14.66-24.555-33.153-28.618-53.481l-5.447-27.253z"></path>
                </svg>
                <h3 className="mb-2 text-3xl font-bold tracking-tight leading-none text-gray-950    dark:text-white">
                    Script not found
                </h3>
                <p className="font-light text-gray-500 text-md dark:text-gray-400">
                    It's ok, sometimes words are not needed
                </p>
                <ButtonTranscribeClip clip={clip}/>
            </div>
        </div>
    </div>;

};
export default CaptionsTimeline
