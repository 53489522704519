import React, {useEffect, useRef, useState} from "react";
import {fabric} from "fabric";
import {sleep} from "front-lib";

let frameNumber = 0;
export const useFabric = () => {

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const fabricCanvasRef = useRef<fabric.Canvas | null>(null);
    const isDrawing = useRef<boolean>(false);
    const frames = useRef<string[]>([]);
    // const [framesCount, setFramesCount] = useState(0);
    // const requestRef = React.useRef<any>();
    const debugInfoObjectRef = React.useRef<fabric.Textbox | null>(null);


    const initFabric = async (clip: any) => {
        console.log("initFabric");
        const {width, height} = clip;
        canvasRef.current = canvasRef.current || document.createElement('canvas');
        const canvas = canvasRef.current;

        // const devElement = document.getElementById("dev-stages");
        // if (devElement) {
        //     devElement.innerHTML = '';
        //     devElement.append(canvas)
        // }

        fabricCanvasRef.current = new fabric.Canvas(canvas, {
            width,
            height,
            // backgroundColor : '#b0f397',
            backgroundColor: "#fff" //no background make the font looks strange after ffmpeg burn
            // backgroundColor: "purple"
        });
        addDebugInfo();

    }

    const addDebugInfo = () => {
        const canvas = canvasRef.current;
        if (!canvas || !fabricCanvasRef.current) return;
        debugInfoObjectRef.current = new fabric.Textbox(`Frame: ${frameNumber}\nTime: ${formatTime(0)}\nSeconds: ${0}`, {
            left: 10,
            top: 10,
            width: 410,
            fontSize: 36,
            fontFamily: "monospace",
            fill: 'yellow',
            textAlign: 'left',
            selectable: true,
        });
        fabricCanvasRef.current.add(debugInfoObjectRef.current);

    }

    const addShape = async (loadShape: any) => {
        if (!fabricCanvasRef.current) return;
        const shape = await loadShape()
        // console.log("addShape", shape)
        fabricCanvasRef.current.add(shape);
        shape.onPreRender && shape.onPreRender()
        // shape.sendToBack()
    }

    const preRender = async () => {
        if (!fabricCanvasRef.current) return;
        const fabricCanvas = fabricCanvasRef.current;
        //preRender
        await Promise.all(fabricCanvas.getObjects().concat().map(async function (obj: any) {
            obj.onPreRender && await obj.onPreRender()
        }));
    }
    const processVideo = async (onProgress?: any) => {
        console.log("processVideo");
        frameNumber = 0;
        frames.current = [];
        // startingTime = new Date().getTime();
        isDrawing.current = true;
        await preRender();
        await render();
        await onTick(onProgress);
        fabricCanvasRef.current?.dispose()
        return frames.current
    }

    const onTick = async (onProgress?: any) => {
        // console.log("onTick", frameNumber)
        if (!isDrawing.current || !fabricCanvasRef.current) return;
        frameNumber++;
        await render();

        await onProgress && onProgress(frameNumber)
        // await sleep(50)
        // requestRef.current = fabric.util.requestAnimFrame(onTick);
        await onTick(onProgress);
    }
    const render = async () => {
        const seconds = (frameNumber * (1000 / 29.97)) / 1000;
        const currentMs = parseInt((seconds * 1000).toString());
        // console.log("render", seconds, frameNumber)
        if (!fabricCanvasRef.current) return;
        const fabricCanvas = fabricCanvasRef.current;

        debugInfoObjectRef.current?.set({text: `Frame: ${frameNumber}\nTime: ${formatTime(seconds)}\nSeconds: ${seconds.toFixed(2)}`});
        debugInfoObjectRef.current?.bringToFront();


        await Promise.all(fabricCanvas.getObjects().concat().map(async function (obj: any) {
            obj.onRender && await obj.onRender({frameNumber, seconds, currentMs})
        }));
        // await sleep(1000 / 30);
        // console.log("before render all")
        fabricCanvas.renderAll();

        const frame = fabricCanvas.toDataURL({
            format: 'png',
            quality: 1
        });
        frames.current.push(frame);
        const event = new CustomEvent('render', {detail: {seconds, frameNumber}});
        canvasRef.current?.dispatchEvent(event);
    }

    const stopRender = () => {
        isDrawing.current = false;
    }

    return {
        canvasRef,
        initFabric,
        processVideo,
        stopRender,
        render,
        addShape,
    }
}


export function formatTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
}
