import React, {useState} from "react"

const Accordion = ({defaultItems}: any) => {
    // const [activeItem, setActiveItem] = useState<any>(0);
    const [items, setItems] = useState<any[]>(defaultItems);

    return (
        <>
            <div>
                {items.map((item: any, index: number) => {
                    const isFirst = index === 0;
                    const isLast = index === items.length - 1;
                    // const isOpen = index === activeItem;
                    return <div key={`acc_item_${item.id || index}`}>
                        <h2 onClick={() => {
                            const _items = [...items]
                            _items[index].isOpen = !_items[index].isOpen;
                            setItems(_items);
                        }}>
                            <button type="button"
                                    className={`${isFirst && 'rounded-t-xl'}  ${(!isLast || item.isOpen) &&  'border-b-0'} flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3`}
                                    aria-expanded="true">
                                {item.label}

                                <svg className={`w-3 h-3 shrink-0 ${item.isOpen ? '' : 'rotate-180'}`}
                                     aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M9 5 5 1 1 5"/>
                                </svg>
                            </button>
                        </h2>
                        <div className={item.isOpen ? '' : 'hidden'}>
                            <div
                                className={`${!isLast && 'border-b-0'} p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900`}>
                                {item.content}
                            </div>
                        </div>

                    </div>
                })}
            </div>

        </>
    )
}

export {Accordion}