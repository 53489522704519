import React, {useEffect, useState} from "react";
import {getSubtitleAccordingToCurrentTime} from "../../../engine/useCanvasEffects";

const VoicePlayer = ({clip, videoRef}: any) => {

    const requestRef = React.useRef<any>();
    const [voiceUrl, setVoiceUrl] = useState<any>("");

    const animate = () => {
        // console.log("VoicePlayer - animate", videoRef.current.currentTime.toFixed(1));
        if (!videoRef?.current) return
        const subtitle = getSubtitleAccordingToCurrentTime(clip, videoRef.current.currentTime);
        setVoiceUrl(subtitle ? subtitle.voiceUrl : "");
        requestRef.current = requestAnimationFrame(animate)
    }
    useEffect(() => {
        if (!clip.captions) return;
        console.log("VoicePlayer start animation frame")
        animate();

        return () => {
            cancelAnimationFrame(requestRef.current)
        }
    }, [clip]);

    useEffect(() => {
        if (!voiceUrl || videoRef.current.paused || !clip.dubbing) return;
        console.log("playing audio", voiceUrl)
        const audio = new Audio(voiceUrl);
        audio.play();
    }, [voiceUrl]);
    return (
        <>
            {/*VoicePlayer - {videoCurrentTime}*/}
        </>
    )
}

export default VoicePlayer
