import React from "react";
import {FormContextProvider} from "./FormContextProvider";

const withNestedForm = (Component: any) => {

    function WithNestedForm({...rest}) {

        return (
            <FormContextProvider>
                <Component {...rest} />
            </FormContextProvider>
        );
    }

    return WithNestedForm;
};

export {withNestedForm};
