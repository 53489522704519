import React from "react";
import {FormInputText} from "front-lib";
import {ButtonDeleteClip} from "../buttons/ButtonDeleteClip";


interface IProps {
    clip: any;
    onSubmit?: (formData?: any) => Promise<void>;
    onCancel?: () => void
}

const ClipSettingsForm = ({clip, onSubmit, onCancel}: IProps) => {

    return (
        <>
            <div className="grid grid-cols-6 gap-3">
                <div className="col-span-6  sm:col-span-4">
                    <FormInputText label={"Resolution"} name={"resolution"} disabled={true}
                                   defaultValue={`${clip.width}x${clip.height}`}/>
                </div>
                <div className="col-span-6  sm:col-span-4">
                    <FormInputText label={"FPS"} name={"fps"} disabled={true}
                                   defaultValue={clip.fps}/>
                </div>
                <div className="col-span-6">
                    <ButtonDeleteClip clip={clip}/>

                </div>
            </div>


        </>);

};


export default ClipSettingsForm
