import React, {useEffect, useRef, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useParams} from "react-router-dom";
import {api} from "front-lib"


function PublicClipPlayerRoute() {
    const {clipId} = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [clip, setClip] = useState<any | null>(null);

    const loadClip = async () => {
        try {
            setIsLoading(true);
            console.log("loadClip", clipId)
            const response = await api.get(`/clips/${clipId}`)
            console.log("response", response)
            setClip(response.data)
        } catch (err) {

        } finally {
            setIsLoading(false)
        }

    }
    useEffect(() => {
        loadClip();
    }, [clipId]);
    return (
        <>


            <div className={' px-6 py-2.5 max-w-screen-xl mx-auto'}>
                {clip && <>
                    <Helmet>
                        <title>Clipz </title>
                    </Helmet>
                    <h1 className={'text-3xl dark:text-white px-0'}>{clip.name}</h1>
                    <p className={'pb-2 dark:text-gray-500'}>{clip.description}</p>


                    <video src={clip.outputUrl} controls={true}/>
                </>}

                <pre>
                  {JSON.stringify(clip, null, 2)}
                </pre>
            </div>

        </>
    );
}

export default PublicClipPlayerRoute;
