import React, {useState} from "react";
import {ClickAwayListener} from "front-lib";

const Popover = ({defaultOpen = false, renderTrigger, renderContent, className, onChange}: any) => {
    const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
    const handleClickAway = () => {
        // console.log("handleClickAway")
        setIsOpen(false)
        onChange && onChange(false, "away")
    };

    const toggleOpen = () => {
        const newState = !isOpen;
        setIsOpen(newState);
        onChange && onChange(newState, "toggle")

    }
    const renderTriggerWithOnClick = () => {
        const trigger = typeof (renderTrigger) === 'function' ? renderTrigger({isOpen, toggleOpen}) : renderTrigger;
        if (React.isValidElement(trigger)) {
            return React.cloneElement<any>(trigger, {onClick: toggleOpen});
        }
        return renderTrigger
    }

    const renderContentAsFunctionOrComponent = () => {
        const content = typeof (renderContent) === 'function' ? renderContent({isOpen, toggleOpen}) : renderContent;
        return content;
    }

    return (
        <ClickAwayListener onClickAway={isOpen ? handleClickAway : undefined}>
            <div className={'inline'}>
                {renderTriggerWithOnClick()}
                <div
                    className={`${isOpen ? "opacity-100" : "opacity-0 pointer-events-none"} ${className} transition-opacity duration-150 `}>
                    {renderContentAsFunctionOrComponent()}
                </div>
            </div>
        </ClickAwayListener>
    )
}

export {Popover}