import React from "react"
import {TabsSocials} from "./tabs/TabsSocials";

const SocialsView = () => {
    return (
        <>
            <TabsSocials/>
        </>
    )
}

export {SocialsView}