import SrtParser2 from "srt-parser-2";
import {useCanvasEffects} from "../../engine/useCanvasEffects";
import {
    createEmptyAudioFile,
    getPartFromAudio,
    mergeAudioFiles,
    muteVideoAndMergeWithAudio
} from "tpz-lib";
import {calcActiveCaptionsDuration} from "../forms/CaptionsTimeline";
import {useMuxer} from "../../engine/useMuxer";


const srtParser = new SrtParser2();
export const useExport = () => {
    const {getFrames, canvasRef} = useCanvasEffects();
    const {bundleFrames} = useMuxer();


    const getVideoAssetFromClip = async (clip: any, captions: any[], onProgress?: any) => {
        let frames: string[] = []

        if (!clip.skipCuts || !clip.captions) {
            //get all frames
            frames = await getFrames(clip, 0, clip.duration, onProgress);
        } else {
            //get frames for captions
            for (let i = 0; i < captions.length; i++) {
                const c = captions[i];
                const partialFrames = await getFrames(clip, c.startTime, c.endTime, onProgress);
                frames = frames.concat(partialFrames);
            }
        }

        const videoBlob = await bundleFrames(frames, clip.width, clip.height, onProgress);
        return window.URL.createObjectURL(videoBlob);
    }
    const getAudioAssetFromClip = async (clip: any, captions: any[]) => {
        //merge with audio for captions
        const croppedDuration = calcActiveCaptionsDuration(clip);
        let audioBlob = await createEmptyAudioFile(croppedDuration);
        for (let i = 0; i < captions.length; i++) {
            const c = captions[i]
            const url = URL.createObjectURL(audioBlob);
            const partialAudioUrl = await (async () => {
                if (clip.dubbing && c.voiceUrl) {
                    return c.voiceUrl;
                } else {
                    const partialOrgAudio = await getPartFromAudio(clip.orgAudioUrl, c.startTime, c.endTime)
                    return URL.createObjectURL(partialOrgAudio)
                }
            })();
            audioBlob = await mergeAudioFiles(url, partialAudioUrl, c.startTime)
        }

        return window.URL.createObjectURL(audioBlob);
    }
    const exportAsAudio = async (clip: any): Promise<Blob> => {
        console.log("exportAsAudio", clip.orgAudioUrl)
        const blob = await fetch(clip.orgAudioUrl).then(r => r.blob());
        const blobUrl = URL.createObjectURL(blob);
        downloadUrl(blobUrl, "audio.mp3");
        return blob;
    }

    const exportVideoWithCanvasEffects = async (clip: any, onProgress?: any): Promise<Blob> => {
        console.log("exportVideoWithCanvasEffects")

        const captions = clip.captions.filter((c: any) => c.shouldTake);
        const videoUrl = await getVideoAssetFromClip(clip, captions, onProgress);
        const audioUrl = await getAudioAssetFromClip(clip, captions);
        const outputBlob = await muteVideoAndMergeWithAudio(videoUrl, audioUrl);

        downloadUrl(window.URL.createObjectURL(outputBlob), "demo.mp4");
        return outputBlob;

    }

    const exportAsSubtitles = async (clip: any): Promise<Blob> => {

        const arr = [
            {
                id: '1',
                startTime: '00:00:11,544',
                startSeconds: 11.544,
                endTime: '00:00:12,682',
                endSeconds: 12.682,
                text: 'Hello'
            },
            {
                id: '2',
                startTime: '00:00:15,544',
                startSeconds: 15.544,
                endTime: '00:00:17,682',
                endSeconds: 17.682,
                text: 'World'
            }
        ];
        const srt = srtParser.toSrt(arr);
        console.log("exportAsSubtitles", srt)
        const blob = new Blob([srt], {
            type: 'text/plain'
        });
        const blobUrl = URL.createObjectURL(blob);
        downloadUrl(blobUrl, "video.srt");
        return blob;
    }
    return {
        exportAsAudio,
        exportVideoWithCanvasEffects,
        exportAsSubtitles,
        canvasRef
    }

}


export const downloadUrl = (url: string, filename: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.target = "_blank";
    a.download = filename;
    a.textContent = 'Download';
    a.click();
    // document.body.appendChild(a);
}