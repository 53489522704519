import React, {useEffect} from "react";
import FormElevenVoices from "./FormElevenVoices";
import {FormGlobalErrorMessage, FormGlobalSuccessMessage, useForm, useTheme, withForm} from "front-lib";

interface IProps {
    onSubmit?: (formData?: any) => Promise<void>;
    clip: any
}

const ClipVoiceOverForm = ({clip, onSubmit}: IProps) => {

    const {handleSubmit, watch} = useForm();

    const {theme} = useTheme();
    // const onSubmit = async ({dirtyValues}: any) => {
    //     console.log("dirtyValues", dirtyValues)
    //     // await updateMyProfileAndContextState(dirtyValues);
    //     return "Profile changes saved successfully"
    // }
    return (
        <>

            <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-4">
                    <FormElevenVoices defaultValue={clip.voiceId}/>
                </div>
            </div>
            {/*<div className="bg-gray-50 dark:bg-gray-900 px-4 py-3 text-right sm:px-6 flex justify-between">*/}

            {/*    <button type="submit"*/}
            {/*            onClick={() => {*/}
            {/*                console.log("before on submit")*/}
            {/*                //make sure all the form is valid*/}
            {/*                handleSubmit(onSubmit)*/}

            {/*                // console.log("after on submit")*/}
            {/*            }}*/}
            {/*            className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">Save*/}
            {/*    </button>*/}
            {/*</div>*/}
        </>
    )
}

export default (ClipVoiceOverForm)
