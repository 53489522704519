import React from "react";
import {
    AlertInfo,
    FormGlobalErrorMessage,
    FormGlobalSuccessMessage,
    FormInputText, FormSwitch,
    FormTextarea,
    useForm,
    withForm
} from "front-lib";
import {calcActiveCaptionsDuration} from "./CaptionsTimeline";


interface IProps {
    clip: any;
    onSubmit?: (formData?: any) => Promise<void>;
    onCancel?: () => void
}

const ClipDetailsForm = ({clip, onSubmit, onCancel}: IProps) => {
    const {watch} = useForm();
    const skipCuts = watch("skipCuts", clip.skipCuts);
    const durationDiff = calcActiveCaptionsDuration(clip);
    const withScript = Boolean(clip.captions && clip.captions.length)

    return (
        <>
            {withScript && <div className="space-y-6 bg-white dark:bg-gray-900 px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                        <FormSwitch name={'skipCuts'} label={"Skip Cuts"} defaultValue={clip.skipCuts}/>
                        {skipCuts && durationDiff > 0 && <AlertInfo className={'mt-1'} showClose={false}>
                            Cropped video length: <span
                            className={'font-bold'}> {durationDiff} seconds</span>
                        </AlertInfo>}
                    </div>
                    <div className="col-span-6 sm:col-span-4">
                        <FormSwitch name={'withSubs'} label={"Captions"} defaultValue={clip.withSubs}/>
                        {/*    <FormTextarea label={"Description"} name={"description"} defaultValue={clip.description || ''}/>*/}
                    </div>
                    <div className="col-span-6 sm:col-span-4">
                        <FormSwitch name={'dubbing'} label={"Dubbing"} defaultValue={clip.dubbing}/>
                        {/*    <FormTextarea label={"Description"} name={"description"} defaultValue={clip.description || ''}/>*/}
                    </div>
                </div>
            </div>}

            {/*<div className="bg-gray-50 dark:bg-gray-900 px-4 py-3   sm:px-6">*/}

            {/*<ButtonFormSubmit/>*/}
            {/*<button type="submit"*/}
            {/*        onClick={() => {*/}
            {/*            console.log("before on submit")*/}
            {/*            //make sure all the form is valid*/}
            {/*            handleSubmit(onSubmit)*/}

            {/*            // console.log("after on submit")*/}
            {/*        }}*/}
            {/*        className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">*/}
            {/*    Save*/}
            {/*</button>*/}
            {/*</div>*/}

        </>);

};


export default ClipDetailsForm
