import React, {useEffect} from "react";
import {TabsClipEditor} from "./tabs/TabsClipEditor";
import ClipDetailsForm from "./forms/ClipDetailsForm";
import ClipPlayer from "./player/clip-player/ClipPlayer";


export const ClipEditor = ({clip}: any) => {
    return (
        <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 bg-white dark:bg-gray-950 md:col-span-7 order-2">
                <TabsClipEditor clip={clip}/>
            </div>
            <div className="col-span-12 md:col-span-5 order-1 md:order-2 bg-white dark:bg-gray-950">
                <ClipPlayer clip={clip}/>
                <ClipDetailsForm clip={clip}/>
            </div>
        </div>
    );
}
