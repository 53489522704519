import React from "react"
import {useForm} from "front-lib";
import {useAi} from "../../engine/useAi";

const ButtonDubClip = ({clip}: any) => {
    const {handleSubmit, setValue} = useForm();

    const {dubClip} = useAi();

    return (
        <>
            <button type="button"
                    onClick={async () => {
                        const clipDubbingModel = await dubClip(clip.id)
                        console.log("clipDubbingModel", clipDubbingModel)
                        const captions = clipDubbingModel.captions;
                        setValue("captions", captions, {shouldDirty: true})
                    }}
                    className={`px-3 py-2 text-xs font-medium text-center text-white bg-primary-200 rounded-lg hover:bg-primary-300  dark:bg-primary-600 dark:hover:bg-primary-700 `}>
                Generate AI Dubbing
            </button>
        </>
    )
}

export {ButtonDubClip}