import {ButtonFormSubmit, DropzoneVideoUploader} from "front-lib";
import React from "react";
import {Link} from "react-router-dom";

const StepUploadOrRecord = ({onSubmit}: any) => {
    return <>
        <DropzoneVideoUploader onFilesChanged={async (files: any[]) => {
            const file = files[0];
            console.log("file", file)
            onSubmit && onSubmit(file);
            // const fileEntity = await upload(`/upload/video`, file)
            // console.log("fileEntity", fileEntity)
        }}>
            <Link to={"/spotlight"}>
                <button
                    className="ml-1 mt-3 cursor-pointer rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    Open Cemera
                </button>
            </Link>
        </DropzoneVideoUploader>
        {/*<ButtonFormSubmit text={"Skip"} onSubmit={onSubmit}/>*/}
    </>
}
export {StepUploadOrRecord}