import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './style/index.scss'
import {initRecaptcha , initAnalytics} from "front-lib";

initAnalytics();
// Sentry.init({
//     dsn: "https://f33bdde59c9e43109dc753708c7457f7@o4504589362528256.ingest.sentry.io/4504589363576832",
//     integrations: [new BrowserTracing()],
//     environment: import.meta.env.MODE,
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });
initRecaptcha();
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    // <React.StrictMode>
    <App/>
    // </React.StrictMode>
)


if (import.meta.env.MODE === "development") {
    localStorage.setItem("inline_devtools", JSON.stringify(true))
} else {
    // window.oncontextmenu = function () {
    //     return false;
    // };
    // document.addEventListener("keydown", function (event) {
    //     if (!event || !event.code) return;
    //     const key = event.code.toLowerCase()
    //     if (key == "f12") {
    //         event.preventDefault();
    //         return false;
    //     } else if ((event.ctrlKey && event.shiftKey && key == "keyi") || (event.ctrlKey && event.shiftKey && key == "keyj")) {
    //         event.preventDefault();
    //         return false;
    //     }
    // }, false);
}

const bugs = `Want to help us crush bugs?     |     |
Send us a message               \\\\_V_//
hello@clipz.studio               \\/=|=\\/
                                 [=v=]
                               __\\___/_____
                              /..[  _____  ]
                             /_  [ [  M /] ]
                            /../.[ [ M /@] ]
                           <-->[_[ [M /@/] ]
                          /../ [.[ [ /@/ ] ]
     _________________]\\ /__/  [_[ [/@/ C] ]
    <_________________>>0---]  [=\\ \\@/ C / /
       ___      ___   ]/000o   /__\\ \\ C / /
          \\    /              /....\\ \\_/ /
       ....\\||/....           [___/=\\___/
      .    .  .    .          [...] [...]
     .      ..      .         [___/ \\___]
     .    0 .. 0    .         <---> <--->
  /\\/\\.    .  .    ./\\/\\      [..]   [..]
 / / / .../|  |\\... \\ \\ \\    _[__]   [__]_
/ / /       \\/       \\ \\ \\  [____>   <____]
`
console.log(bugs);

