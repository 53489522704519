import {useEffect, useState} from "react";
import {useSocket, useSocketEvent} from "./useSocket";
import {sleep, api} from "front-lib";

export const useSocketAdmin = () => {
    // console.log("useSocketAdmin");
    const {socket, isConnected} = useSocket();
    const [clients, setClients] = useState<any[]>([]);


    const getClients = async () => {
        const response = await api.get("socket/clients")
        setClients(response.data);
    }

    useEffect(() => {
        (async () => {
            await sleep(500)
            await getClients();
        })()
    }, []);

    useSocketEvent("user-count-changed", async (args: any) => {
        console.log("user-count-changed ", args);
        await sleep(500)
        await getClients();

    }, ["aa"])

    return {
        clients,
        getClients
    }
}