import React, {useEffect} from "react";
import {BsRecordFill, MdOutlineCameraswitch} from "react-icons/all";
import RecordingClock from "./timer/RecordingClock";
import WizardBackButton from "./buttons/WizardBackButton";
import useMediaRecorder from "./media/useMediaRecorder";
import {IRecorder} from "./media/recorder.interfaces";
import {AlertError, LoadingStripes} from "front-lib";

const CameraRecorder = ({onCompleted, onBack}: IRecorder) => {

    const {
        openDevice,
        closeDevice,
        switchMediaDevice,
        startRecording,
        stopRecording,
        outputUrl,
        inputRecordingRef,
        blob,
        isRecording,
        isCompletedRecording,
        resetToInitialState,
        error,
        isOpeningCamera
    } = useMediaRecorder({
        constraints: {
            video: {
                facingMode: "user",
            },
            audio: true
        }
    })


    const stopRecordingAndCompleteFlow = async () => {
        const blob = await stopRecording("video/webm");
        onCompleted(blob);
    }
    useEffect(() => {
        openDevice();
        return () => {
            closeDevice();
        }
    }, []);

    const renderError = () => {
        return (
            <>
                {/*<ErrorBrokenHeart/>*/}
                heart error
                <div className={'mb-4'}>
                    <AlertError showClose={false}>
                        {error.message}
                    </AlertError>
                </div>
            </>

        )
    }

    const renderIntro = () => {
        return <>
            <div className={'absolute m-4 z-10 p-4 flex items-center flex-col text-white bg-camera-overlay'}>
                <p>
                    When you're ready, press the button below. You can start over if you make a mistake.
                </p>

                {/*<LabelMaxDuration mrd={mrd}/>*/}
                <div>some text goes here</div>
                {/*<AlertInfo className={'mt-4'}>Max. length: 10 seconds</AlertInfo>*/}

                <button
                    onClick={startRecording}
                    className="flex  items-center text-white text-md bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg px-5 py-2.5 mr-2 mb-2 mt-4 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none ">
                    <BsRecordFill className={'w-7 h-7 mr-1'}/>
                    Start Recording
                </button>
            </div>
            {!isOpeningCamera && !isRecording &&
                <div className={'absolute z-10 bottom-[20px] right-[20px] bg-camera-overlay p-3 '}
                     onClick={() => {
                         switchMediaDevice();
                     }}>

                    <MdOutlineCameraswitch size={40} className={'text-white cursor-pointer'}/>
                </div>}
        </>
    }


    const getBackText = () => {
        if (isCompletedRecording) return "Try again"
        if (isRecording) return "Cancel"
        if (onBack) return "Back"
    }

    console.log("outputUrl", outputUrl)

    if (error) return renderError();

    return (
        <>
            {!isOpeningCamera && (isCompletedRecording || isRecording || onBack) && <WizardBackButton text={getBackText()} onClick={() => {
                if (isCompletedRecording || isRecording) {
                    resetToInitialState();
                } else {
                    onBack && onBack()
                }
            }}/>}
            <div className={'flex items-center flex-col'}>
                {isOpeningCamera && <LoadingStripes/>}
                <div className={'relative'}>
                    <div>
                        {!isOpeningCamera && !isRecording && !isCompletedRecording && renderIntro()}
                        {isRecording && <div className={'bg-camera-overlay absolute top-[10px] right-[10px] p-4 z-50'}>
                            <RecordingClock timeLimit={30} onTimeOver={stopRecordingAndCompleteFlow}/>
                            <div className={' w-full flex items-center justify-end z-50 '}>
                                <button type="button"
                                        onClick={stopRecordingAndCompleteFlow}
                                        className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-2 py-1 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none ">
                                    Complete
                                </button>
                            </div>
                        </div>}
                        <video className={`w-full ${isOpeningCamera || isCompletedRecording ? 'hidden' : ''}`}
                               ref={inputRecordingRef} autoPlay muted/>
                    </div>
                </div>

                {/*<pre className={'text-warning-500 mt-20'}>{*/}
                {/*    JSON.stringify({*/}
                {/*        blob,*/}
                {/*        isRecording,*/}
                {/*        // isCameraStreaming,*/}
                {/*        isCompletedRecording,*/}
                {/*        error,*/}
                {/*        isOpeningCamera*/}
                {/*    }, null, 2)*/}
                {/*}*/}
                {/*</pre>*/}

            </div>
        </>

    )
}

export default CameraRecorder
