import {FFmpeg} from '@ffmpeg/ffmpeg';
import {fetchFile, toBlobURL} from "@ffmpeg/util";

const ffmpeg = new FFmpeg();
// const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd'
const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.2/dist/esm"

const loadFfmpeg = async () => {
    console.log("loadFfmpeg start")
    // await sleep(1000)
    await ffmpeg.load({
        coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
        wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    });
    console.log("loadFfmpeg end")
}


export const promisedToLoadFfmpeg = loadFfmpeg();

ffmpeg.on("log", ({message}: any) => {
    console.log(message);
})

export async function extractAudioFromVideo(url: string): Promise<Blob> {
    console.log("extractAudioFromVideo start", url)
    // await promisedToLoad;
    // Fetch the video file
    const videoArray = await getUrlAsArray(url)

    // Write the video file to the FFmpeg file system
    await ffmpeg.writeFile('input.mp4', videoArray);

    // Run the FFmpeg command to extract the audio
    await ffmpeg.exec(['-i', 'input.mp4', '-async', '1', 'output.mp3']);

    // Read the extracted audio file from the file system
    const data = await ffmpeg.readFile('output.mp3');
    await ffmpeg.deleteFile('input.mp4');
    await ffmpeg.deleteFile('output.mp3');
    const blob = new Blob([data], {type: 'audio/mp3'})
    // Return the audio data as Uint8Array
    return blob;

}

// const videoArray = await getUrlAsArray(url)
//
// // Write the video file to the FFmpeg file system
// await ffmpeg.writeFile('input-before-muted.mp4', videoArray);
//
// // Run the FFmpeg command to extract the audio
// await ffmpeg.exec(['-i', 'input-before-muted.mp4', '-c', 'copy', '-an', 'output-muted.mp4']);
//
// // Read the extracted audio file from the file system
// const data = await ffmpeg.readFile('output-muted.mp4');
// // await ffmpeg.deleteFile('output-muted.mp4');
// // await ffmpeg.deleteFile('input-before-muted.mp4');
// const blob = new Blob([data], {type: 'video/mp4'})
// // Return the audio data as Uint8Array
// return blob;
export async function getMutedVideo(url: string): Promise<Blob> {
    console.log("getMutedVideo start", url)


    // Fetch the video file
    const videoArray = await getUrlAsArray(url)

    // Write the video file to the FFmpeg file system
    await ffmpeg.writeFile('input.mp4', videoArray);

    // Run the FFmpeg command to extract the audio
    await ffmpeg.exec(['-i', 'input.mp4', '-c', 'copy', '-an', 'output.mp4']);

    // Read the extracted audio file from the file system
    const data = await ffmpeg.readFile('output.mp4');

    const blob = new Blob([data], {type: 'video/mp4'})
    // Return the audio data as Uint8Array
    return blob;
}

export async function convertWebToMp4(url: string): Promise<Blob> {
    console.log("convertToMp4 start2", url)


    // Fetch the video file
    const videoArray = await getUrlAsArray(url)

    // Write the video file to the FFmpeg file system
    await ffmpeg.writeFile('input.webm', videoArray);

    // Run the FFmpeg command to extract the audio
    await ffmpeg.exec(['-i', 'input.webm', '-c', 'copy', '-r', '30', 'output.mp4']);

    // Read the extracted audio file from the file system
    const data = await ffmpeg.readFile('output.mp4');

    const blob = new Blob([data], {type: 'video/mp4'})
    // Return the audio data as Uint8Array
    return blob;
}

export async function convertWebToMp3(url: string): Promise<Blob> {
    console.log("convertWebToMp3 start", url)


    // Fetch the video file
    const videoArray = await getUrlAsArray(url)

    // Write the video file to the FFmpeg file system
    await ffmpeg.writeFile('input.webm', videoArray);

    // Run the FFmpeg command to extract the audio
    await ffmpeg.exec(['-i', "input.webm", '-q:a', '0', '-map', 'a', "output.mp3"]);

    // Read the extracted audio file from the file system
    const data = await ffmpeg.readFile('output.mp3');

    const blob = new Blob([data], {type: 'audio/mp3'})
    // Return the audio data as Uint8Array
    return blob;
}

export async function mergeTwoVideos(urlOne: string, urlTwo: string): Promise<Blob> {
    console.log("mergeTwoVideos start", urlOne, urlTwo)


    // Fetch the video file
    const videoArrayOne = await getUrlAsArray(urlOne)
    const videoArrayTwo = await getUrlAsArray(urlTwo)


    // Write the video file to the FFmpeg file system
    await ffmpeg.writeFile('input1.mp4', videoArrayOne);
    await ffmpeg.writeFile('input2.mp4', videoArrayTwo);

    // Run the FFmpeg command to extract the audio
    // await ffmpeg.exec(['-i', 'input1.mp4', '-i', 'input2.mp4', '-filter_complex', '[0:v][1:v]overlay=W-w-10:H-h-10', 'output.mp4']);
    // await ffmpeg.exec(['-i', 'input1.mp4', '-i', 'input2.mp4', '-filter_complex', '[1:v]format=argb,geq=r='st(3,n)*p(X,Y/T+W/T*H/h):a=alpha*st(3,n)*p(X,Y/T+W/T*H/h)[ovr];[0:v][ovr]overlay=W-w-10:H-h-10', 'output.mp4']);
    // await ffmpeg.exec(['-i', 'input1.mp4', '-i', 'input2.mp4', '-filter_complex', '[1:v]format=argb,colorchannelmixer=aa=0.5[ovr];[0:v][ovr]overlay=W-w-10:H-h-10', 'output.mp4']);
    // await ffmpeg.exec(['-i', 'input1.mp4', '-i', 'input2.mp4', '-filter_complex', '[1:v]colorkey=0x000000:0.1:0.2[ckout];[0:v][ckout]overlay=W-w-0:H-h-0:format=auto', 'output.mp4']);

    // const colorToIgnore = "b0f397";
    const colorToIgnore = "000000";
    await ffmpeg.exec(['-i', 'input1.mp4', '-i', 'input2.mp4', '-filter_complex', `[1:v]colorkey=0x${colorToIgnore}:0.1:0.2[ckout];[0:v][ckout]overlay=W-w-0:H-h-0:format=auto`, '-c:v', 'libx264', '-profile:v', 'baseline', '-level', '3.0', '-pix_fmt', 'yuv420p', 'output.mp4']);


    // Read the extracted audio file from the file system
    const data = await ffmpeg.readFile('output.mp4');

    const blob = new Blob([data], {type: 'video/mp4'})
    // Return the audio data as Uint8Array
    return blob;
}

export async function concatTwoVideos(urlOne: string, urlTwo: string): Promise<Blob> {
    console.log("concatTwoVideos start", urlOne, urlTwo)


    // Fetch the video file
    const videoArrayOne = await getUrlAsArray(urlOne)
    const videoArrayTwo = await getUrlAsArray(urlTwo)


    // Write the video file to the FFmpeg file system
    await ffmpeg.writeFile('input1.mp4', videoArrayOne);
    await ffmpeg.writeFile('input2.mp4', videoArrayTwo);

    await ffmpeg.exec([
            '-i', 'input1.mp4',
            '-i', 'input2.mp4',
            '-filter_complex', `[0:v:0][0:a:0][1:v:0][1:a:0]concat=n=2:v=1:a=1[outv][outa]`, // Concatenate video and audio streams
            '-map', '[outv]',
            '-map', '[outa]',
            '-c:v', 'libx264', // Set video codec to libx264
            '-c:a', 'aac',     // Set audio codec to AAC
            '-strict', 'experimental', // Necessary for the aac codec
            'output.mp4'
        ]
    );


    // Read the extracted audio file from the file system
    const data = await ffmpeg.readFile('output.mp4');

    const blob = new Blob([data], {type: 'video/mp4'})
    // Return the audio data as Uint8Array
    return blob;
}

export const getPartFromAudio = async (url: string, startTime: number, endTime: number): Promise<Blob> => {
    console.log("getPartFromAudio", url, startTime, endTime)
    const videoArray = await getUrlAsArray(url);

    // Write the video file to the FFmpeg file system
    await ffmpeg.writeFile('input.mp4', videoArray);

    // Extract audio only from the video
    await ffmpeg.exec(
        [
            '-i', 'input.mp4',
            '-ss', startTime.toString(),
            '-to', endTime.toString(),
            '-vn',                   // Extract audio only
            '-c:a', 'libmp3lame',  // Encode audio to MP3 using LAME MP3 encoder
            'output.mp3'            // Output as mp3 file
        ]
    );

    const data = await ffmpeg.readFile('output.mp3');

    const blob = new Blob([data], {type: 'audio/mpeg'});
    // ffmpeg.unlink('output.mp3');
    return blob;
}

export const getPartFromVideo = async (url: string, startTime: number, endTime: number): Promise<Blob> => {
    const videoArray = await getUrlAsArray(url)

    // Write the video file to the FFmpeg file system
    await ffmpeg.writeFile('input.mp4', videoArray);

    await ffmpeg.exec(
        [
            '-i', 'input.mp4',
            '-ss', startTime.toString(),
            '-to', endTime.toString(),
            '-c:v', 'libx264', // Explicitly set video codec to libx264
            // '-c:a', 'aac',     // AAC audio codec
            // '-strict', 'experimental', // Necessary for the aac codec
            'output.mp4'
        ]
    )
    const data = await ffmpeg.readFile('output.mp4');

    const blob = new Blob([data], {type: 'video/mp4'})
    // ffmpeg.unlink('output.mp4');
    return blob;
}

export async function mergeVideoWithAudio(urlOne: string, urlTwo: string, startTime = 0): Promise<Blob> {
    console.log("mergeVideoAndAudio start", urlOne, urlTwo)

    const st = Math.max(0, startTime - 1)

    // Fetch the video file
    const videoArrayOne = await getUrlAsArray(urlOne)
    const videoArrayTwo = await getUrlAsArray(urlTwo)


    // Write the video file to the FFmpeg file system
    await ffmpeg.writeFile('input1.mp4', videoArrayOne);
    await ffmpeg.writeFile('input2.mp3', videoArrayTwo);
    // const startTime = 2;
    // keep video audio
    await ffmpeg.exec([
        '-i',
        'input1.mp4',
        '-i',
        'input2.mp3',
        '-filter_complex',
        `[1:a]adelay=${st * 1000}|${st * 1000}[delayed];[0:a][delayed]amix=inputs=2:duration=longest[a]`,
        '-map',
        '0:v',
        '-map',
        '[a]',
        '-c:v',
        'copy',
        'output.mp4'
    ]);


    // Read the extracted audio file from the file system
    const data = await ffmpeg.readFile('output.mp4');

    const blob = new Blob([data], {type: 'video/mp4'})
    // Return the audio data as Uint8Array
    return blob;
}

export async function muteVideoAndMergeWithAudio(urlOne: string, urlTwo: string, startTime = 0): Promise<Blob> {
    console.log("mergeVideoAndAudio start", urlOne, urlTwo)


    // Fetch the video file
    const videoArrayOne = await getUrlAsArray(urlOne)
    const videoArrayTwo = await getUrlAsArray(urlTwo)


    // Write the video file to the FFmpeg file system
    await ffmpeg.writeFile('input1.mp4', videoArrayOne);
    await ffmpeg.writeFile('input2.mp3', videoArrayTwo);

    // don't keep video audio
    await ffmpeg.exec(['-i',
        'input1.mp4',
        '-itsoffset',
        `${startTime}`,
        '-i',
        'input2.mp3',
        '-c',
        'copy',
        '-map',
        '0:v:0',
        '-map',
        '1:a:0',
        'output.mp4'
    ]);

    // Read the extracted audio file from the file system
    const data = await ffmpeg.readFile('output.mp4');

    const blob = new Blob([data], {type: 'video/mp4'})
    // Return the audio data as Uint8Array
    return blob;
}

export async function mergeVideoAndWaterMark(urlOne: string, urlTwo: string): Promise<Blob> {
    console.log("mergeVideoAndWaterMark start", urlOne, urlTwo)


    // Fetch the video file
    const videoArrayOne = await getUrlAsArray(urlOne)
    const videoArrayTwo = await getUrlAsArray(urlTwo)


    // Write the video file to the FFmpeg file system
    await ffmpeg.writeFile('input1.mp4', videoArrayOne);
    await ffmpeg.writeFile('input2.png', videoArrayTwo);
    const x = 0;
    const y = 0;
    await ffmpeg.exec([
        '-i', 'input1.mp4',
        '-i', 'input2.png',
        '-filter_complex', `[0:v]scale=iw:ih[v0];[v0][1:v] overlay=${x}:${y}:enable='1'`,
        'output.mp4'
    ]);


    // Read the extracted audio file from the file system
    const data = await ffmpeg.readFile('output.mp4');

    const blob = new Blob([data], {type: 'video/mp4'})
    // Return the audio data as Uint8Array
    return blob;
}

export const convertVideoToGif = async (url: string) => {
    console.log("convertVideoToGif")
    // Fetch the video from the URL
    const video = await getUrlAsArray(url)

    // Write the video to memory
    await ffmpeg.writeFile('input.mp4', video);

    // Run the FFmpeg command to convert the video to a gif
    await ffmpeg.exec(['-i', 'input.mp4', 'output.gif']);

    // Read the result
    const data = await ffmpeg.readFile('output.gif');

    // Create a URL from the result
    const blob = new Blob([data], {type: 'image/gif'})
    return blob;
};

export async function convertImagesToVideo(images: string[], fps = 30): Promise<Blob> {
    console.log("convertImagesToVideo: frames received:", images.length)
    // Create a temporary directory to store the images
    await ffmpeg.createDir('/images');

    // Write images to the virtual file system
    for (let i = 0; i < images.length; i++) {
        const image = await fetchFile(images[i]);
        await ffmpeg.writeFile(`/images/image${i}.png`, image);
    }
    // console.log("start exec")
    // Run FFmpeg command to create the video
    await ffmpeg.exec(
        [
            '-framerate',
            `${fps}`, // Set the framerate to 60 fps
            '-i',
            '/images/image%d.png',
            '-c:v',
            'libx264',
            '-pix_fmt',
            'yuv420p',
            "output.mp4"
        ]
    );
    // console.log("done exec")
    // Read the output file from the virtual file system
    const data = await ffmpeg.readFile('output.mp4');

    const blob = new Blob([data], {type: 'video/mp4'})

    // Perform cleanup
    // console.log("delete files")
    for (let i = 0; i < images.length; i++) {
        await ffmpeg.deleteFile(`/images/image${i}.png`);
    }
    // console.log("delete folder and output")
    await ffmpeg.deleteDir('/images');
    await ffmpeg.deleteFile('output.mp4');

    return blob;

}

export async function calculateFramePerSecond(url: string): Promise<number> {
    console.log("calculateFramePerSecond", url)
    return new Promise(async (resolve, reject) => {

        // Fetch the video file
        const videoArray = await getUrlAsArray(url)

        // Write the video file to the FFmpeg file system
        await ffmpeg.writeFile('input.mp4', videoArray);
        const callback = ({type, message}: any) => {
            // console.log("calculateFramePerSecond", type, message)

            const match = message.match(/(\d+)\s*fps/);
            // console.log("calculateFramePerSecond match", match)

            if (match) {
                const fps = parseFloat(match[1]);
                console.log("calculateFramePerSecond DONE - found fps:", fps)
                resolve(fps);
            }
        }
        ffmpeg.on("log", callback)
        await ffmpeg.exec(['-i', 'input.mp4']);
        ffmpeg.off("log", callback)
    });


}

export async function convertVideoToImages(url: string): Promise<string[]> {
    const video = await getUrlAsArray(url)

    // Write the video to memory
    await ffmpeg.writeFile('input.mp4', video);

    await ffmpeg.exec(
        [
            '-i',
            'input.mp4',
            'output-%d.png'
        ]
    );
    const files = await ffmpeg.listDir('/');
    const images = await Promise.all(files.filter((file) => file.name.match(/output-\d+.png/)).map(async (file) => {
        const data = await ffmpeg.readFile(file.name);
        const url = URL.createObjectURL(new Blob([data], {type: 'image/png'}));
        return url
    }));
    console.log("convertVideoToImages: created frames:", images.length)

    return await convertBlobUrlsToBase64Pngs(images)

}

export async function mergeAudioFiles(urlOne: string, urlTwo: string, startTime: number) {
    const audioArrayOne = await getUrlAsArray(urlOne);
    const audioArrayTwo = await getUrlAsArray(urlTwo);

    // Write the audio files to the FFmpeg file system
    await ffmpeg.writeFile('input1.mp3', audioArrayOne);
    await ffmpeg.writeFile('input2.mp3', audioArrayTwo);

    await ffmpeg.exec(
        [
            '-i',
            'input1.mp3',
            '-i',
            'input2.mp3',
            '-filter_complex',
            `[1:a]adelay=${startTime * 1000}|${startTime * 1000}[delayed];[0:a][delayed]amix=inputs=2:duration=longest[a]`,
            '-map',
            '[a]',
            '-c:a',
            'libmp3lame',
            'output.mp3',
        ]
    );

    const data = await ffmpeg.readFile('output.mp3');
    const blob = new Blob([data], {type: 'audio/mp3'});

    return blob;

}

export async function createEmptyAudioFile(durationInSeconds: number) {

    await ffmpeg.exec(
        [
            '-f',
            'lavfi',
            '-i',
            `anullsrc=channel_layout=stereo:sample_rate=44100`,
            '-t',
            `${durationInSeconds}`,
            'output.mp3'
        ]
    );

    const data = await ffmpeg.readFile('output.mp3');
    return new Blob([data], {type: 'audio/mp3'});

}

export async function getVideoMetaData(url: string) {
    try {
        // Fetch the video data
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch video');
        }

        // Read the video data as an ArrayBuffer
        const videoData = await response.arrayBuffer();

        // Create a blob from the ArrayBuffer
        // @ts-ignore
        const videoBlob = new Blob([videoData], {type: response.headers.get('content-type')});

        // Create a URL object from the video blob
        const videoURL = URL.createObjectURL(videoBlob);

        // Create a video element to get metadata
        const videoElement = document.createElement('video');
        videoElement.src = videoURL;

        // Wait for the video to load metadata
        await new Promise((resolve) => {
            videoElement.addEventListener('loadedmetadata', resolve);
        });

        // Extract metadata
        const width = videoElement.videoWidth;
        const height = videoElement.videoHeight;
        const duration = isFinite(videoElement.duration) ? videoElement.duration : 0;

        console.log("getVideoMetaData", {width, height, duration})
        // Clean up the temporary URL and video element
        URL.revokeObjectURL(videoURL);
        videoElement.remove();

        // Return metadata
        return {width, height, duration};
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

async function blobUrlToBase64(blobUrl: string): Promise<string> {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64data = reader.result as string;
            resolve(base64data);
        };
        reader.readAsDataURL(blob);
    });
}

async function convertBlobUrlsToBase64Pngs(blobUrls: string[]): Promise<string[]> {
    const base64Pngs: string[] = [];
    for (const blobUrl of blobUrls) {
        const base64Png = await blobUrlToBase64(blobUrl);
        base64Pngs.push(base64Png);
    }
    return base64Pngs;
}


const getUrlAsArray = async (url: string) => {
    const response = await fetch(url);
    const videoData = await response.arrayBuffer();
    const videoArray = new Uint8Array(videoData);
    return videoArray;
}
