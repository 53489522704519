import React, {useEffect, useState} from "react";
import CaptionsTimeline from "./CaptionsTimeline";
import {FormGlobalErrorMessage, LoadingStripes, useForm, withNestedForm} from "front-lib";
import {useAi} from "../../engine/useAi";


interface IProps {
    onSubmit?: (formData?: any) => Promise<void>;
    clip: any
}

const ClipScriptForm = ({clip, onSubmit}: IProps) => {
    const {setValue, handleSubmit, isSubmitting, getError} = useForm();
    const [isLoading, setIsLoading] = useState<boolean>(!clip.captions);
    const {transcribeClip} = useAi();
    const isError = getError("global");
    const initScriptIfItsFirstTime = async () => {
        if (clip && !clip.captions) {
            // handleSubmit(async () => {
                const clipScriptModel = await transcribeClip(clip.id)
                console.log("clipScriptModel", clipScriptModel)
                const captions = clipScriptModel.captions;
                setValue("captions", captions, {shouldDirty: true})
                const words = clipScriptModel.words;
                setValue("words", words, {shouldDirty: true})
            // });

        }
    }
    useEffect(() => {
        initScriptIfItsFirstTime()
    }, []);

    useEffect(() => {
        if (isError) {
            setIsLoading(false)
        }
        if (clip.captions && isLoading) {
            setIsLoading(false)
        }
    }, [clip, isLoading, isError]);
    return (
        <>
            {/*aa{isSubmitting ? "yes " : "no"}*/}
            <div className="min-h-full space-y-6 px-4 py-0 sm:p-6 overflow-y-auto max-h-[560px]">
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                        {isLoading && <div>
                            <div className={'flex items-center justify flex-col w-full mt-10'}>
                                <LoadingStripes text={"Doing some cool AI stuff..."} className={'mx-auto'}/>
                            </div>
                            {/*<LoadingCaptionsView/>*/}
                        </div>}
                        <FormGlobalErrorMessage/>
                        {!isLoading && <CaptionsTimeline key={`clip_updated_${clip.updatedAt}`} clip={clip}/>}
                    </div>
                </div>
            </div>

        </>
    )
}

const LoadingCaptionsView = () => {

    return <>
        <div role="status" className="max-w-sm animate-pulse">
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
            <span className="sr-only">Loading...</span>
        </div>
    </>
}

export default (ClipScriptForm)
