import {useEffect} from "react";
import {useWatch} from "react-hook-form";
import {useForm} from "./useForm";


export const useFormChange = (onChange?: any) => {
    const {control} = useForm();
    const formValues = useWatch({control})

    useEffect(() => {

        if (!Object.keys(formValues).length) return;
        console.log("useFormChange: formChanged", formValues)
        onChange && onChange(formValues);
    }, [formValues]);

    return formValues;
}