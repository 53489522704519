import React from "react";
import {FaDiscord, FaTwitch} from "react-icons/all";
import {LabelDateTime} from "front-lib";
import {Link, useNavigate} from "react-router-dom";
import {formatTime} from "../../engine/fabric/useFabric";

interface IProps {
    clip: any
}

const LabelClipMeta = ({clip}: IProps) => {
    const {fps, duration, captions} = clip

    const words = (() => {

        if (!captions) return 0

        return captions.map((c: any) => c.transcript).reduce((sum: number, str: string) => {

            return sum + str.trim().split(" ").length
        }, 0)

    })()
    return (
        <div>
            {/*<div>*/}
            {/*    FPS: {fps}*/}
            {/*</div>*/}
            <div>
                Captions: {captions ? captions.length : 0}
            </div>
            <div>
                Words: {words}
            </div>
            {/*<div>*/}
            {/*    Duration: {formatTime(duration)}*/}
            {/*</div>*/}
        </div>
    );
};


export default LabelClipMeta;
