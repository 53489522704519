import React from 'react';
import "./stripes-loader.scss"

const LoadingStripes = ({className, text = "Loading"}: any) => {
    return (<div className={'flex align-items flex-col '}>
        <div className={`boxContainer ${className}`}>
            <div className="box box1"></div>
            <div className="box box2"></div>
            <div className="box box3"></div>
            <div className="box box4"></div>
            <div className="box box5"></div>
        </div>
        {text && <span className={'mt-1 text-center'}>{text}</span>}
    </div>)
}

export {LoadingStripes}

//https://codepen.io/yomateo/pen/ypbNrJ
