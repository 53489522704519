import React from "react";
import {
    FormGlobalErrorMessage,
    FormGlobalSuccessMessage,
    FormInputNumber,
    LazyFormColorPicker,
    useForm, withForm
} from "front-lib";


interface IProps {
    onSubmit?: (formData?: any) => Promise<void>;
    clip: any
}

const CaptionsStyleForm = ({clip, onSubmit}: IProps) => {

    const {handleSubmit} = useForm();

    return (
        <>

            <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-4">
                    <FormInputNumber label={"Font Size"} name={"captionsFontSize"}
                                     defaultValue={clip.captionsFontSize}/>
                </div>

                <div className="col-span-6 sm:col-span-4">
                    <LazyFormColorPicker defaultValue={clip.captionsPrimaryColor} name={"captionsPrimaryColor"}
                                         onChange={(hex: string) => {
                                             // setThemeColor("primary", hex)
                                         }}
                                         label={"Primary Color"}/>
                </div>
                {/*<div className="col-span-6 sm:col-span-4">*/}
                {/*    <LazyFormColorPicker defaultValue={clip.captionsBackgroundColor || ""}*/}
                {/*                         name={"captionsBackgroundColor"}*/}
                {/*                         onChange={(hex: string) => {*/}
                {/*                             // setThemeColor("primary", hex)*/}
                {/*                         }}*/}
                {/*                         label={"Background Color"}/>*/}
                {/*</div>*/}

                {/*</div>*/}

            </div>

            {/*<div className="bg-gray-50 dark:bg-gray-900 px-4 py-3 text-right sm:px-6 flex justify-between">*/}

            {/*    <button type="submit"*/}
            {/*            onClick={() => {*/}
            {/*                console.log("before on submit")*/}
            {/*                //make sure all the form is valid*/}
            {/*                handleSubmit(onSubmit)*/}

            {/*                // console.log("after on submit")*/}
            {/*            }}*/}
            {/*            className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">Save*/}
            {/*    </button>*/}
            {/*</div>*/}
        </>
    )
}

export default CaptionsStyleForm
