import ReactGA, {ReactGAImplementation} from "react-ga4";

const VITE_APP_ANALYTICS_CODE = import.meta.env.VITE_APP_ANALYTICS_CODE;


export const initAnalytics = () => {
    console.log("initAnalytics")
    if (VITE_APP_ANALYTICS_CODE && window.location.href.indexOf("http://localhost/") === -1) {
        console.log("initAnalytics starting ga")
        ReactGA.initialize(VITE_APP_ANALYTICS_CODE, {
            testMode: false
        });
    }
}

const gaEvents = {
    trackSocialLogin: (provider: string) => ReactGA.event("login", {method: provider}),
    trackDonationFlowWizardAction: (action: string, streamer: string) => ReactGA.event({
        category: "DonationFlowWizard",
        action: action,
        label: streamer,

    })
}
export {gaEvents}
