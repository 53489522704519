import React from "react";
import {FaDiscord, FaTwitch} from "react-icons/all";
import {LabelDateTime} from "front-lib";
import {Link, useNavigate} from "react-router-dom";

interface IProps {
    clip: any
}

const LabelClipResolution = ({clip}: IProps) => {
    const {width, height} = clip
    return (
        <span>
            {width}x{height}
        </span>
    );
};


export default LabelClipResolution;
