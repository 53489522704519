import React from 'react';
import Dropzone from 'react-dropzone'
import {Link} from "react-router-dom";

interface IProps {
    onFilesChanged: (files: any[]) => Promise<void>,
    disabled?: boolean,
    children?: any
}

const DropzoneVideoUploader = ({onFilesChanged, disabled, children}: IProps) => {

    // const inputFile = useRef<any>(null)


    // console.log("ImageUploader", progress, isUploading);
    return (
        <div>
            <Dropzone noClick={true} onDrop={onFilesChanged} disabled={disabled}>
                {({getRootProps, getInputProps, isDragAccept}: any) => (

                    <div
                        {...getRootProps()}
                        className={`mt-1 flex flex-col rounded-md border-2 min-h-[300px] items-center border-dashed border-gray-300 dark:border-gray-700 px-6 pt-5 pb-6 ${isDragAccept ? 'border-primary-300 bg-gray-100' : ' '} ${disabled ? 'opacity-50 pointer-events-none' : ' '}`}>

                        <div className={'flex  grow items-center'}>
                            <div className="space-y-1 text-center">
                                <div>
                                    <div
                                        className="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12 flex justify-center items-center flex-col">

                                        <AddVideoIcon/>
                                        <h3 className="mb-3 text-3xl font-bold tracking-tight leading-none text-gray-950    dark:text-white">
                                            Create new clip
                                        </h3>
                                        <p className="font-light text-gray-500 text-md dark:text-gray-400">
                                            Create new clip by clicking the button <br/>or by Drag 'n' drop a file here
                                        </p>

                                        <div className={'flex items-center'}>
                                            <label htmlFor="file-upload"
                                                   className="mt-3 cursor-pointer rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                Browse File
                                                <input type='file' id='file-upload' style={{display: 'none'}}
                                                       multiple
                                                       accept={"image/svg,image/svg+xml,image/jpg,image/jpeg,image/png"}
                                                       {...getInputProps()}
                                                       onChange={async (event: any) => {
                                                           event.stopPropagation();
                                                           event.preventDefault();
                                                           console.log("event.target.files", event.target.files)
                                                           await onFilesChanged(Array.from(event.target.files))
                                                       }}/>
                                            </label>
                                            {children}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )}
            </Dropzone>
            {/*<div*/}
            {/*    className={`${isDragAccept ? 'bg-primary-300' : 'bg-gray-400'} h-[300px] `} {...getRootProps()}>*/}
            {/*    <input {...getInputProps()} />*/}
            {/*    <p>Drag 'n' drop some files here, or click to select files</p>*/}
            {/*</div>*/}

        </div>
    );
};

const AddVideoIcon = () => {

    return <>
        <svg fill="none" className="mx-auto w-[80px] h-[80px] fill-gray-500 " height="512" viewBox="0 0 24 24"
             width="512" xmlns="http://www.w3.org/2000/svg">
            <path clipRule="evenodd"
                  d="m9.98974 1.25008h-3.98974c-2.62335 0-4.75 2.12665-4.75 4.75v12.00002c0 2.6233 2.12665 4.75 4.75 4.75h12c2.6234 0 4.75-2.1267 4.75-4.75v-12.00002c0-2.62335-2.1266-4.75-4.75-4.75h-7.9884c-.0073-.00011-.01456-.00011-.02186 0zm-1.39113 1.5h-2.59861c-1.79493 0-3.25 1.45507-3.25 3.25v1.25h2.84861zm-1.19722 4.5 3.00001-4.5h6.1972l-3 4.5zm-1.41303 1.5c.00731.00011.0146.00011.0219 0h7.97814c.0073.00011.0146.00011.0219 0h7.2397v9.25002c0 1.7949-1.4551 3.25-3.25 3.25h-12c-1.79493 0-3.25-1.4551-3.25-3.25v-9.25002zm15.26164-1.5v-1.25c0-1.66423-1.2509-3.0363-2.8638-3.22729l-2.9848 4.47729zm-9.25 4.00002c.4142 0 .75.3358.75.75v2.25h2.25c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-2.25v2.25c0 .4142-.3358.75-.75.75s-.75-.3358-.75-.75v-2.25h-2.25c-.41421 0-.75-.3358-.75-.75s.33579-.75.75-.75h2.25v-2.25c0-.4142.3358-.75.75-.75z"
                  fill="rgb(0,0,0)" fillRule="evenodd"/>
        </svg>
    </>
}
export {DropzoneVideoUploader}
