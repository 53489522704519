import React from "react";
import {Helmet} from "react-helmet-async";
import ClipCrudTable from "../../../../modules/admin/crud/entities/clip/ClipCrudTable";

const ClipsRoute = ({}) => {
    return (
        <>
            <Helmet>
                <title>Clips</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl py-5'}>
                {/*<div className={''}>*/}
                {/*    <Breadcrumb/>*/}
                {/*</div>*/}
                <div className={''}>
                    <ClipCrudTable/>
                </div>
            </div>
        </>
    )
}

export default ClipsRoute