import {fabric} from "fabric";
import {getSubtitleAccordingToCurrentTime} from "../useCanvasEffects";

export const useShapes = () => {

    const getSubtitlesShapeForClip = (clip: any, startTime = 0) => {
        const {width, height, captionsFontSize, captionsPrimaryColor, captionsFontFamily} = clip;

        const defaultPosition = clip.shapes?.find((s: any) => s.id === "subtitles") || {
            left: width / 2,
            top: (height / 2),
            angle: -10,
            width: width,
        }
        const defaultSub = getSubtitleAccordingToCurrentTime(clip, startTime)
        const defaultText = defaultSub ? defaultSub.transcript : "";
        const shape = new fabric.Textbox(defaultText, {
            ...defaultPosition,
            originX: 'center',
            originY: 'center',
            centeredRotation: true,
            fontSize: captionsFontSize,
            fontFamily: captionsFontFamily,
            fill: captionsPrimaryColor,
            textAlign: 'center',
            selectable: true,
            editable: false
        });

        // @ts-ignore
        shape.onRender = ({seconds}) => {

            const currentTime = seconds + startTime;
            console.log("onRender", currentTime)
            //@ts-ignore
            shape.updateSubtitleText(currentTime)
            shape.bringToFront();
        };

        //@ts-ignore
        shape.updateSubtitleText = (currentTime) => {
            console.log("updateSubtitleText", currentTime)
            const sub = getSubtitleAccordingToCurrentTime(clip, currentTime);
            const text = sub ? sub.transcript : "";
            shape.set({text});
        }
        return shape;
    }

    return {
        getSubtitlesShapeForClip
    }
}


