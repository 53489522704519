import React from 'react';
import {
    AvatarList,
    ButtonFormSubmit,
    FormGlobalErrorMessage,
    FormGlobalSuccessMessage,
    FormInputText,
    withForm
} from "front-lib";


interface IProps {
    workspace: any;
    onSubmit?: (formData?: any) => Promise<void>;
    onCancel?: () => void
}

const WorkspaceForm = ({workspace, onSubmit, onCancel}: IProps) => {
    return (
        <>
            <div className="space-y-6 bg-white dark:bg-gray-900 px-4 py-5 sm:p-6">

                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6  sm:col-span-3">
                        <FormInputText label={"Name"} name={"name"}
                                       defaultValue={workspace.name}/>
                    </div>
                    <div className="col-span-6 ">
                        <label
                            className="block mb-2 text-sm font-medium text-gray-950 dark:text-gray-300">
                            Profiles
                        </label>
                        {workspace.profiles && <AvatarList profiles={workspace.profiles} max={20}/>}

                    </div>

                    <div className="col-span-6 ">
                        <label
                            className="block mb-2 text-sm font-medium text-gray-950 dark:text-gray-300">
                            Puppets
                        </label>
                        {workspace.puppets && <AvatarList profiles={workspace.puppets} max={20}/>}

                    </div>
                    <div className="col-span-6">
                        <FormGlobalErrorMessage/>
                        <FormGlobalSuccessMessage/>
                    </div>
                </div>
            </div>

            <div
                className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">

                <ButtonFormSubmit onSubmit={onSubmit}/>
                <button type="button"
                        onClick={onCancel}
                        className="text-gray-500 min-h-[50px] bg-white hover:bg-gray-100  rounded-lg border border-gray-200 text-lg font-medium px-5 py-2.5 hover:text-gray-950 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                    Cancel
                </button>
            </div>
        </>);

};

export default withForm(WorkspaceForm)
