import React from 'react';
import {LoadingStripes} from "../stripes/LoadingStripes";

const LoadingCover = ({isLoading, children, className = "", text}: any) => {
    return (
        <>
            {!isLoading && children && children()}
            {isLoading && <div className={`h-full ${className}`}>
                <div className={'flex items-center justify flex-col w-full'}>
                    <LoadingStripes text={text} className={'mx-auto'}/>
                </div>
            </div>}
        </>
    )
}

export {LoadingCover}