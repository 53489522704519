import React from "react";
import {useForm} from "../context/useForm";
import {FormFieldErrorMessage} from "../messages/error/FormFieldErrorMessage";
import {Controller} from "react-hook-form";


const FormInputText = ({
                           name,
                           defaultValue = "",
                           value,
                           rules,
                           label,
                           placeholder,
                           type = "text",
                           autoFocus,
                           className,
                           onChange,
                           disabled,
                       }: any) => {
    const {control} = useForm();

    const inputClassName = className || `${disabled ? "disabled" : ""} bg-gray-50 border border-gray-300 text-gray-950 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-gray-300 dark:focus:ring-primary-500 dark:focus:border-primary-500`
    return (
        <div className={``}>
            <Controller
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                name={name}
                render={({field}: any) => (
                    <>
                        {label && <label htmlFor={name}
                                         className="block mb-2 text-sm font-medium text-gray-950 dark:text-gray-300">
                            {label}
                        </label>}
                        <input type={type}
                               id={name}
                               {...field}
                            // value={value}
                            //  autoComplete={"off"}
                               disabled={disabled}

                            // onChange={onChange}
                               autoFocus={autoFocus}
                            //
                               className={inputClassName}
                               placeholder={placeholder}/>
                    </>
                )}
            />

            <FormFieldErrorMessage name={name}/>
        </div>
    );
};

export {FormInputText};
