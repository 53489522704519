import React from "react"

const TabClosedMessage = ({text, subText, icon}: any) => {
    return <div className={'noData'}>
        <div>
            <div
                className="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12 flex justify-center items-center flex-col">

                <div className={'text-gray-500 w-[100px] h-[100px] mb-5 opacity-75'}>
                    {icon}
                </div>

                <h3 className="mb-2 text-3xl font-bold tracking-tight leading-none text-gray-950    dark:text-white">
                    {text}
                </h3>
                <p className="font-light text-gray-500 text-md dark:text-gray-400">
                    {subText}
                </p>
            </div>
        </div>
    </div>;
}

export {TabClosedMessage}