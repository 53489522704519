import React from 'react';
import {Link} from "react-router-dom";
import {IconLogo} from "front-lib";

const BrandName = ({to = "/", className}: any) => {
    return (
        <Link to={to} className={`flex items-center ${className}`}>
            {/*<img src={logo} className=" h-8"*/}
            {/*     alt="Logo"/>*/}
            <IconLogo className={'w-8 h-8 mr-1 fill-primary-200 dark:fill-primary-200 icon-logo -rotate-12'}/>
            <span
                className="brand-name self-center text-2xl whitespace-nowrap text-primary-200 dark:text-primary-200">
                Clipz
            </span>
        </Link>
    )
}

export default BrandName