import React, {forwardRef, useEffect, useState} from "react";
import {ShapesCanvas} from "./ShapesCanvas";
import {useForm} from "front-lib";

const ShapesEditor = ({clip, videoRef, scale}: any) => {
    const {setValue} = useForm();
    const onShapeChanged = (shape: any) => {
        console.log("onShapeChanged", shape)
        setValue("shapes", [shape], {shouldDirty: true})
    }
    return (
        <>
            <div
                className={'absolute z-20 border-1 top-0 left-1/2  text-white origin-top-left  text-center'}
                style={{
                    width: clip.width,
                    height: clip.height,
                    transform: `scale(${scale})`
                }}>
                <>

                    {clip.captions && <div className={' -translate-x-1/2 '}>
                        <ShapesCanvas
                            videoRef={videoRef}
                            clip={clip}
                            onShapeChanged={onShapeChanged}
                            // text={subtitle}
                        />
                    </div>}
                </>
            </div>
        </>

    )
}


export {ShapesEditor}
