import React from "react"
import {LoadingStripes, Popover, useForm, withNestedForm} from "front-lib";
import {BiMovie} from "react-icons/bi";
import {MdOutlineSubtitles} from "react-icons/md";
import {calcActiveCaptionsDuration} from "../forms/CaptionsTimeline";
import {ExportsToaster} from "./ExportsToaster";
import {useExportsContext} from "./context/useExportsContext";

const ExportDropdown = withNestedForm(({clip, onExportCompleted}: any) => {
    const {addToExportsQueue } = useExportsContext();
    const {handleSubmit, isSubmitting} = useForm()
    const startExport = (type: string) => {

        handleSubmit(async () => {
            const c = await addToExportsQueue(clip, type)
            onExportCompleted(c);
        })

    }
    return (
        <>
            <ExportsToaster/>
            <div className={'relative z-30'}>
                <Popover
                    className={'absolute top-[40px] right-0 z-20 my-4 w-56 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600'}
                    renderTrigger={<button type="button"
                                           disabled={isSubmitting}
                                           className={`text-lg min-h-[50px] flex justify-center items-center text-white bg-primary-300 hover:bg-primary-400  font-medium rounded-lg px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 `}>
                        {/*{!isSubmitting && 'Export'}*/}
                        {/*{isSubmitting && <div className={'flex items-center'}>*/}
                        {/*    <LoadingStripes text={""} className={'small white'}/>*/}
                        {/*    <span className={'ml-1'}>Exporting...</span>*/}
                        {/*</div>}*/}
                        Export
                    </button>}
                    renderContent={({toggleOpen}: any) => {
                        return <div onClick={toggleOpen}>
                            <ul className="py-1 font-light text-gray-500 dark:text-gray-400 border-t-2 border-gray-100"
                                aria-labelledby="dropdown">
                                <li className={'mb-0'}>
                                    <DropdownLink
                                        onClick={() => startExport("video")}
                                        icon={<BiMovie className={'mr-2 w-5 h-5'}/>}
                                        text={"Video (mp4)"}/>
                                </li>
                                {/*<li className={'mb-0'}>*/}
                                {/*    <DropdownLink*/}
                                {/*        onClick={() => submitExportFunc(exportAsAudio)}*/}
                                {/*        icon={<MdOutlineAudiotrack className={'mr-2 w-5 h-5'}/>}*/}
                                {/*        text={"Audio (mp3)"}/>*/}
                                {/*</li>*/}
                                <li className={'mb-0'}>
                                    <DropdownLink
                                        onClick={() => startExport("subtitles")}
                                        icon={<MdOutlineSubtitles className={'mr-2 w-5 h-5'}/>}
                                        text={"Subtitles (srt)"}/>
                                </li>
                            </ul>
                        </div>
                    }}
                />
            </div>
        </>

    )
})

const DropdownLink = ({onClick, text, icon}: any) => {

    const getActiveClass = () => {
        // if (to === pathname)
        //     return "bg-gray-100 dark:bg-gray-700"
        return ""
    }
    return <span
        onClick={onClick}
        className={`cursor-pointer flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${getActiveClass()} `}>
        {icon}
        {text}
    </span>
}

export {ExportDropdown}