import React, {useEffect, useState} from "react";
import {sleep} from "front-lib";
import {LoadingCover} from "front-lib";

interface IProps {
    children: JSX.Element;
    preload?: () => Promise<void>
}

const ApplicationConfigsProvider = ({children, preload}: IProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [appKey, setAppKey] = useState(1);

    useEffect(() => {
        (async () => {

            try {
                await Promise.all([
                    sleep(500),
                    preload && preload()
                ]);
                // throw new Error("Something bad")
                setIsLoading(false);
            } catch (err) {
                console.error(err);
                setHasError(true);
                setIsLoading(false);
            }

        })();
    }, []);


    useEffect(() => {
        const handleReload = async (e: any) => {
            console.log("handle app-reload", e);
            setIsLoading(true)
            setAppKey(appKey + 1)
            await sleep(1000)
            setIsLoading(false)
        };

        window.addEventListener("app-reload", handleReload);
        return () => window.removeEventListener("app-reload", handleReload)
    }, [appKey])
    return (
        <LoadingCover key={appKey} isLoading={isLoading} className={'min-h-screen flex items-center'}>
            {() => {
                return <>
                    {!hasError && children}
                    {hasError && <ErrorView/>}
                </>;
            }}
        </LoadingCover>
    );
};

const ErrorView = () => {

    return <section className="bg-white dark:bg-gray-950 flex items-center">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center">
                <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">500</h1>
                <p className="mb-4 text-3xl tracking-tight font-bold text-gray-950 md:text-4xl dark:text-white">Something's
                    went wrong.</p>
                <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Application cannot be
                    loaded...</p>
                {/*<Link to={"/"}*/}
                {/*      className="inline-flex text-white bg-primary-600 hover:bg-primary-800  font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">Back*/}
                {/*    to Homepage*/}
                {/*</Link>*/}

            </div>
        </div>
    </section>
}

export {ApplicationConfigsProvider};
