import React, {useState} from "react";
import {MdOutlineClose, MdOutlineInfo} from "react-icons/md";

const AlertWarning = ({icon, children, showClose = true, onClose}: any) => {
    const [isVisible, setIsVisible] = useState(true);

    return (
        <>
            {isVisible &&
                <div
                    className="bg-warning-100 border-t-4 border-warning-500 rounded-b text-warning-900 px-4 py-3 shadow-md"
                    role="alert">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center w-full">

                            {icon || <MdOutlineInfo className="fill-current h-6 w-6 text-warning-500 mr-4"/>}
                            <div className="font-sm m-0  w-[calc(100%-40px)]">{children}</div>

                        </div>
                        {showClose && <div className={'flex '}>
                            <button type="button"
                                    onClick={(e) => {
                                        setIsVisible(false)
                                    }}
                                    className="cursor-pointer p-2 my-[2px] -mr-[9px] text-warning-500 rounded-lg hover:text-warning-900 hover:bg-warning-200 dark:text-warning-400 dark:hover:text-white dark:hover:bg-warning-700 focus:ring-4 focus:ring-0 dark:focus:ring-warning-600">
                                <MdOutlineClose className={'w-5 h-5'}/>
                            </button>
                        </div>}

                    </div>
                </div>}
        </>
    )
}

export {AlertWarning}