import React, {useState} from "react";
import {HelmetProvider} from "react-helmet-async";
import AppRouter from "./routing/AppRouter";
import {AuthContextProvider, setupInterceptors, ThemeProvider, LocalizationProvider} from "front-lib";
import ExportsContextProvider from "./modules/app/clip-editor/export/context/ExportsContextProvider";

setupInterceptors((error: any) => {

});

function App() {
    // console.log("App")
    return (
        <AuthContextProvider>
            <ThemeProvider>
                <LocalizationProvider>
                    <HelmetProvider>
                        <ExportsContextProvider>
                            <AppRouter/>
                        </ExportsContextProvider>
                    </HelmetProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </AuthContextProvider>
    )
}

export default App


const Example = () => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [items, setItems] = useState<any[]>(["a", "b", "c", "d", "d"]);

    const activeItem = items[0]; // should be in state or not? are we going to need to set the active item according something else? on open a dialog maybe?
    return <div>
        hello example
        <pre>
            {JSON.stringify(items, null, 2)}
        </pre>
    </div>
}