import React, {useState} from "react";
import {DialogAnimated} from "./DialogAnimated";

const DialogBasic = ({title, renderTrigger, renderContent, className}: any) => {
    const [isOpen, setIsOpen] = useState(false);

    const renderContentAsFunctionOrComponent = ({isOpen, toggleOpen}: any) => {

        const content = typeof (renderContent) === 'function' ? renderContent({isOpen, toggleOpen}) : renderContent;
        return content;
    }
    const renderTriggerWithOnClick = () => {
        const trigger = typeof (renderTrigger) === 'function' ? renderTrigger({isOpen}) : renderTrigger;
        if (React.isValidElement(trigger)) {
            return React.cloneElement<any>(trigger, {onClick: toggleOpen});
        }
        return renderTrigger
    }

    const toggleOpen = () => {
        // console.log("toggleOpen")
        const newState = !isOpen;
        setIsOpen(newState);

    }

    return (
        <>
            {renderTriggerWithOnClick()}
            <DialogAnimated isOpen={isOpen} onClose={toggleOpen} title={title} className={className}>
                <>
                    {renderContentAsFunctionOrComponent({isOpen, toggleOpen})}
                </>
            </DialogAnimated>

        </>
    )
}

export {DialogBasic}