import React from 'react';
import {IColumn} from "../../table/context/GenericTableContext";
import {LabelNullOrValue, LazyLabelDateTime, AvatarList} from "front-lib";
import DialogEditWorkspace from "./dialogs/DialogEditWorkspace";

export const workspaceColumns: IColumn[] = [
    {
        property: "actions",
        label: "Actions",
        disableSorting: true,
        isVisible: true,
        component: ({row, crudApi, loadRows}) => <div className={'flex items-center'}
                                                      onClick={(event) => event.stopPropagation()}>
            <DialogEditWorkspace workspace={row} onEdit={async (updatedWorkspace: any) => {
                await crudApi.edit(updatedWorkspace);
                await loadRows()
            }}/>

        </div>
    },
    {
        property: "id",
        label: "ID",
        isVisible: false,
        component: ({row, column}) => <>
            {row.id}
        </>
    },
    {
        property: "name",
        label: "Name",
        isVisible: true,
        component: ({row, column}) => <>
            <LabelNullOrValue value={row.name}/>
        </>
    },
    {
        property: "profiles",
        label: "Humans",
        isVisible: true,
        component: ({row, column}) => <>
            {row.profiles && <AvatarList profiles={row.profiles} max={4}/>}

        </>
    },

    {
        property: "puppets",
        label: "Puppets",
        isVisible: true,
        component: ({row, column}) => <>
            {row.puppets && <AvatarList profiles={row.puppets} max={4}/>}

        </>
    },
    {
        property: "createdAt",
        label: "Created",
        isVisible: true,
        component: ({row, column}) => <>
            <LazyLabelDateTime timestamp={row.createdAt}/>
        </>
    },

];
