import {FaPlay} from "react-icons/fa";
import React from "react";

const ButtonPlayAudio = ({url}: any) => {

    const onClick = () => {
        const audio = new Audio(url);
        return audio.play();
    }
    return (
        <>
            <button type="button"
                    onClick={onClick}
                    className="text-white bg-primary-200 hover:bg-primary-300 font-medium rounded-lg text-xs px-2 py-1 text-center inline-flex items-center dark:bg-primary-600 dark:hover:bg-primary-700 ">
                <FaPlay className="w-2.5 h-2.5 ml-1"/>
                {/*Play*/}
            </button>

        </>
    )
}

export {ButtonPlayAudio}