import React, {useEffect} from 'react';
import {fabric} from 'fabric';
import {useShapes} from "../../../engine/fabric/useShapes";
import randomImage from "../../../../../assets/dev/cloud-computing.png";


interface IProps {
    videoRef: any;
    clip: any;
    onShapeChanged?: any
}

export const ShapesCanvas = ({clip, videoRef, onShapeChanged}: any) => {

    const {getSubtitlesShapeForClip} = useShapes();
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const fabricCanvasRef = React.useRef<any>(null);
    const subsShapeRef = React.useRef<fabric.Textbox | null>(null);
    const imageShapeRef = React.useRef<fabric.Image | null>(null);
    const lastDuration = React.useRef<number>(-1);
    const {width, height, captionsFontSize, captionsPrimaryColor, captionsFontFamily} = clip;


    useEffect(() => {
        if (!canvasRef?.current) return;
        console.log("CaptionsCanvas: init start")
        fabricCanvasRef.current = new fabric.Canvas(canvasRef.current, {width, height});


        fabric.Image.fromURL(randomImage, function (img: any) {
            img.set({
                left: width - 300,
                top: height - 300
            });
            imageShapeRef.current = img;
            fabricCanvasRef.current.add(img);
        });


        const drawFrame = async () => {
            if (lastDuration.current !== videoRef.current?.currentTime) {
                //@ts-ignore
                subsShapeRef.current?.updateSubtitleText(videoRef.current.currentTime)
                fabricCanvasRef.current.renderAll();
            }
            lastDuration.current = videoRef.current.currentTime;

            requestAnimationFrame(drawFrame);
        }

        drawFrame();
        return () => {
            fabricCanvasRef.current.dispose();
        };
    }, []);

    useEffect(() => {

        if (clip.withSubs) {
            addSubsShape();
        } else {
            removeSubsShape();
        }

    }, [clip]);

    const addSubsShape = () => {
        console.log("addSubsShape");
        if (!subsShapeRef.current) {
            subsShapeRef.current = getSubtitlesShapeForClip(clip)
            subsShapeRef.current.setControlsVisibility({
                mt: false,
                mb: false,
                ml: false,
                mr: false,
                bl: false,
                br: false,
                tl: false,
                tr: false,
                // mtr: false,
            });
            subsShapeRef.current.on('modified', function (e: any) {
                const shape = {
                    id: "subtitles",
                    angle: e.target.angle,
                    left: e.target.left,
                    top: e.target.top,
                    width: e.target.width,
                };
                console.log("modified", e)
                onShapeChanged && onShapeChanged(shape)
            })
        }

        subsShapeRef.current.setOptions({
            fontSize: captionsFontSize,
            fontFamily: captionsFontFamily,
            fill: captionsPrimaryColor,
        })
        //@ts-ignore
        subsShapeRef.current.updateSubtitleText(videoRef.current.currentTime)
        fabricCanvasRef.current.add(subsShapeRef.current);
    }
    const removeSubsShape = () => {
        console.log("removeSubsShape")
        fabricCanvasRef.current.remove(subsShapeRef.current)
        lastDuration.current = -1;
    }
    return <>
        <div className={'flex spacing-x-2 mt-2 ml-2'}>
            <button
                onClick={addSubsShape}
                className={`text-lg min-h-[50px] mr-1 flex justify-center items-center text-white bg-primary-700 hover:bg-primary-800  font-medium rounded-lg px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700  `}>
                Add
            </button>
            <button
                onClick={removeSubsShape}
                className={`text-lg min-h-[50px] mr-1 flex justify-center items-center text-white bg-primary-700 hover:bg-primary-800  font-medium rounded-lg px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700  `}>
                Remove
            </button>
        </div>
        <canvas id={"captions-canvas"} className={'mx-auto'}
                ref={canvasRef}
                width={width}
                height={height}/>
    </>;
};
