import React, {useEffect, useState} from "react";
import {DropzoneVideoUploader, LoadingCover} from "front-lib"
import {useClipUploader} from "./useClipUploader";
import {Link, useNavigate} from "react-router-dom";
import {SpotlightView} from "../spotlight/SpotlightView";

const ClipCreationWizard = ({onCreated}: any) => {

    const [isCameraMode, setIsCameraMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {createClipEntity} = useClipUploader();
    const navigate = useNavigate();
    const onVideoBlobReady = async (blob: Blob | null | undefined) => {
        console.log("onVideoBlobReady", blob);
        if (blob) {
            setIsLoading(true);
            const blobUrl = URL.createObjectURL(blob);
            const clip = await createClipEntity(blobUrl);
            navigate(`/app/clips/${clip.id}`)
        }
    }
    return <>
        <div className={' px-2'}>
            <div className={'bg-white dark:bg-gray-900 p-4 pt-0 relative'}>
                {isLoading && <div className={'py-10'}>
                    <LoadingCover isLoading={true}/>
                </div>}
                {!isLoading && <>

                    {!isCameraMode && <DropzoneVideoUploader onFilesChanged={async (files: any[]) => {
                        const file = files[0];
                        await onVideoBlobReady(file);
                    }}>
                        <button
                            onClick={() => setIsCameraMode(true)}
                            className="ml-1 mt-3 cursor-pointer rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            Open Camera
                        </button>
                    </DropzoneVideoUploader>}

                    {isCameraMode && <>

                        <SpotlightView onCompleted={onVideoBlobReady} onBack={() => setIsCameraMode(false)}/>
                    </>}

                </>}
            </div>
        </div>
    </>
}

export default ClipCreationWizard
