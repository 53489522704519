import React, {useRef, useState} from "react";
import {Muxer, ArrayBufferTarget} from "mp4-muxer";

let videoEncoder: any = null;
let lastKeyFrame: any = null;
let framesGenerated = 0;
let startTime: any = null;
export const useMuxer = () => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const muxerRef = React.useRef<any>(null);

    const bundleFrames = async (frames: string[], width: number, height: number, onProgress?: any): Promise<Blob> => {
        console.log("bundleFrames", frames.length);
        await initCanvas(width, height);
        await initMuxer(width, height);
        startTime = document.timeline.currentTime;
        for (let i = 0; i < frames.length; i++) {
            // await sleep(100);
            await drawFrame(frames[i]);
            encodeFrame();
            onProgress && onProgress(i)
        }

        await closeMuxer();

        let buffer = muxerRef.current.target.buffer;
        return new Blob([buffer])
    };

    const closeMuxer = async () => {
        const muxer = muxerRef.current;
        await videoEncoder?.flush();
        muxer.finalize();
    };

    const initMuxer = async (width: number, height: number): Promise<void> => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        return new Promise(async (resolve, reject) => {
            console.log("initMuxer");
            muxerRef.current = new Muxer({
                target: new ArrayBufferTarget(),
                video: {
                    codec: "avc",
                    width: width,
                    height: height,
                },
                fastStart: "in-memory",
                firstTimestampBehavior: "offset",
            });

            videoEncoder = new VideoEncoder({
                output: (chunk, meta) => muxerRef.current.addVideoChunk(chunk, meta),
                error: (e) => console.error(e),
            });
            videoEncoder.configure({
                codec: "avc1.42001f",
                width: width,
                height: height,
                bitrate: 1e6,
            });
            resolve();
        });
    };

    const initCanvas = async (width: number, height: number) => {
        canvasRef.current = canvasRef.current || document.createElement("canvas");
        canvasRef.current.width = width;
        canvasRef.current.height = height;
    };

    const drawFrame = async (frame: string): Promise<void> => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext("2d");
        if (!ctx) return;
        return new Promise(async (resolve, reject) => {
            console.log("drawFrame");
            const img = new Image();

            img.src = frame;
            img.onload = function () {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0);
                console.log("frame rendered");
                resolve();
            };
        });
    };
    const encodeFrame = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        // @ts-ignore
        let elapsedTime = document.timeline.currentTime - startTime;
        let frame = new VideoFrame(canvas, {
            timestamp: (framesGenerated * 1e6) / 30, // Ensure equally-spaced frames every 1/30th of a second
            duration: 1e6 / 30,
        });
        framesGenerated++;

        // Ensure a video key frame at least every 5 seconds for good scrubbing
        let needsKeyFrame = elapsedTime - lastKeyFrame >= 5000;
        if (needsKeyFrame) lastKeyFrame = elapsedTime;

        videoEncoder.encode(frame, {keyFrame: needsKeyFrame});
        frame.close();

        console.log("framesGenerated", framesGenerated);
    };

    return {
        bundleFrames,
        canvasRef,
    };
};

// const downloadBlob = (blob: Blob) => {
//   let url = window.URL.createObjectURL(blob);
//   let a = document.createElement("a");
//   a.style.display = "none";
//   a.href = url;
//   a.download = "davinci.mp4";
//   document.body.appendChild(a);
//   a.click();
//   window.URL.revokeObjectURL(url);
// };
