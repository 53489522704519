import {LoadingStripes, api} from "front-lib";
import React, {useEffect, useState} from "react";
import {ExportsContext} from "./context";
import {useExport} from "../useExport";
import {useClipUploader} from "../../useClipUploader";
import {calcActiveCaptionsDuration} from "../../forms/CaptionsTimeline";


const ExportsContextProvider = ({children}: any) => {
    const {canvasRef, exportVideoWithCanvasEffects, exportAsSubtitles} = useExport();
    const {createFileEntity} = useClipUploader();

    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    // const [exports, setExports] = useState<any[]>([]);

    const exportFunctions: any = {
        video: exportVideoWithCanvasEffects,
        subtitles: exportAsSubtitles
    }
    const onProgress = (percent: number) => {
        setProgress(Math.min(100, percent));
    }

    async function addToExportsQueue(clip: any, type: string) {
        console.log("addToExportsQueue");
        setIsExporting(true)
        const duration = calcActiveCaptionsDuration(clip)
        const totalFramesCount = duration * 30 * 2;
        // setExports(exports => [{type, clip, status: "loading"}, ...exports]);
        let steps = 0;
        onProgress(0);
        const blob = await exportFunctions[type](clip, () => {
            steps++;
            const percent = (steps / totalFramesCount) * 100;
            console.log("onProgress", steps, totalFramesCount, duration, percent)
            onProgress(percent);
        });
        const file = await createFileEntity(clip.id, blob, duration);
        setIsExporting(false)

        return {...clip, files: [file, ...clip.files]}
    }


    const context = {
        isExporting,
        progress,
        addToExportsQueue
    }
    return (
        <ExportsContext.Provider value={context}>
            {/*<canvas ref={canvasRef} width={1024} height={768}*/}
            {/*        className={'outline-2 outline-dashed outline-error-400 mt-10'}/>*/}

            {children}
        </ExportsContext.Provider>
    )
}

export default ExportsContextProvider
