import React from "react"
import CameraRecorder from "./camera-recorder/CameraRecorder";

const SpotlightView = ({onCompleted , onBack}: any) => {

    return (
        <>
            <CameraRecorder onCompleted={onCompleted} onBack={onBack}/>
        </>
    )
}

export {SpotlightView}