
import {IFilter} from "../../table/context/GenericTableContext";

export const clipCrudFilters: IFilter[] = [



];


