import {fabric} from "fabric";
import imageUrlOfWatermark from "../../../assets/dev/watermark.png";
import {useFabric} from "./fabric/useFabric";
import {useVideoFramesParser} from "./parsers/useVideoFramesParser";
import {sleep} from "front-lib";
import {useShapes} from "./fabric/useShapes";

export const useCanvasEffects = () => {
    const {
        initFabric,
        canvasRef,
        processVideo,
        stopRender,
        addShape,
    } = useFabric();
    const {getSubtitlesShapeForClip} = useShapes();
    const {initVideoFramesParser, parseFrameFromVideo} = useVideoFramesParser();

    const initCanvasEffects = async (clip: any, st?: number, et?: number) => {
        const startTime = st || 0;
        const endTime = et || clip.duration;
        await initFabric(clip);
        await initVideoFramesParser(clip.orgVideoUrl, startTime);
        const {width, height, captionsFontSize, captionsPrimaryColor, captionsFontFamily} = clip;

        //subtitles
        if (clip.captions) {
            await addShape(() => {
                return getSubtitlesShapeForClip(clip, startTime)
            });
        }

        //watermark
        await addShape(() => {
            return new Promise((resolve) => {
                fabric.Image.fromURL(imageUrlOfWatermark, function (img: any) {
                    img.set('left', width - 180).set('top', 10);
                    // img.set("left", width - 190).set("top", height - 60);
                    const scale = 0.3;
                    img.set({
                        scaleX: scale,
                        scaleY: scale,
                    });
                    // @ts-ignore
                    img.onRender = ({seconds}) => {
                        img.bringToFront();
                    };
                    resolve(img);
                });
            });
        });

        //video frame background
        await addShape(async () => {
            return new Promise((resolve) => {
                const img = new fabric.Image("", {
                    left: 0,
                    top: 0,
                    angle: 0,
                    width,
                    height,
                    selectable: false
                });
                //@ts-ignore
                img.onRender = async ({frameNumber, seconds}) => {
                    return new Promise(async (resolve) => {
                        img.sendToBack();
                        console.log("render", frameNumber, seconds + startTime, " - ", seconds)

                        const src = await parseFrameFromVideo(seconds + startTime);

                        // console.log("src", src)
                        img.setSrc(src, async () => {
                            // console.log("image loaded")
                            // await sleep(500)
                            resolve(src);
                        })


                    });
                }
                img.sendToBack();
                img.set('hoverCursor', 'default');
                resolve(img)
            })
        });

        const onRenderCompleted = function (e: any) {
            const {seconds, frameNumber} = e.detail;
            const currentTime = seconds + startTime;
            // const fps = clip.fps || 30;
            // if (frameNumber >= Math.floor(clip.duration * fps - 1)) {
            // if (frameNumber >= Math.floor(clip.duration * fps)) {

            if (currentTime >= endTime) {
                console.log("stop render at frame", frameNumber, "seconds", seconds);
                canvasRef.current?.removeEventListener("render", onRenderCompleted)
                stopRender();
            }
        }
        canvasRef.current?.addEventListener("render", onRenderCompleted);
    };

    const getFrames = async (clip: any, startTime: number, endTime: number, onProgress?: any) => {
        await initCanvasEffects(clip, startTime, endTime);
        return await processVideo(onProgress);
    };

    return {
        getFrames,
        canvasRef
    };
};

export const getSubtitleAccordingToCurrentTime = (clip: any, currentTime: number) => {
    return clip.captions.find(
        (c: any) => c.startTime < currentTime && c.endTime > currentTime
    );
};

// render 0 0.8  -  0
// render 1 0.8333333333333334  -  0.03333333333333333
// render 2 0.8666666666666667  -  0.06666666666666667
// render 3 0.9  -  0.1
// render 4 0.9333333333333333  -  0.13333333333333333
// render 5 0.9666666666666668  -  0.16666666666666669