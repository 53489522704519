import React from "react"
import {
    FormInputText,
    FormGlobalSuccessMessage,
    FormGlobalErrorMessage,
    ButtonFormSubmit,
    withForm,
    FormTextarea
} from "front-lib";

interface IProps {
    notification: any;
    onSubmit?: (formData?: any) => Promise<void>;
    onCancel?: () => void
}

const NotificationForm = ({notification, onSubmit, onCancel}: IProps) => {
    // const {t} = useLanguage();

    return (
        <>
            <div className="space-y-6 px-4 py-5 sm:p-6">

                <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-6">
                        <FormInputText label={"Type"} name={"type"}
                                       defaultValue={notification.type}/>
                    </div>


                    <div className="col-span-6 sm:col-span-3">
                        <FormTextarea label={"Message"} name={"message"} defaultValue={notification.message}/>
                    </div>

                    <div className="col-span-6">
                        <FormGlobalErrorMessage/>
                        <FormGlobalSuccessMessage/>
                    </div>
                </div>


            </div>

            <div
                className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">

                <ButtonFormSubmit onSubmit={onSubmit}/>
                <button type="button"
                        onClick={onCancel}
                        className="text-gray-500 bg-white hover:bg-gray-100  rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-950 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                    Cancel
                </button>
            </div>
        </>);

};

export default withForm(NotificationForm)
