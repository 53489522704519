import React from "react"
import {useAi} from "../../engine/useAi";
import {useForm} from "front-lib";

const ButtonTranscribeClip = ({clip}: any) => {
    const {handleSubmit, setValue} = useForm();

    const {transcribeClip} = useAi();

    return (
        <>
            <button type="button"
                    onClick={async () => {
                        const clipScriptModel = await transcribeClip(clip.id)
                        console.log("clipScriptModel", clipScriptModel)
                        const captions = clipScriptModel.captions;
                        setValue("captions", captions, {shouldDirty: true})
                        const words = clipScriptModel.words;
                        setValue("words", words, {shouldDirty: true})
                        // handleSubmit(async () => {
                        //     const formData: any = {dirtyValues: {captions, words}}
                        //     // await onSubmit!(formData)
                        // })
                    }}
                    className={`px-3 py-2 text-xs font-medium text-center text-white bg-primary-200 rounded-lg hover:bg-primary-300  dark:bg-primary-600 dark:hover:bg-primary-700 `}>
                Generate AI Captions
            </button>
        </>
    )
}

export {ButtonTranscribeClip}