import React, {useEffect, useState} from "react"
import {DashboardStats} from "./DashboardStats";
import ClipsTable from "./ClipsTable";
import {api, LoadingCover} from "front-lib";
import {MyFirstEnvBanner} from "./banners/MyFirstEnvBanner";
import {WelcomeBanner} from "./banners/WelcomeBanner";
import {useWorkspace} from "../workspace/context/useWorkspace";

const Dashboard = () => {
    // const [myClips, setMyClips] = useState<any[] | null>(null);
    // const [isLoading, setIsLoading] = useState(true);
    const {clips} = useWorkspace();
    // const loadMyClips = async () => {
    //     const response = await api.get(`/workspace/clips`)
    //     setMyClips(response.data)
    //     setIsLoading(false)
    // }
    // useEffect(() => {
    //     loadMyClips();
    // }, []);
    return (
        <>
            {/*{isLoading && <div className={'py-10'}>*/}
            {/*    <LoadingCover isLoading={true}/>*/}
            {/*</div>}*/}
            {/*{!isLoading && <>*/}
                <div className="mx-auto max-w-screen-xl w-full pb-20">
                    <div className="grid grid-cols-12 gap-3">
                        {/*<div className={'col-span-12'}>*/}
                        {/*    /!*<MyFirstEnvBanner/>*!/*/}
                        {/*    <WelcomeBanner/>*/}
                        {/*</div>*/}
                        <div className={'col-span-12'}>
                            <header className="">
                                <div className="mx-auto pt-4">
                                    <h1 className="text-3xl font-bold tracking-tight text-gray-950 dark:text-white">
                                        Dashboard</h1>
                                </div>
                            </header>
                        </div>
                        <div className={'col-span-12'}>
                            <DashboardStats/>
                        </div>

                        <div className={'col-span-12'}>
                            <ClipsTable clips={clips}/>
                        </div>
                    </div>
                </div>
            {/*</>}*/}
        </>
    )
}

export {Dashboard}
