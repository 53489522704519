import {api} from "front-lib"

export const useAi = () => {
    const dubClip = async (clipId: string) => {
        console.log("dubClip")
        const response = await api.put(`/workspace/ai/dub/${clipId}`)
        console.log("response", response.data)
        return response.data;
    }

    const transcribeClip = async (clipId: string) => {
        console.log("transcribeClip")
        const response = await api.put(`/workspace/ai/script/${clipId}`)
        console.log("response", response.data)
        return response.data;
    }


    return {
        transcribeClip,
        dubClip
    }
}
