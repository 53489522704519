import React from 'react';
import {Helmet} from 'react-helmet-async';

function CareersRoute() {
    return (
        <>
            <Helmet>
                <title>Careers</title>
            </Helmet>
            <section className={'!pt-6'}>
                <div className={'max-w-screen-xl mx-auto px-6'}>

                    <div className={'grid grid-cols-6 gap-6'}>

                        <div className={'col-span-6 lg:col-span-5'}>
                            <h2 className="mb-2 text-4xl tracking-tight font-extrabold text-gray-950 dark:text-white">
                                Careers
                            </h2>
                            <p className={'px-1'}>
                                At Clipz, we are passionate about revolutionizing the way organizations leverage AI
                                technology. We empower teams to optimize their workflows,
                                enhance communication, and unlock the full potential of AI models. Our user-friendly
                                interface and intuitive tools make it easy for users to interact with AI models,
                                providing efficient and accurate responses. With our contextual helper and collaboration
                                features, teams can seamlessly collaborate, share insights, and drive collective
                                intelligence.


                            </p>
                        </div>
                        <div
                            className="bg-gray-100   dark:bg-gray-800 dark:border-gray-700 col-span-6 lg:col-span-5  mb-12">

                            <div className="p-5">
                                <h5 className="mb-5 text-3xl font-bold tracking-tight text-gray-950 dark:text-white">
                                    Co-Founder, CEO/CMO
                                </h5>
                                <div className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                                    We are seeking a dynamic and accomplished individual to join us as a Co-Founder,
                                    CEO/CMO, who will hold a crucial position in driving the success and expansion of
                                    Clipz. In this role, you will assume leadership responsibilities, overseeing
                                    the company while spearheading the development and execution of our marketing
                                    strategy.   you will actively shape the overall vision, strategy,
                                    and trajectory of Clipz, while simultaneously taking charge of marketing
                                    initiatives to optimize brand recognition, customer acquisition, and revenue growth.
                                    <br/>
                                    <br/>

                                </div>
                                <div className={'mt-10'}>

                                    <h3 className={'text-lg mb-2 font-semibold'}> Key Responsibilities:</h3>


                                    <ul className="list-disc pl-5">
                                        <li className={"mb-2"}> Building and maintaining a strong brand and engaged
                                            community around Clipz
                                        </li>
                                        <li className={"mb-2"}>Creating and implementing marketing and
                                            community-building
                                            strategies
                                        </li>
                                        <li className={"mb-2"}>Moderating online forums and social media channels</li>
                                        <li className={"mb-2"}>Responding to customer inquiries and feedback</li>

                                        <li className={"mb-2"}>Collaborating with the executive team and other
                                            co-founders
                                            to ensure that all marketing and community-building efforts align with the
                                            company's overall goals and objectives.
                                        </li>
                                        <li className={"mb-2"}>Analyzing market trends, community data, and metrics to
                                            inform marketing and community-building decisions and measure the
                                            effectiveness
                                            of efforts.
                                        </li>
                                    </ul>
                                    <a href={`mailto:donoslide@gmail.com?subject=Apply for Chief Marketing Officer`}
                                       className="inline-flex items-center px-3 py-2 mt-4  text-sm font-medium text-center text-white bg-primary-700 rounded-lg hover:bg-primary-800  dark:bg-primary-600 dark:hover:bg-primary-700 ">
                                        Apply Now
                                        <svg className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    );
}

export default CareersRoute;
