export class StreamRecorder {

    mediaRecorder?: MediaRecorder | undefined | null
    blobRecords: BlobPart [] = []
    stream?: MediaStream | undefined | null

    constructor() {
    }


    startRecording(stream: MediaStream) {
        this.stream = stream;
        this.mediaRecorder = new MediaRecorder(this.stream,) //{mimeType: 'video/webm; codecs=opus'}
        this.mediaRecorder.ondataavailable = (event: any) => {
            this.blobRecords.push(event.data);
        };
        this.mediaRecorder.start()

    }

    stopRecording(type: string): Promise<{ url: string, blob: Blob }> {
        return new Promise((resolve) => {
            if (this.mediaRecorder) {
                this.mediaRecorder.addEventListener('stop', () => {
                    const blob = new Blob(this.blobRecords, {type})
                    const url = URL.createObjectURL(blob);
                    this.blobRecords = []
                    resolve({url, blob})
                });
                this.mediaRecorder.stop()
                this.mediaRecorder = undefined
            }
            if (this.stream) {
                this.stream.getTracks().forEach(function (track: MediaStreamTrack) {
                    track.stop();
                });
                this.stream = undefined
            }
        })
    }

}
