import React from "react";
import {Helmet} from "react-helmet-async";
import WorkspaceView from "../../../../modules/app/workspace/WorkspaceView";
import {ComingSoonView} from "../../../../modules/app/clip-editor/tabs/TabsClipEditor";

const MyWorkspaceRoute = ({}) => {
    return (
        <>
            <Helmet>
                <title>My Workspace</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl py-5'}>
                {/*<WorkspaceView/>*/}
                <ComingSoonView/>
            </div>
        </>
    )
}

export default MyWorkspaceRoute