import React from 'react';
import {Helmet} from 'react-helmet-async';
import {SpotlightView} from "../../../../modules/app/spotlight/SpotlightView";
import {useNavigate} from "react-router-dom";
import {AlertModern} from "front-lib";

function SpotlightRoute() {
    const navigate = useNavigate();

    const onCompleted = (blob: Blob) => {
        console.log("onCompleted", blob);
        const blobUrl = URL.createObjectURL(blob);
        navigate(`/app/clips/create?url=${encodeURIComponent(blobUrl)}`)
    }
    return (
        <>
            <header className="">
                <div className="mx-auto pt-4 flex justify-between items-center">
                    <div>
                        <h1 className="text-3xl font-bold tracking-tight text-gray-950 dark:text-white">
                            Spotlight
                        </h1>
                    </div>

                </div>
            </header>
            <div className={'my-2'}>
                <AlertModern text={'Spotlight, our recording studio is on the way, stay tuned for early access.'}/>
            </div>
            <SpotlightView onCompleted={onCompleted}/>
        </>
    );
}

export default SpotlightRoute;
