import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {useParams} from "react-router-dom";
import {api, LoadingCover, Role, useForm, useFormChange, VisibleByRole, withForm} from "front-lib"
import {ExportDropdown} from "../../../../modules/app/clip-editor/export/ExportDropdown";
import {ClipEditor} from "../../../../modules/app/clip-editor/ClipEditor";
import {useWorkspaceApi} from "../../../../modules/app/workspace/context/useWorkspaceApi";


const ClipEditorRoute = ({}) => {

    const {loadClipById, editClip} = useWorkspaceApi();
    const {clipId} = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [clip, setClip] = useState<any | null>(null);
    const {handleSubmit} = useForm()
    const breadcrumbs = [
        {text: "Dashboard", to: "/app"},
        {text: clip ? clip.id : 'Clip'}
    ]
    const loadClipAndSaveState = async () => {
        if (!clipId) {
            throw new Error("Clip Id must be provided")
        }
        try {
            setIsLoading(true);
            console.log("loadClip", clipId)
            const clipDb = await loadClipById(clipId)
            console.log("clip loaded:", clipDb)
            setClip(clipDb)
        } catch (err) {

        } finally {
            setIsLoading(false)
        }

    }

    const editClipAndSetState = async (clipToUpdate: any) => {
        console.log("editClip", clipToUpdate);
        if (!clipToUpdate) return;
        setClip((c: any) => {
            return {...c, ...clipToUpdate}
        })
        const clipDb = await editClip(clip.id, clipToUpdate)

    }
    const onExportCompleted = (clip: any) => {
        console.log("onExportCompleted", clip)
        if (clip) {
            setClip(clip);
        }
    }
    useFormChange(() => {
        console.log("form changed")
        handleSubmit(async ({dirtyValues}: any) => {
            console.log("dirtyValues", dirtyValues)
            await editClipAndSetState(dirtyValues);
        });
    });

    useEffect(() => {
        loadClipAndSaveState();
    }, [clipId]);

    return (
        <>
            <Helmet>
                <title>Clipz - Clip Editor</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl'}>
                {isLoading && <div className={'py-10'}>
                    <LoadingCover isLoading={isLoading}/>
                </div>}
                {!isLoading && <>
                    <header className="">
                        <div className="mx-auto pt-4 flex justify-between items-center">
                            <div>
                                <h1 className="text-3xl font-bold tracking-tight text-gray-950 dark:text-white">
                                    Clip Editor
                                </h1>
                                {/*<Link to={"/app"}*/}
                                {/*      className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-950 dark:text-gray-400 dark:hover:text-white">*/}
                                {/*   <BiArrowBack className="w-4 h-4 mr-2"/>*/}
                                {/*    My clips*/}
                                {/*</Link>*/}
                            </div>
                            <div className={''}>
                                <ExportDropdown clip={clip} onExportCompleted={onExportCompleted}/>
                            </div>
                        </div>
                    </header>


                    <div className={'bg-gray-100 dark:bg-gray-800 mt-3'}>
                        <ClipEditor clip={clip}/>
                        {/*<div className="pt-[100px] pb-5 hidden md:block">*/}
                        {/*    <div className="border-t border-gray-200 dark:border-gray-700"></div>*/}
                        {/*</div>*/}

                        {/*<div className="md:grid md:grid-cols-3 md:gap-6">*/}
                        {/*    <div className="md:col-span-1 hidden md:block">*/}
                        {/*        <div className="px-4 sm:px-0">*/}
                        {/*            <h3 className="text-lg font-medium leading-6 text-gray-950 dark:text-gray-300">*/}
                        {/*                Voice Over</h3>*/}
                        {/*            <p className="mt-1 text-sm text-gray-600 dark:text-gray-500">*/}
                        {/*                Custom voice for your clip</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="mt-5 md:col-span-2 md:mt-0">*/}
                        {/*        <div className="shadow sm:overflow-hidden sm:rounded-md">*/}
                        {/*            <ClipVoiceOverForm clip={clip}/>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="pt-[100px] pb-5 hidden md:block">*/}
                        {/*    <div className="border-t border-gray-200 dark:border-gray-700"></div>*/}
                        {/*</div>*/}
                        {/*<div className="md:grid md:grid-cols-3 md:gap-6">*/}
                        {/*    <div className="md:col-span-1 hidden md:block">*/}
                        {/*        <div className="px-4 sm:px-0">*/}
                        {/*            <h3 className="text-lg font-medium leading-6 text-gray-950 dark:text-gray-300">*/}
                        {/*                Captions Style</h3>*/}
                        {/*            <p className="mt-1 text-sm text-gray-600 dark:text-gray-500">*/}
                        {/*                Style configs of clip's captions</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="mt-5 md:col-span-2 md:mt-0">*/}
                        {/*        <div className="shadow sm:overflow-hidden sm:rounded-md">*/}
                        {/*            {clip && <CaptionsStyleForm clip={clip}/>}*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="pt-[100px] pb-5 hidden md:block">*/}
                        {/*    <div className="border-t border-gray-200 dark:border-gray-700"></div>*/}
                        {/*</div>*/}
                        {/*<div className="md:grid md:grid-cols-3 md:gap-6">*/}
                        {/*    <div className="md:col-span-1 hidden md:block">*/}
                        {/*        <div className="px-4 sm:px-0">*/}
                        {/*            <h3 className="text-lg font-medium leading-6 text-gray-950 dark:text-gray-300">Clip*/}
                        {/*                Details</h3>*/}
                        {/*            <p className="mt-1 text-sm text-gray-600 dark:text-gray-500">*/}
                        {/*                Information about your clip</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="mt-5 md:col-span-2 md:mt-0">*/}
                        {/*        <div className="shadow sm:overflow-hidden sm:rounded-md">*/}
                        {/*            <ClipDetailsForm clip={clip}/>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="pt-[100px] pb-5 hidden md:block">*/}
                        {/*    <div className="border-t border-gray-200 dark:border-gray-700"></div>*/}
                        {/*</div>*/}
                    </div>
                </>}

            </div>
            <VisibleByRole role={Role.Developer}>
                <>
                    {clip && <div className={'mx-auto max-w-screen-xl'}>
                        {/*<div className={'mt-6  bg-white dark:bg-gray-950 h-[600px]'}>*/}
                        {/*    <div id={"dev-stages"} className={'w-full h-full'}/>*/}
                        {/*</div>*/}
                        <div className={'mt-6  bg-white dark:bg-gray-950'}>
                             <pre className={'p-2'}>
                                  {JSON.stringify(clip, null, 2)}
                            </pre>
                        </div>
                        <div className={'h-[100px] w-full'}/>
                    </div>}
                </>
            </VisibleByRole>



        </>
    )
}

export default withForm(ClipEditorRoute)