import React from "react";
import {Popover, Role, useUser, VisibleByRole} from "front-lib"
import {Link, useLocation} from "react-router-dom";
import {
    AiOutlineAudit,
    BsPalette,
    CgDisplayGrid,
    FaBug,
    FaCog, FaPhotoVideo,
    FaRegFlag, FaStore,
    FaTicketAlt,
    FaUpload,
    FaUser, FaUsers,
    FiFile,
    HiTranslate, MdDashboard, MdGamepad, MdHomeWork, MdMemory,
    MdWorkspaces,
    SiStorybook
} from "react-icons/all";
import {FaVideo} from "react-icons/fa";

const AdminDropdown = ({}) => {

    const {user} = useUser();
    const {profile} = user;
    return (
        <>
            <VisibleByRole role={Role.Admin}>
                <Popover
                    className={'absolute top-[40px] right-[70px]  overflow-hidden z-50 my-4 max-w-sm text-base list-none bg-white rounded divide-y divide-gray-100 shadow-lg dark:bg-gray-700 dark:divide-gray-600'}
                    renderTrigger={<button type="button" data-dropdown-toggle="apps-dropdown"
                                           className="p-2 text-gray-500 rounded-lg hover:text-gray-950 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600">
                        <span className="sr-only">Admin Options</span>
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
                        </svg>
                    </button>}
                    renderContent={({toggleOpen}: any) => {
                        // @ts-ignore
                        return <div onClick={toggleOpen}>
                            <div
                                className="block py-2 px-4 text-base font-medium text-center text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                Admin Options
                            </div>
                            <div className="grid grid-cols-3 gap-2 p-2">
                                {/*<MenuLink to={"/app/admin/dashboard"}>*/}
                                {/*    <MdDashboard*/}
                                {/*        className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"/>*/}
                                {/*    <div className="text-sm text-gray-950 dark:text-white">Dashboard</div>*/}
                                {/*</MenuLink>*/}

                                <MenuLink to={"/app/admin/clips"}>
                                    <FaVideo
                                        className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"/>
                                    <div className="text-sm text-gray-950 dark:text-white">Clips</div>
                                </MenuLink>
                                <MenuLink to={"/app/admin/users"}>
                                    <FaUsers
                                        className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"/>
                                    <div className="text-sm text-gray-950 dark:text-white">Users</div>
                                </MenuLink>

                                <MenuLink to={"/app/admin/audit"}>
                                    <AiOutlineAudit
                                        className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"/>
                                    <div className="text-sm text-gray-950 dark:text-white">Audit Log</div>
                                </MenuLink>
                                <MenuLink to={"/app/admin/tickets"}>
                                    <FaTicketAlt
                                        className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"/>
                                    <div className="text-sm text-gray-950 dark:text-white">Tickets</div>
                                </MenuLink>

                                <MenuLink to={"/app/admin/workspaces"}>
                                    <MdWorkspaces
                                        className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"/>
                                    <div className="text-sm text-gray-950 dark:text-white">Workspaces</div>
                                </MenuLink>

                                {/*<MenuLink to={"/app/admin/jobs"}>*/}
                                {/*    <MdHomeWork*/}
                                {/*        className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"/>*/}
                                {/*    <div className="text-sm text-gray-950 dark:text-white">Jobs</div>*/}
                                {/*</MenuLink>*/}
                                <MenuLink to={"/app/admin/system"}>
                                    <FaCog
                                        className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"/>
                                    <div className="text-sm text-gray-950 dark:text-white">Settings</div>
                                </MenuLink>
                                {/*<MenuLink to={"/app/dev/cache"}>*/}
                                {/*    <MdMemory*/}
                                {/*        className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"/>*/}
                                {/*    <div className="text-sm text-gray-950 dark:text-white">Cache</div>*/}
                                {/*</MenuLink>*/}
                                <MenuLink to={"/app/dev/playground"}>
                                    <MdGamepad
                                        className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"/>
                                    <div className="text-sm text-gray-950 dark:text-white">Playground</div>
                                </MenuLink>

                                <MenuLink to={"/app/dev"}>
                                    <FaBug
                                        className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"/>
                                    <div className="text-sm text-gray-950 dark:text-white">DevTools</div>
                                </MenuLink>
                                <MenuLink to={"/app/dev/colors"}>
                                    <BsPalette
                                        className="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"/>
                                    <div className="text-sm text-gray-950 dark:text-white">Colors</div>
                                </MenuLink>
                            </div>
                        </div>
                    }}
                />
            </VisibleByRole>
        </>
    )
}

const MenuLink = ({to, children}: any) => {
    const {pathname} = useLocation();

    const getActiveClass = () => {
        if (to === pathname)
            return "bg-gray-100 dark:bg-gray-700"
        return ""
    }
    return <Link to={to}
                 className={`block py-4 px-2 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group ${getActiveClass()}`}>
        {children}
    </Link>
}

export default AdminDropdown
