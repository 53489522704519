import React from "react";
import {useForm} from "../context/useForm";
import {Controller} from "react-hook-form";

const FormInputHidden = ({
                             name,
                             defaultValue = "",
                             rules,
                             className,
                         }: any) => {
    const {control} = useForm();

    return (
        <div>
            <Controller
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                name={name}
                render={({field}: any) => (
                    <>
                        <input type={"hidden"}
                               id={name}
                               {...field}
                               className={'hidden'}/>
                    </>
                )}
            />

        </div>
    );
};

export {FormInputHidden};
