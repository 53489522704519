import React from "react"
import {DialogBasic} from "front-lib";
import ClipCreationWizard from "../../clip-editor/ClipCreationWizard";

const DialogCreateClip = ({onCreated}: any) => {
    // const {loadEnvsAndBuildsData} = useWorkspace();
    return (
        <>
            <DialogBasic
                title={"Create Clip"}
                renderTrigger={<button
                    className={`text-white bg-primary-200 hover:bg-primary-300  font-medium rounded-lg text-sm px-3 py-1.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 `}>
                    Create Clip
                </button>}
                renderContent={({toggleOpen}: any) => {
                    return <>
                        <ClipCreationWizard onCompleted={async () => {
                            // await loadEnvsAndBuildsData();
                            toggleOpen()
                        }}/>
                    </>
                }}
            />
        </>
    )
}

export default DialogCreateClip