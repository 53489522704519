import React from "react";
import FaqOpenList from "./FaqOpenList";


const data = [
    {
        title: "What is Clipz?",
        content: <>
            Clipz is a platform designed for AI tool enthusiasts that provide efficient interactions between you and
            your models. Using our contextual helper and team collaborations tools it becomes easier to communicate
            between Your Ai models, your team , and yourself
        </>
    },
    {
        title: "How does Clipz work?",
        content: <div>
            Clipz is an innovative platform that works by integrating with the vendors APIs to facilitate seamless
            interactions with AI models.
            <br/>
            Throughout the process, Clipz leverages the power of AI and OpenAI's technology to make your interactions
            with AI models intuitive, efficient, and effective.
        </div>,
    },

    {
        title: "Is Clipz free to use?",
        content: <div>

            Clipz is currently available for free usage. we also  working on a paid plans with enhanced features.

            {/*To access the platform, simply provide your OpenAI API token,*/}
            {/*which will be used for all requests associated with your account. For details regarding OpenAI pricing*/}
            {/*plans,&nbsp;*/}
            {/*<a target={"_blank"} href={'https://openai.com/pricing'}*/}
            {/*   className={'underline'}>click here</a>.*/}
        </div>,
    },
    {
        title: "What Ai Models you support?",
        content: <div>
            At the moment, we support OpenAi ChatGPT models.
            <br/>
            We're also working on an additional models like Dall-E.
        </div>,
    },
];


const HomeFaqSection = ({}) => {
    return (
        <section className={'bg-gray-100'}>

            <div className={"max-w-screen-md lg:max-w-screen-xl mx-auto py-[100px] px-6 "}>
                {/*<Faq*/}
                {/*    data={data}*/}
                {/*/>*/}
                <FaqOpenList data={data}/>
                {/*<p className="text-center mt-2 text-gray-500">Can't find the answer you're looking for? Don't be a stranger!<br/>*/}
                {/*    Come find us on <a target="_blank" href="https://discord.gg/5HPyCaF742" rel="noreferrer"*/}
                {/*                       className={'text-discord'}>Discord</a>.*/}
                {/*</p>*/}
            </div>
        </section>
    )
}

export default HomeFaqSection