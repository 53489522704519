import {useContext} from "react";
import {ExportsContext} from "./context";

export const useExportsContext = () => {
    const context = useContext(ExportsContext);
    if (context === undefined) {
        throw new Error("useExportsContext must be used within ExportsContext");
    }
    return context;
}

