import React from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    AiFillTool, AiOutlineEdit, BsRecordCircle,
    FaLock, IoShareSocial, MdWorkspaces, RiBearSmileLine, SiSpotlight,
} from "react-icons/all";
import {Popover, Avatar, useUser, useAuthContext} from "front-lib"
import DialogContactUs from "../../site/contact-us/dialogs/DialogContactUs";
import {BiDirections} from "react-icons/bi";
import {MdGroups} from "react-icons/md";

const MyAccountDropdown = ({}) => {
    const {user} = useUser();
    const {logoutAndRemoveMyProfile} = useAuthContext();
    const navigate = useNavigate();

    const doLogout = () => {
        logoutAndRemoveMyProfile();
        navigate("/login");
    }

    return (
        <>
            <Popover
                className={'absolute top-[40px] right-6 z-50 my-4 w-56 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600'}
                renderTrigger={<button type="button"
                                       className="flex mx-3 text-sm bg-gray-800 rounded-full mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                                       id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
                    <span className="sr-only">Open user menu</span>
                    <Avatar url={user.profile.avatar} className={'w-10 h-10 '}/>
                </button>}
                renderContent={({toggleOpen}: any) => {
                    return <div onClick={toggleOpen}>
                        <div className="py-3 px-4">
                            <span
                                className="block text-sm font-semibold text-gray-950 dark:text-white">
                                {user.profile.firstname} {user.profile.lastname}
                                {/*{user.profile.username}*/}
                            </span>
                            <span
                                className="block text-sm font-light text-gray-500 truncate dark:text-gray-400">{user.email}</span>
                        </div>
                        {/*<ul className="py-1 font-light text-gray-500 dark:text-gray-400 border-t-2 border-gray-100">*/}
                        {/*    <li className={'mb-0'}>*/}
                        {/*        <DropdownLink to={`/${user.profile.username}`}*/}
                        {/*                      icon={<FaPhotoVideo className={'mr-2 w-5 h-5'}/>}*/}
                        {/*                      target={"_blank"}*/}
                        {/*                      text={"Public Portfolio"}*/}
                        {/*        />*/}
                        {/*    </li>*/}
                        {/*</ul>*/}

                        <ul className="py-1 font-light text-gray-500 dark:text-gray-400 border-t-2 border-gray-100"
                            aria-labelledby="dropdown">


                            <li className={'mb-0'}>
                                <DropdownLink to={"/app"}
                                              icon={<BiDirections
                                                  className={'mr-2 w-5 h-5'}/>}
                                              text={"Home"}/>

                            </li>

                            <li className={'mb-0'}>
                                <DropdownLink to={"/app/socials"}
                                              icon={<IoShareSocial
                                                  className={'mr-2 w-5 h-5'}/>}
                                              text={"Socials"}/>
                            </li>

                            <li className={'mb-0'}>
                                <DropdownLink to={"/app/spotlight"}
                                              icon={<BsRecordCircle
                                                  className={'mr-2 w-5 h-5'}/>}
                                              text={"Spotlight"}/>
                            </li>


                            {/*<li className={'mb-0'}>*/}
                            {/*    <DropdownLink to={`/app/workspace`}*/}
                            {/*                  icon={<MdWorkspaces className={'mr-2 w-5 h-5'}/>}*/}
                            {/*                  text={"My Workspace"}*/}
                            {/*    />*/}
                            {/*</li>*/}

                            <li className={'mb-0'}>
                                <DropdownLink to={`/app/profile`}
                                              icon={<FaLock className={'mr-2 w-5 h-5'}/>}
                                              text={"Account"}
                                />
                            </li>


                            {/*<li className={'mb-0'}>*/}
                            {/*    <DropdownLink to={"/app/settings"}*/}
                            {/*                  icon={<FaCog className={'mr-2'}/>}*/}
                            {/*                  text={"Settings"}*/}
                            {/*    />*/}
                            {/*</li>*/}
                        </ul>

                        <ul className="py-1 font-light text-gray-500 dark:text-gray-400 border-t-2 border-gray-100 ">

                            <li className={'mb-0'}>
                                <DropdownLink to={"/app/referrals"}
                                              icon={<MdGroups className={'mr-2 w-5 h-5'}/>}
                                              text={"Invite "}
                                />
                            </li>
                            <li className={'mb-0 '} onClick={e => e.stopPropagation()}>
                                <DialogContactUs renderTrigger={<DropdownButton
                                    icon={<AiFillTool className={'mr-2 w-5 h-5'}/>}
                                    text={"Support"}
                                />} source={'app-dropdown'}/>
                            </li>
                        </ul>
                        {/*<ul className="py-1 font-light text-gray-500 dark:text-gray-400">*/
                        }
                        {/*    <li>*/
                        }
                        {/*        <Link to={"/app/dev"}*/
                        }
                        {/*              className="flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">*/
                        }
                        {/*            <FaBug className={'mr-2'}/>*/
                        }
                        {/*            Dev Tools</Link>*/
                        }
                        {/*    </li>*/
                        }

                        {/*    <li>*/
                        }
                        {/*        <Link to={"/app/dev/playground"}*/
                        }
                        {/*              className="flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">*/
                        }
                        {/*            <FaGamepad className={'mr-2'}/>*/
                        }
                        {/*            Playground</Link>*/
                        }
                        {/*    </li>*/
                        }
                        {/*    <li>*/
                        }
                        {/*        <Link to={"/app/dev/story"}*/
                        }
                        {/*              className="flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">*/
                        }
                        {/*            <SiStorybook className={'mr-2'}/>*/
                        }
                        {/*            StoryBook</Link>*/
                        }
                        {/*    </li>*/
                        }
                        {/*</ul>*/
                        }
                        <ul className="py-1 font-light text-gray-500 dark:text-gray-400"
                            aria-labelledby="dropdown">
                            <li className={'mb-0'}>

                                <a
                                    onClick={doLogout}
                                    className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">Sign
                                    out</a>
                            </li>
                        </ul>
                    </div>
                }}
            />
        </>
    )
}
const DropdownLink = ({to, text, icon, target}: any) => {
    const {pathname} = useLocation();

    const getActiveClass = () => {
        if (to === pathname)
            return "bg-gray-100 dark:bg-gray-700"
        return ""
    }
    return <Link to={to}
                 target={target}
                 className={`flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${getActiveClass()} `}>
        {icon}
        {text}
    </Link>
}

const DropdownButton = ({onClick, text, icon}: any) => {
    const {pathname} = useLocation();

    const getActiveClass = () => {
        // if (to === pathname)
        //     return "bg-gray-100 dark:bg-gray-700"
        return ""
    }
    return <div onClick={onClick}
                className={`flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer ${getActiveClass()} `}>
        {icon}
        {text}
    </div>
}
export default MyAccountDropdown