import React from "react"
import {MediaDevice} from "./MediaDevice";
import {StreamRecorder} from "./StreamRecorder";


const mediaDevice = new MediaDevice("videoinput");
const streamRecorder = new StreamRecorder();


interface IMediaRecorderProps {
    constraints: MediaStreamConstraints
}

const useMediaRecorder = ({constraints}: IMediaRecorderProps) => {

    const [outputUrl, setOutputUrl] = React.useState<any>(null)

    const inputRecordingRef = React.useRef<HTMLVideoElement | null>(null)
    const [error, setError] = React.useState<any>(null)
    const [blob, setBlob] = React.useState<Blob | null>(null)
    const [isCompletedRecording, setIsCompletedRecording] = React.useState(false)
    const [currentCameraIndex, setCurrentCameraIndex] = React.useState<number>(0)
    const [isOpeningCamera, setIsOpeningCamera] = React.useState(true)
    const [isRecording, setIsRecording] = React.useState(false)

    const startRecording = () => {
        console.log("startRecording")
        if (mediaDevice.stream) {
            console.log("startRecording with stream ready")
            setIsCompletedRecording(false)
            streamRecorder.startRecording(mediaDevice.stream);
            setIsRecording(true)
        }
    }
    const stopRecording = async (type: string): Promise<Blob> => {
        const response = await streamRecorder.stopRecording(type);
        await mediaDevice.close();
        console.log("stop recording")
        setBlob(response.blob);
        // SetPreviewUrl(response.url);
        setOutputUrl(response.url)
        setIsRecording(false)
        setIsCompletedRecording(true)

        return response.blob;
    }
    const openDevice = async () => {
        try {
            setIsOpeningCamera(true);
            await mediaDevice.open(constraints);

            if (inputRecordingRef && inputRecordingRef.current) {
                inputRecordingRef.current.srcObject = mediaDevice.stream
                console.log("set mediaDevice stream")
            }

        } catch (err: any) {
            console.error(`error opening camera  `, err.name);
            const type = err.name || "UNKNOWN_CAMERA_ISSUE"
            const error = {
                type,
                message: (() => {
                    if (type === 'NotFoundError') {
                        return 'Camera device not found'
                    }
                    if (type === 'NotAllowedError') {
                        return 'Please make sure to grant video access on the site.';
                    }
                    return <>
                        Something went wrong.<br/>Try refreshing the page, and make sure to grant video access on the
                        site.
                    </>
                })()
            }

            setError(error);
            throw err
        } finally {
            setIsOpeningCamera(false);
        }

    }

    const closeDevice = async () => {
        // console.log("closeDevice")
        if (mediaDevice.isOpen) {
            console.log("stop mediaDevice stream")
            await mediaDevice.close();
        }

    }
    const switchMediaDevice = async () => {
        await mediaDevice.switchMediaDevice(currentCameraIndex);
        if (mediaDevice.stream) {
            if (inputRecordingRef.current && mediaDevice.stream) {
                console.log("set new stream")
                inputRecordingRef.current.srcObject = mediaDevice.stream
            }
            setCurrentCameraIndex(currentCameraIndex + 1);
        }
    }

    const resetToInitialState = async () => {
        console.log("resetToInitialState");
        setBlob(null)
        setError(null)
        // setIsOpeningCamera(true);
        setIsCompletedRecording(false);
        setIsRecording(false)
        if (!mediaDevice.isOpen) {
            await openDevice();
        }
    }

    return {
        startRecording,
        stopRecording,
        openDevice,
        closeDevice,
        switchMediaDevice,
        resetToInitialState,
        isOpeningCamera,
        isCameraOpen: mediaDevice.isOpen,
        isCompletedRecording,
        error,
        isRecording,
        blob,
        inputRecordingRef,
        outputUrl,
        stream: mediaDevice.stream
    }

}

export default useMediaRecorder

