import React from 'react';
import {Helmet} from 'react-helmet-async';
import FeatureListSection from "../../../modules/site/features/FeatureListSection";
import HeroSection from "../../../modules/site/hero/HeroSection";
import MainFeatureSection from "../../../modules/site/features/MainFeatureSection";
import ContactUsSection from "../../../modules/site/contact-us/ContactUsSection";
import HomeFaqSection from "../../../modules/site/faq/HomeFaqSection";
import VendorListSection from "../../../modules/site/companies/VendorListSection";
import BlogSection from "../../../modules/site/blog/BlogSection";



function HomeRoute() {
    return (
        <>
            <Helmet>
                <title>Clipz - AI-Driven Video Editing</title>
            </Helmet>
            <HeroSection/>

            {/*<MainFeatureSection/>*/}

            {/*<FeatureListSection/>*/}
            {/*<LastFeatureSection/>*/}
            {/*<VendorListSection/>*/}

            {/*<ShortFeatureList/>*/}

            <HomeFaqSection/>
            {/*<BlogSection/>*/}
            {/*<PricingSection/>*/}

            {/*<OurTeamSection/>*/}

            <ContactUsSection/>
            {/*<NewsLetterSection/>*/}

        </>
    );
}

export default HomeRoute;
