import React from "react";
import MyAccountDropdown from "../dropdowns/MyAccountDropdown";
import BrandName from "../brand/BrandName";
import AdminDropdown from "../dropdowns/AdminDropdown";
import {useUser} from "front-lib";

const AppHeader = ({}) => {
    // console.log("AppHeader")
    const {user} = useUser();
    const profile: any = user ? user.profile : undefined;
    return (
        <>
            <header
                className={'fixed w-full top-0 z-50 bg-white border-b border-gray-200 dark:bg-gray-950 dark:border-gray-800 h-[60px]'}>
                <nav className="px-4 py-2.5">
                    <div className="flex flex-wrap justify-between items-center">
                        <div className="flex justify-start items-center">
                            {/*<NavigationDrawer/>*/}
                            <BrandName to={"/app"}/>
                        </div>
                        <div className="flex items-center lg:order-2">
                            {/*<Link to={"/app/questionnaire"}*/}
                            {/*      className="text-white ml-5 mr-0 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5  dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none ">*/}
                            {/*    Share a sale win*/}
                            {/*</Link>*/}
                            <AdminDropdown/>
                            <MyAccountDropdown/>
                        </div>
                    </div>
                </nav>
            </header>

            {/*<header className={'fixed w-full z-40 bg-white dark:bg-black h-[60px]'}>*/}
            {/*    <nav className="border-gray-200 px-6 py-2.5 max-w-screen-xl mx-auto">*/}
            {/*        <div className="flex flex-wrap justify-between items-center w-full ">*/}
            {/*            <AdminNavigationDrawer/>*/}
            {/*            <BrandName/>*/}
            {/*            /!*<form action="#" method="GET" className="hidden lg:block lg:pl-2">*!/*/}
            {/*            /!*    <label htmlFor="topbar-search" className="sr-only">Search</label>*!/*/}
            {/*            /!*    <div className="relative mt-1 lg:w-96">*!/*/}
            {/*            /!*        <div*!/*/}
            {/*            /!*            className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">*!/*/}
            {/*            /!*            <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor"*!/*/}
            {/*            /!*                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*!/*/}
            {/*            /!*                <path fillRule="evenodd"*!/*/}
            {/*            /!*                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"*!/*/}
            {/*            /!*                      clipRule="evenodd"/>*!/*/}
            {/*            /!*            </svg>*!/*/}
            {/*            /!*        </div>*!/*/}
            {/*            /!*        <input type="text" name="email" id="topbar-search"*!/*/}
            {/*            /!*               className="bg-gray-50 border border-gray-300 text-gray-950 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"*!/*/}
            {/*            /!*               placeholder="Search"/>*!/*/}
            {/*            /!*    </div>*!/*/}
            {/*            /!*</form>*!/*/}
            {/*            <div className={'flex '}>*/}

            {/*                /!*<button type="button"*!/*/}
            {/*                /!*        className="hidden sm:inline-flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none ">*!/*/}
            {/*                /!*    <svg aria-hidden="true" className="mr-1 -ml-1 w-5 h-5" fill="currentColor"*!/*/}
            {/*                /!*         viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*!/*/}
            {/*                /!*        <path fillRule="evenodd"*!/*/}
            {/*                /!*              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"*!/*/}
            {/*                /!*              clipRule="evenodd"/>*!/*/}
            {/*                /!*    </svg>*!/*/}
            {/*                /!*    New Widget*!/*/}
            {/*                /!*</button>*!/*/}
            {/*                /!*<button id="toggleSidebarMobileSearch" type="button"*!/*/}
            {/*                /!*        className="p-2 text-gray-500 rounded-lg lg:hidden hover:text-gray-950 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">*!/*/}
            {/*                /!*    <span className="sr-only">Search</span>*!/*/}
            {/*                /!*    <svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"*!/*/}
            {/*                /!*         xmlns="http://www.w3.org/2000/svg">*!/*/}
            {/*                /!*        <path fillRule="evenodd"*!/*/}
            {/*                /!*              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"*!/*/}
            {/*                /!*              clipRule="evenodd"/>*!/*/}
            {/*                /!*    </svg>*!/*/}
            {/*                /!*</button>*!/*/}
            {/*                <DialogViewCart/>*/}
            {/*                {profile && <>*/}
            {/*                    /!*<MyNotificationsDropdown/>*!/*/}
            {/*                    <MyAppsDropdown/>*/}

            {/*                    <MyAccountDropdown/>*/}
            {/*                </>}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </nav>*/}
            {/*</header>*/}
        </>
    )
}

export default AppHeader
