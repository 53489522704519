import React from "react";
import {IColumn} from "../../table/context/GenericTableContext";
import {Avatar, LazyLabelDateTime} from "front-lib";
import LabelClipThumbnail from "../../../../app/dashboard/labels/LabelClipThumbnail";
import LabelClipResolution from "../../../../app/dashboard/labels/LabelClipResolution";
import LabelClipMeta from "../../../../app/dashboard/labels/LabelClipMeta";

export const clipColumns: IColumn[] = [
    {
        property: "id",
        label: "ID",
        isVisible: false,
        component: ({row, column}) => <>
            {row.id}
        </>
    },
    {
        property: "user",
        label: "User",
        isVisible: true,
        component: ({row, column}) => <div className={'flex items-center'}>
            <div className={'h-11 w-11'}>
                <Avatar url={row.profile.avatar}/>
            </div>
            <span className={'ml-2'}>{row.profile.firstname} {row.profile.lastname} </span>
        </div>
    },
    {
        property: "preview",
        label: "Preview",
        isVisible: true,
        component: ({row, column}) => <>
            <LabelClipThumbnail clip={row}/>
        </>
    },
    {
        property: "resolution",
        label: "Resolution",
        isVisible: true,
        component: ({row, column}) => <>
            <LabelClipResolution clip={row}/>
        </>
    },
    {
        property: "meta",
        label: "Meta",
        isVisible: true,
        component: ({row, column}) => <>
            <LabelClipMeta clip={row}/>
        </>
    },
    {
        property: "createdAt",
        label: "Created",
        isVisible: true,
        component: ({row, column}) => <>
            <LazyLabelDateTime timestamp={row.createdAt}/>
        </>
    },

];
