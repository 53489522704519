import React from "react";
import {downloadUrl} from "../export/useExport";

interface IProps {
    file: any
}

const LabelDownloadFile = ({file}: IProps) => {
    const {url} = file;
    const name = url.substring(url.lastIndexOf('/') + 1)
    return (
        <span className={'cursor-pointer text-primary-200 hover:underline'} onClick={() => {
            downloadUrl(url, name)
        }}>
             [Download File]
        </span>
    );
};

function getFileExtension(url: string) {
    //@ts-ignore
    return url.split(/[#?]/)[0].split('.').pop().trim();
}

export default LabelDownloadFile;
