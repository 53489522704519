import React from 'react'
import './recording-clock.scss'

interface IProps {
    defaultText?: string;
    timeLimit?: number;
    onTimeOver: () => void
}

const pad = (unit: any) => {
    const str = '' + unit
    const pad = '00'
    return pad.substring(0, pad.length - str.length) + str
}
const getState = (seconds: any) => {
    const minutes = Math.floor(seconds / 60)
    const humanTime =
        minutes !== 0
            ? `${minutes}:${pad(seconds - minutes * 60)}`
            : `${seconds - minutes * 60}s`

    return {
        seconds: seconds,
        human: humanTime
    }
}

function RecordingClock({defaultText = '0:00', timeLimit, onTimeOver}: IProps) {

    const nextSeconds = timeLimit || 0
    const state = getState(nextSeconds)
    const [time, setTime] = React.useState<{ seconds: number, human: string }>({
        seconds: state.seconds,
        human: state.human
    })


    React.useEffect(() => {
        const timerInterval = setInterval(() => {
            // console.log("bla")
            setTime((time) => {
                // console.log("time", time.seconds)

                if (time.seconds === 1) {
                    clearInterval(timerInterval)
                    onTimeOver();
                }

                const nextSeconds = timeLimit ? time.seconds - 1 : time.seconds + 1
                return getState(nextSeconds)

            })
        }, 1000)

        return () => {
            clearInterval(timerInterval)
        }

    }, [])


    return (
        <div className={'recorder-timer'}>
            <div className={'icon'}/>
            <span>{time.human || defaultText}</span>
        </div>
    )

}

// React.useEffect(() => {
//     let timeout: NodeJS.Timeout;
//     (async () => {
//         if (state.recording) {
//             await new Promise((resolve) => {
//                 timeout = setTimeout(resolve, maxRecodingDuration + 1000)
//             });
//             console.log("stop recording")
//             if (mediaDevice.mediaRecorder?.state === 'recording') {
//                 await stopRecording()
//                 if (state.status === "INITIAL") {
//                     console.log("open camera plz")
//                     await openCamera()
//                 }
//             }
//         }
//     })();
//
//     return () => {
//         if (timeout) {
//             clearTimeout(timeout)
//
//         }
//     }
// }, [state.recording, mediaDevice])

export default RecordingClock
