import React, {forwardRef, useEffect, useRef, useState} from "react";
import { ShapesEditor } from "../shapes-editor/ShapesEditor";
import VoicePlayer from "../voice-player/VoicePlayer";
import ClipPlayerControls, {ClipPlayerClock} from "./ClipPlayerControls";

const ClipPlayer = ({clip}: any) => {

    const videoRef = useRef<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dimensions, setDimensions] = useState({width: 0, height: 0});

    useEffect(() => {
        const resizeObserver = new window.ResizeObserver(entries => {
            for (let entry of entries) {
                const {width, height} = entry.contentRect;
                setDimensions({width, height});
                // console.log("resizeObserver: ", width, height)
            }
        });

        if (videoRef.current) {
            resizeObserver.observe(videoRef.current);
        }
        //videoRef.current.currentTime = 0.8666666666666667
        return () => {
            if (videoRef.current) {
                resizeObserver.unobserve(videoRef.current);
            }
        };
    }, []);

    const scale = dimensions.width / clip.width;
    return (
        <div id={"clip-player-video"} className={'relative overflow-hidden mx-auto'} style={{maxWidth: clip.width}}>
            <video
                className={'object-fill w-full relative z-10   top-0 left-0 max-h-[calc(100vh-100px)]'}
                controls={false}
                src={clip.orgVideoUrl}
                ref={videoRef}
                // muted
                disablePictureInPicture={true}
                onLoadedMetadata={() => {
                    // console.log("onLoadedMetadata hopaaa")
                    setIsLoading(false)
                }}
                // controlsList="nodownload noplaybackrate"
                controlsList="noplaybackrate nodownload noplaybackrate nofullscreen"
            />

            {!isLoading &&
                <>
                    {/*<div*/}
                    {/*    className={'absolute top-0 left-0 font-mono z-20 px-2 py-2 pointer-events-none text-left text-xl'}*/}
                    {/*    style={{color: "yellow"}}>*/}
                    {/*    <ClipPlayerClock clip={clip} videoRef={videoRef}/>*/}
                    {/*    /!*<br/>*!/*/}
                    {/*    /!*Subs:({videoCurrentTime.toFixed(2)})*!/*/}
                    {/*</div>*/}
                    <ShapesEditor videoRef={videoRef} clip={clip} scale={scale}/>
                    <VoicePlayer videoRef={videoRef} clip={clip}/>
                    <ClipPlayerControls videoRef={videoRef} clip={clip}/>
                </>
            }


        </div>
    )
}

export default ClipPlayer
