import React from "react";
import {useForm} from "../context/useForm";
import {FormFieldErrorMessage} from "../messages/error/FormFieldErrorMessage";
import {Controller} from "react-hook-form";


const FormInputNumber = ({
                             name,
                             defaultValue = "",
                             rules,
                             label,
                             placeholder,
                             autoFocus,
                             min,
                             max,
                             className,
                             step,
                             disabled
                         }: any) => {
    const {control} = useForm();

    const inputClassName = className || "bg-gray-50 border border-gray-300 text-gray-950 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-gray-300 dark:focus:ring-primary-500 dark:focus:border-primary-500"
    return (
        <div>
            <Controller
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                name={name}
                render={({field}: any) => (
                    <>
                        {label && <label htmlFor={name}
                                         className="block mb-2 text-sm font-medium text-gray-950 dark:text-gray-300">
                            {label}
                        </label>}
                        <input type={"number"}
                               id={name}
                               {...field}
                               min={min}
                               max={max}
                               onChange={(event: any) => {
                                   field.onChange(parseFloat(event.target.value))
                               }}
                               disabled={disabled}
                               autoFocus={autoFocus}
                               step={step}
                            //
                               className={inputClassName}
                               placeholder={placeholder}/>
                    </>
                )}
            />
            <FormFieldErrorMessage name={name}/>
        </div>
    );
};

export {FormInputNumber};
