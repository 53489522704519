import React from "react"
import {ComingSoonView} from "../tabs/TabsClipEditor";
import {MdBrandingWatermark, MdOutlineBrandingWatermark} from "react-icons/md";
import {FiMusic, SlFrame} from "react-icons/all";

const EffectsForm = () => {
    return (
        <>
            <div
                className="grid grid-cols-6 gap-6  space-y-6 bg-white dark:bg-gray-950 px-4 py-0 sm:p-6">
                <div className="col-span-6">
                    <ComingSoonView/>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 p-4">
                <EffectCard icon={<MdOutlineBrandingWatermark className="h-8 w-8"/>} text={"Watermark"}/>
                <EffectCard icon={<FiMusic className="h-8 w-8"/>} text={"Music"}/>
                <EffectCard icon={<SlFrame className="h-8 w-8"/>} text={"ZoomZ"}/>
            </div>

        </>
    )
}

const EffectCard = ({icon, text}: any) => {

    return <>
        <div
            className="
              flex cursor-pointer flex-col items-center justify-start rounded-lg bg-gray-50 py-5 px-6 text-center text-gray-800 shadow-gray-200 transition hover:bg-white
              hover:shadow-lg hover:shadow-gray-200 dark:bg-gray-800 dark:text-gray-200 dark:shadow-gray-700 dark:hover:shadow-gray-800
            "
        >
            {icon}
            <div className="mt-3 text-sm font-semibold">{text}</div>
        </div>
    </>
}

export {EffectsForm}