import React from "react";
import {useForm} from "../context/useForm";
import {FormLoadingSpinner} from "./FormLoadingSpinner";

const ButtonFormSubmit = ({onSubmit, text = "Save", className = "", disabled = false}: any) => {

    const {handleSubmit, isSubmitting} = useForm();

    const onClick = () => {
        handleSubmit(async (data: any) => {
            return await onSubmit(data)
        })
    }
    return (
        <button type="submit"
                disabled={disabled || isSubmitting}
                onClick={onClick}
                className={`text-lg min-h-[50px] flex justify-center items-center text-white bg-primary-300 hover:bg-primary-400  font-medium rounded-lg px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700  ${className}`}>

            {!isSubmitting && text}

            <FormLoadingSpinner/>
        </button>
    )
}

export {ButtonFormSubmit}