export type DeviceType = 'videoinput' | 'audioinput' | null | undefined

export class MediaDevice {

    error: any;
    deviceType: DeviceType;
    isOpen: boolean
    stream: MediaStream | null;

    constructor(deviceType: DeviceType) {
        this.stream = null;
        this.isOpen = false;
        this.deviceType = deviceType;
    }

    async open(constraints: MediaStreamConstraints): Promise<void> {
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints)
            this.stream = stream;
            this.isOpen = true;

        } catch (e) {
            throw e
        }
    }

    async switchMediaDevice(currentMediaIndex: number): Promise<void> {
        console.log("switchMediaDevice", currentMediaIndex)
        const devices = await navigator.mediaDevices.enumerateDevices();
        const camerasArr = devices.filter(device => device.kind === this.deviceType);
        console.log("switchMediaDevice", currentMediaIndex, camerasArr.length - 1);

        if (camerasArr.length > 1) {
            const newIndex = (currentMediaIndex + 1) % camerasArr.length;
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: camerasArr[newIndex].deviceId
                }
            });

            this.stream = stream;
        }
    }

    async close() {
        console.log("close camera")
        if (this.stream) {
            console.log("close camera and stream")
            this.stream.getTracks().forEach(function (track) {
                track.stop();
            });

            this.stream = null;
        }
        this.isOpen = false

    }
}
