import React from "react";
import {Helmet} from "react-helmet-async";
import {SocialsView} from "../../../../modules/app/social/SocialsView";

const SocialsRoute = ({}) => {
    return (
        <>
            <Helmet>
                <title>Social</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl py-5'}>
                <SocialsView/>
            </div>
        </>
    )
}

export default SocialsRoute