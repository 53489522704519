import React, {useState} from "react"
import {AlertModern, AlertWarning, LoadingStripes} from "front-lib";
import {useExportsContext} from "./context/useExportsContext";

const ExportsToaster = () => {
    const {isExporting, progress} = useExportsContext();
    return (
        <>
            {isExporting && <div>
                <div className={'fixed top-[20px] left-[50%]  -translate-x-1/2  w-full z-50 max-w-xl mx-auto'}>
                    <AlertWarning icon={<LoadingStripes text={""} className={'small mr-2'}/>} showClose={false}>
                        <div className={'flex justify-between items-center w-full'}>
                            <span>Export is in progress</span>
                            <div className="bg-gray-300 dark:bg-gray-700 w-[200px]">
                                <div
                                    style={{width: `${progress}%`}}
                                    className="bg-primary-200 text-xs font-medium text-white text-center p-0.5 leading-none"
                                > {progress.toFixed(0)}%
                                </div>
                            </div>
                        </div>
                    </AlertWarning>
                </div>
            </div>}
        </>
    )
}

export {ExportsToaster}