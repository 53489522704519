import React from "react";
import {
    Avatar,
    ButtonFormSubmit,
    FormGlobalErrorMessage,
    FormGlobalSuccessMessage,
    LazyLabelDateTime,
    withForm
} from "front-lib";
import {toRoleText} from "front-lib";
import {GoDot} from "react-icons/go";
import { MdLocationSearching} from "react-icons/md";
import {useSocket} from "../context/useSocket";

interface IProps {
    client: any;
    // onSubmit?: (formData?: any) => Promise<void>;
    onCancel?: () => void
}

const ManageSocketForm =  withForm(({client, onCancel}: IProps) => {
    const {socket} = useSocket();

    const isMe = socket.id === client.id;
    const doPing = async () => {
        console.log("doPing");
        return new Promise(async (resolve) => {
            const startTime = performance.now();
            await socket.emit("health-check", {id: client.id})
            socket.once("alive", () => {
                console.log("client is alive")
                const endTime = performance.now();
                const time = parseInt(String(endTime - startTime));
                resolve(`Ping success ${time}ms`)

            })
        })

    }

    const doPageReload = async () => {
        //todo implement as remote command endpoint (command-ping , command-page-reload , command-something)
        console.log("doPageReload");
        await socket.emit("page-reload", {id: client.id})
    }

    return (
        <>
            <div className="space-y-6 bg-white dark:bg-gray-900 px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                        <div className={'flex items-center'}>
                            <div className={'h-11 w-11 relative'}>
                                <Avatar url={client.user.profile.avatar}/>
                                {isMe && <div
                                    className="inline-flex absolute -top-2 -right-2 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-primary-500 rounded-full border-2 border-white dark:border-gray-950">
                                    <MdLocationSearching/>
                                </div>}

                            </div>
                            <span
                                className={'ml-2'}>{client.user.profile.firstname} {client.user.profile.lastname}</span>
                        </div>
                    </div>
                    <div className="col-span-4">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6">
                                <label className="contents text-base font-medium text-gray-950 dark:text-gray-300">Client ID
                                </label>
                                <div className="text-sm text-gray-500">
                                    {client.id}

                                </div>
                            </div>
                            <div className="col-span-6">
                                <label className="contents text-base font-medium text-gray-950 dark:text-gray-300">Rooms
                                </label>
                                <div className="text-sm text-gray-500">
                                    <ul>
                                        {client.rooms.map((r: any) => <li key={`${client.id}=${r}`}
                                                                          className={'mb-1 flex items-center'}>
                                            <GoDot size={8} className={'mt-1 mr-1'}/>
                                            {r}
                                        </li>)}
                                    </ul>

                                </div>
                            </div>

                            <div className="col-span-6">
                                <label className="contents text-base font-medium text-gray-950 dark:text-gray-300">Address
                                </label>
                                <p className="text-sm text-gray-500">{client.address}</p>
                            </div>
                            <div className="col-span-6">
                                <label className="contents text-base font-medium text-gray-950 dark:text-gray-300">Origin
                                </label>
                                <p className="text-sm text-gray-500">{client.origin}</p>
                            </div>

                            <div className="col-span-6">
                                <label className="contents text-base font-medium text-gray-950 dark:text-gray-300">
                                    App Version
                                </label>
                                <p className="text-sm text-gray-500">{client.storage.version}</p>
                            </div>

                            <div className="col-span-6">
                                <label className="contents text-base font-medium text-gray-950 dark:text-gray-300">
                                    App Mode
                                </label>
                                <p className="text-sm text-gray-500">{client.storage.mode}</p>
                            </div>

                            <div className="col-span-6">
                                <label className="contents text-base font-medium text-gray-950 dark:text-gray-300">Time
                                </label>
                                <p className="text-sm text-gray-500">{client.time}</p>
                            </div>

                            <div className="col-span-6">
                                <label className="contents text-base font-medium text-gray-950 dark:text-gray-300">Issued
                                </label>
                                <p className="text-sm text-gray-500">
                                    <LazyLabelDateTime timestamp={client.issued}/>
                                </p>
                            </div>
                            <div className="col-span-6">
                                <FormGlobalErrorMessage/>
                                <FormGlobalSuccessMessage/>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6">
                                <label className="contents text-base font-medium text-gray-950 dark:text-gray-300">Email
                                </label>
                                <p className="text-sm text-gray-500">{client.user.email}</p>
                            </div>
                            <div className="col-span-6">
                                <label className="contents text-base font-medium text-gray-950 dark:text-gray-300">Role
                                </label>
                                <p className="text-sm text-gray-500">{toRoleText(client.user.role)}</p>
                            </div>
                        </div>
                    </div>


                </div>


            </div>

            <div
                className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">

                {!isMe && <ButtonFormSubmit onSubmit={doPing} text={"Ping"} disabled={isMe}/>}
                <ButtonFormSubmit onSubmit={doPageReload} text={"Page Refresh"}/>
                <button type="button"
                        onClick={onCancel}
                        className="text-gray-500 min-h-[50px] bg-white hover:bg-gray-100  rounded-lg border border-gray-200 text-lg font-medium px-5 py-2.5 hover:text-gray-950 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                    Cancel
                </button>
            </div>
        </>
    )
})

export {ManageSocketForm}