import React, {useEffect, useState} from 'react';
import {LoadingCover} from "front-lib";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useClipUploader} from "../../../modules/app/clip-editor/useClipUploader";

function ClipCreationRoute() {
    // const [isLoading, setIsLoading] = useState(true);
    const {createClipEntity} = useClipUploader();
    const [searchParams] = useSearchParams();
    const url = searchParams.get("url");
    const navigate = useNavigate();

    const createClipEntityAndRedirect = async () => {
        if (url) {
            const clip = await createClipEntity(url);
            navigate(`/app/clips/${clip.id}`)
        }
    }
    useEffect(() => {
        createClipEntityAndRedirect();
    }, []);

    return (
        <>
            <div className={'pt-10'}>
                <LoadingCover isLoading={true} text={"Processing Video..."}
                              className={' flex z-50 items-center w-full left-0 bg-white py-[100px]  dark:bg-gray-950 min-h-[calc(100%-70px)]'}/>
            </div>
        </>
    );
}

export default ClipCreationRoute;
